@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Pushster&family=Teko:wght@500&display=swap);
body {
  margin: 0;
  font-family: "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.border {
  border-bottom: 1px solid #dedede;
  width: 90%;
  margin: auto;

  padding: 10px;
}
.helpborder {
  border-bottom: 1px solid #dedede;
  width: 40%;
  margin: auto;
  padding-top: 35px;
}

.rsIcon {
  content: "\20B9";
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bm-burger-button button {
  width: 50px !important;
  height: 50px !important;
}
.bm-burger-bars {
  height: 1% !important;
}
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}
.tox-tinymce{
  border-radius: 0 !important;
  border: none !important;
 
}
.tox-statusbar{
  display: none !important;
}
p{
  line-height: 0;
}
.mce-content-body {
  font-size: 18px !important;
}
.mce-content-body:not([dir=rtl])[data-mce-placeholder]:not(.mce-visualblocks)::before{
  font-size: 18px !important;
  font-weight: 600 !important;
}
.mainPage {
  background-color: white;
  -webkit-tap-highlight-color: transparent;
}

div {
   font-family: "Poppins", sans-serif;
 
}

h1 {
  color: #1e1e1e;
  font-family: "Poppins", sans-serif; 
  font-weight: 600;
}

h2 {
  font-family: "Poppins", sans-serif; 
  font-size: large;
  font-weight: 400;
}
h3 {
  font-family: "Poppins", sans-serif;
  font-size: large;
  font-weight: 400;
}
label {
  font-family: "Poppins", sans-serif;
  font-size: large;
  font-weight: 500;
}
p {
  font-family: "Poppins", sans-serif;
  font-size: large;
  font-weight: 400;
}

h6 {
  font-family: "Teko", sans-serif;
}

textTrasition {
  font-family: "Pushster", cursive;
}

.screeWidth {
  max-width: 1600px;

  margin: auto;
}
.dots li button:before {
  color: rgb(255, 255, 255) !important;
}

.linkTag {
  text-decoration: none;
  color: inherit;
}
::-webkit-scrollbar {
  display: none;
}
.Login_pageLoader__2kXew {
  text-align: center;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Login_mainContainer__2lZGs {
  padding: 20px;
}
.Login_inputFields_check__2FDsp {
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
  align-items: center;
  cursor: pointer;
}
.Login_input_container__3xVJM {
  margin-top: 25px;
}
.Login_checkbox__lekwy {
  cursor: pointer;
}
.Login_checkbox_text__2IXgP {
  color: #858585;
  margin-left: 4px;
  align-items: center;
}
.Login_LoadingBtn__19c2M {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 10px 10px;
  width: 55%;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
}
.Login_freelancerlink__3YuP8 {
  display: block;
  color: #13a900;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  padding-top: 30px;
}
.Login_signup_link__QCESF {
  color: #858585;
  text-align: center;
  margin-top: 15px;
  text-decoration: none;
  cursor: pointer;
}
.Login_MainHeading__1RHir{
  text-align: center;
  font-size: 30px;
  padding-top: 20px;
}
.Login_login_with__1I3Ox {
  text-align: center;
  color: #858585;
  margin: 30px 0px 8px 0px;
}
.Login_terms_link__1Sfz2 {
  color: #1778f2;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
}

.Login_checkbox__lekwy {
  padding: 2px;
}
.Login_forgot_password__y8GKC {
  color: #1778f2;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
.Login_ActionBtn__3C_Tw {
  display: flex;
  justify-content: center;
}
.Login_back_button__1doG6 {
  background: #fff;
  border: none;
  margin-top: 15px;
  cursor: pointer;
}

.Login_error_message__2LMim {
  color: rgb(250, 0, 0);
  display: flex;
  align-items: center;
}
.Login_loginWith_Otp__81jAX {
  color: #1778f2;
  text-align: right;
  text-decoration: none;
  cursor: pointer;
}
.Login_loginLink__1tI-y {

  color: #858585;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

@media screen and (min-width: 1024px) {
  .Login_mainContainer__2lZGs {
    padding: 30px;
    text-align: center;
    background-color: white;
  }
  .Login_signup_link__QCESF {
    display: none;
  }
  .Login_freelancerlink__3YuP8 {
    display: block;
    color: #13a900;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    padding-top: 8px;
    margin-top: 20px;
    font-weight: bold;
  }

  .Login_Maincontainer_border__2U6aU {
    width: 40%;
    margin: auto;
    border-width: 1px;
    border-color: rgb(223, 223, 223);
    border-style: solid;
    margin-top: 20px;
  }
  .Login_Pagewrapper__3X4-G {
    background-color: #f5f5f5;
    padding-top: 20px;
    padding-bottom: 50px;
  }

  .Login_loginLink__1tI-y {
  
    color: #858585;
    text-align: center;
    margin-top: 15px;
    text-decoration: none;
    cursor: pointer;
  }
}

.RIModal_modalContainer__BqG3S {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.RIModal_modal__Z04g3 {
  background-color: white;
  margin: auto;
  position: relative;
  font-weight: 500;
  top: -25px;
  width: 350px;
  padding: 23px 9px;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
  z-index: 30;
}
.RIModal_button__3nAqj {
  width: 70px;
  color: #f02f39;
  padding: 2px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.RIModal_ActionBtn__3o_1n {
  display: flex;
  justify-content: space-around;
}

.RIModal_checkBoxContainer__3EVkO {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

@-webkit-keyframes RIModal_slide-down__1ds6M {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes RIModal_slide-down__1ds6M {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
.RIModal_ModalCheckIcon__CzG3I {
  text-align: center;
}
.RIModal_ModalCheckTextFirst__Sn7T2 {
  text-align: center;
  padding-top: 16px;
  font-size: 14px;
  font-weight: bold;
}
.RIModal_ModalCheckTextSecond__2rdhL {
  text-align: center;
  padding-top: 16px;
  font-size: 16px;
  font-weight: bold;
}
.RIModal_modalcheck__vq-Ti {
  height: 40px;
  width: 40px;
  margin-top: -90px;
  border: 1px solid;
  padding: 20px;
  border-radius: 50%;
  background-color: #0c2551;
  color: #fff;
  border-color: #0c2551;
}
.RIModal_ModalCheckIconText__2y3Kp {
  text-align: center;
  font-weight: bold;
}
.RIModal_overlay__2QRf- {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
}

.RIModal_mainModalContainer__3mK-m {
  background-color: white;
  margin: auto;
  position: relative;
  font-weight: 500;
  top: -30px;
  width: 350px;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
  z-index: 30;
}
.RIModal_closeModal__Q5kDh {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 6px;
  color: #000;
}
.RIModal_Message__1Sq84 {
  display: block;
  font-size: 15px;
  padding: 10px 12px 0px 12px;
  text-align: center;
  font-weight: 400;
  color: #000;
}
.RIModal_LoaderCenter__PQWQQ {
  text-align: center;
}
.RIModal_msgbtmcenter__2qGeZ {
  text-align: center;
  color: #000;
}
.RIModal_filterOptionHeadings__1vzqa {
  font-size: 22px;
  font-weight: 600;
  color: #000;
  margin-top: 45px;
  text-align: left;
}
.RIModal_title__1ZI5F {
  display: none;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  color: #000;
  margin:45px auto 0px;
}
.RIModal_titleIcon__1XaNa{
  display: block;
  margin:45px auto 0px;
}
.RIModal_buttonRedirectText__3GZso {
  display: flex;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  font-size: 20px;
  color: #1778f2;
}
.RIModal_forwardIcon__mk9Fi{
  margin-top: 12px;
  width: 32px;
  height: 32px;
}
.RIModal_skillOption__2PcnI {
  padding: 2px;
  border: 1.5px solid #59acbf;
  border-radius: 5px;
  text-align: center;
  margin: 5px;
  font-size: 12px;
  color: #59acbf;
  cursor: pointer;
}
.RIModal_skillOption__2PcnI:hover {
  background-color: #59acbf;
  color: #f3f9ff;
}
.RIModal_tagDisplay__3RrTY {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}
.RIModal_MessageSkill__3POUb {
  margin-top: 5px;
  font-size: 22px;
  padding: 10px 12px 0px 13px;
  font-weight: 600;
  color: #000;
}
.RIModal_modalContentFilter__gyS4R {
  overflow-y: scroll;
  height: 80vh;
}
.RIModal_modalContent__ZKZov{
  height: 60%;
  padding: 16px 0px 20px 0px;
}
.RIModal_WorkTypeContainer__1XYuW{
  padding-left: 10px;
}
@media screen and (min-width: 768px) {
  .RIModal_modal__Z04g3 {
    margin: 0px 20px;
    height: 200px;
  }
  .RIModal_mainModalContainer__3mK-m {
    background-color: white;
    margin: auto;
    position: relative;
    font-weight: 500;
    top: -25px;
    width: 550px;
    padding: 23px 0px;
    border-radius: 14px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
    z-index: 30;
  }
  .RIModal_Message__1Sq84 {
    padding: 12px;
    font-size: 20px;
  }

  .RIModal_title__1ZI5F {
    display: block;
    margin-top: 30px;
  }
  .RIModal_titleIcon__1XaNa{
    display: none;
  }
  .RIModal_buttonRedirectText__3GZso {
    display: flex;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    font-size: 24px;
    color: #1778f2;
  }
  .RIModal_forwardIcon__mk9Fi{
    margin-top: 12px;
    width: 40px;
    height: 40px;
  }
}

.RITheme_buttonBox__27H-F {
  background-color: #1778f2;
  color: white;
  height: auto;
  min-width: 60%;
  border-radius: 6px;
  display: flex;
  margin: auto;
  padding: 10px 10px 10px 10px;
  margin-top: 25px;
  margin-bottom: 25px;
  border: 1px solid transparent;
}
.RITheme_uldropdown__1tKGE {
  list-style: none;
  margin-left: -30px;
}

.RITheme_buttonBoxsmallForReqCard__2m1ME {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;

  padding: 5px 5px 5px 5px;
  margin-top: 25px;
  margin-bottom: 25px;

  min-width: 100px;
  border: 1px solid transparent;
}


.RITheme_buttonBoxsmallForReqCard__2m1ME{
  cursor: pointer;
}
.RITheme_flagImage__fAW2V {
  height: 18px;
  width: 30px;
  margin-right: 10px;
  margin-top: 5px;
}
.RITheme_dialCode__26W7s {
  outline: none;
  border: none;
  font-size: 18px;
  margin: 5px 0px 8px 12px;
}
.RITheme_contriesList__3S31f {
  height: 200px;
  overflow: scroll;
  margin-top: -10px;
  border: 1px solid #ece9e9;
  border-top: none;
  background-color: #fff !important;
  transition: all 0.3s ease;
}
.RITheme_mobileWrapper__8EcVf {
  display: flex;
  align-items: center;
}
.RITheme_dropdownLi__3GWAX {
  background-color: white;
  list-style: none;
  text-align: left;
  margin: 4px;
  cursor: pointer;
}
.RITheme_dropdownLi__3GWAX:hover {
  color: #858585;
}

.RITheme_dualButton__3NHae {
  display: flex;
  justify-content: center;
}
.RITheme_buttonBoxdual__24qgd {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  padding: 10px 10px 10px 10px;
  margin-top: 25px;
  margin-bottom: 25px;
  cursor: pointer;
  border: none;
  min-width: 40%;
}
.RITheme_buttonBoxdualSecond__OEQis {
  background-color: #dad1d1;
  color: rgb(36, 29, 29);
  border-radius: 6px;
  display: flex;
  padding: 10px 10px 10px 10px;
  margin-top: 25px;
  margin-bottom: 25px;
  cursor: pointer;
  border: none;
  min-width: 40%;
  margin-right: 10px;
}
.RITheme_buttonBoxWhite__31eGg {
  background-color: #ffffff;
  color: #0f587d;
  height: auto;
  border-radius: 6px;
  display: flex;
  padding: 10px 10px 10px 10px;
  margin-top: 25px;
  border: none;
}

.RITheme_buttonBoxLeft__3BCMQ {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  padding: 10px 10px 10px 10px;
  margin-top: 25px;
  margin-bottom: 25px;
  border: 1px solid transparent;
  min-width: 40%;
}

.RITheme_buttonBoxLeft__3BCMQ:hover {
  background-color: white;
  border-width: 1px;
  border-color: #8600b0;
  border-style: solid;
  color: #8600b0;
  cursor: pointer;
}
.RITheme_buttonBoxsmall__3dFNW {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;

  padding: 5px 5px 5px 5px;
  margin-top: 25px;
  margin-bottom: 25px;

  min-width: 100px;
  border: 1px solid transparent;
}

.RITheme_buttonBoxsmall__3dFNW:hover {
  background-color: white;

  border-color: #8600b0;
  border-style: solid;
  color: #8600b0;
  cursor: pointer;
}

.RITheme_smallForReqCard__36cQe {
  background-color: #efefef;
}

.RITheme_smallForReqCard__36cQe:hover {
  background-color: #1778f2;
  color: #ffffff;

  cursor: pointer;
}
.RITheme_buttonBoxgreen__3Pg_m {
  background-color: #11ce00;
  color: white;
  border-radius: 6px;

  padding: 5px 5px 5px 5px;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
  min-width: 100px;
}

.RITheme_buttonBoxgreen__3Pg_m:hover {
  background-color: white;
  border-width: 1px;
  border-color: #8600b0;
  border-style: solid;
  color: #8600b0;
  cursor: pointer;
}
.RITheme_buttonBoxreject__ouvQl {
  background-color: white;
  color:  #ff0000;
  border-radius: 6px;
  border-color: #ff0000;
  padding: 5px 5px 5px 5px;
  margin-top: 25px;
  margin-bottom: 25px;
  border-width: 1px;
  min-width: 100px;
}

.RITheme_buttonBoxreject__ouvQl:hover {
  background-color: #ff0000;
  border-width: 1px;
  border-color: #ff0000;
  border-style: solid;
  color: #ffffff;
  cursor: pointer;
}
.RITheme_buttonBoxHeader__2ask1 {
  background-color: #1778f2;
  color: white;
  height: 38px;
  margin: auto;
  border-radius: 6px;
  display: flex;
  padding: 0px 30px;
  width: 40%;
  margin-top: 25px;
  border: none;
}

.RITheme_buttonBoxmedium__14fKR {
  background-color: #000;
  color: #fff;
  height: auto;
  border-radius: 6px;
  display: flex;
  padding: 10px 10px 10px 10px;
  margin-top: 25px;
  border: none;
}
.RITheme_buttonBoxmedium__14fKR:hover {
  cursor: pointer;
  background-color: #1778f2;
}

.RITheme_buttonBoxSignup__315JC {
  background-color: #000;
  color: #fff;
  height: auto;
  display: flex;
  padding: 5px 3px 5px 3px;
  border: none;
  margin-left: 30px;
  font-weight: 600;
}
.RITheme_buttonBoxSignup__315JC:hover {
  cursor: pointer;
  background-color: #282f39;
}
.RITheme_buttonText__OwmA8 {
  align-self: center;
  margin: auto;
  padding: 0px 10px;
  font-weight: bold;

  font-size: 16px;
  letter-spacing: 1px;
}

.RITheme_buttonTextSignUp__35qC1 {
  align-self: center;
  margin: auto;
  padding: 0px 10px;
}

.RITheme_flagContainer__2pXnl {
  display: flex;
  border: 1px solid #ece9e9;
  height: 20px;
  align-items: center;
  outline: none;
  padding: 8px 12px 12px 12px;
  cursor: pointer;
  background-color: white;
  margin: 10px 0px;
  color: #000;
}
.RITheme_buttonBox__27H-F:hover {
  background-color: white;
  border-width: 1px;
  border-color: #8600b0;
  border-style: solid;
  color: #8600b0;
  cursor: pointer;
}
.RITheme_buttonBoxWhite__31eGg:hover {
  background-color: #1778f2;
  color: white;
  cursor: pointer;
}
.RITheme_flagContainerFocus__2Xop3 {
  display: flex;
  border: 1px solid #1778f2;
  height: 20px;
  align-items: center;
  outline: none;
  padding: 8px 12px 12px 12px;
  cursor: pointer;
  background-color: white;
  margin: 10px 0px;
  color: #000;
}
.RITheme_formContainer__X8cRy {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.RITheme_inputBoxContainer__qWVFl {
  padding: 20px;
}

.RITheme_inputArea_name__1hyQn {
  padding: 10px 10px;
  border: 1px solid #ece9e9;

  outline: none;
  margin: 10px 0px;

  font-size: 18px;
}
.RITheme_inputArea_name__1hyQn:focus {
  border-color: #1778f2;
}

.RITheme_inputArea__2aEaQ {
  padding: 10px 10px;
  outline: none;
  border: 1px solid #ece9e9;

  margin: 10px 0px;
  font-size: 18px;

  font-family: inherit;
}
.RITheme_inputArea__2aEaQ:focus {
  border-color: #1778f2;
}
.RITheme_inputArea_dropdown__E2GVX {
  border: 1px solid #ece9e9;
  height: 45px;
  font-size: 18px;
  outline: none;
  padding: 11px 12px;
  cursor: pointer;
  background-color: white;
  margin: 10px 0px;
  color: #000;
  -webkit-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 10.127L12 18.127L20 10.127H4Z" fill="%238E8E93"/></svg>');
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: 98%;
}

.RITheme_uploadIcon__2DuEA {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.RITheme_inputArea_upload__1Oaes {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  width: 80px;
  border-radius: 50px;
  height: 80px;
  align-items: center;
}
.RITheme_inputArea_withIcon__2lodU {
  padding: 10px 12px;
  width: 100%;
  border: none;
  font-size: 18px;
  outline: none;
}
.RITheme_inputArea_withIcon__2lodU:focus {
  border-color: #1778f2;
}
.RITheme_option_dropdown___7SDB {
  height: 45px;
  cursor: pointer;
}
.RITheme_input_fields__y8pm_ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
  border: 1px solid #ece9e9;
}
.RITheme_input_fields__y8pm_:focus {
  border-color: #1778f2;
}
.RITheme_eyeIcon__35hld {
  margin-right: 10px;
  height: 23px;
  width: 23px;
  cursor: pointer;
}
.RITheme_tickIcon__2vxsu {
  color: #2ec72e;
  margin-right: 10px;
  height: 24px;
  width: 24px;
}
.RITheme_CheckboxStyle__38OGp {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border: 1px solid #ece9e9;
  margin: 7px 0px;
}
.RITheme_CheckBoxInput__SS9WS {
  display: flex;
}
.RITheme_form_upload_label__Uoeij {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.RITheme_inputArea_Custom_dropdown__3q-pE {
  border: none;
  padding: 6px;
  width: 100%;
  font-size: 18px;
  outline: none;
  padding-top: 9px;
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: 98%;
}
::-webkit-input-placeholder {
  font-size: 18px;
  color: #858585;
}
:-ms-input-placeholder {
  font-size: 18px;
  color: #858585;
}
::placeholder {
  font-size: 18px;
  color: #858585;
}
.RITheme_inputAreaDateContainer__3GMTd {
  width: 100%;
  border: 1px solid #ece9e9;
  height: 45px;
  margin: 15px 0px;
}
.RITheme_inputAreadate__2LnrV {
  font-size: 18px;
  width: 100%;
  border: none;
  outline: none;
}

.RITheme_CheckBox_Text__2Auwv {
  padding-left: 28px;
}
.RITheme_CheckBoxInput_Text__2XbSs {
  padding-left: 8px;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}
.RITheme_checkBox_input__bmr5S {
  height: 19px;
  cursor: pointer;
}

.RITheme_RiTextTransition__3K85u {
  color: #000;
  font-size: 25px;
  margin-top: -30px;
}

.RITheme_mainHeadingContainer__3yFue {
 
  padding: 10px;
 
}

.RITheme_newHeadingFirst__1gRtg {
  margin-bottom: 7px;
  font-size: 25px;
font-weight: 800;
}

.RITheme_thirdLine__10vbo {
  color: #858585;
  font-size: 20px;
}
.RITheme_thirdLineWhite__2l5W- {
  color: #fff;
  font-size: 30px;
}

.RITheme_headingTwo__1RAUj {
  margin-top: -10px;
  line-height: 1.2;
  font-size: 40px;
}
.RITheme_input_fields_mobile__2Cl1D {
  border: 1px solid #1778f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}
.RITheme_labelWithCheckBox__uz8X9 {
  display: flex;
  align-items: center;
}

.RITheme_labelWithCheckBoxInput__RAbwN {
  margin-left: 20px;
}
.RITheme_CheckBoxJobAlert__E2RQC{
  width: 30px;
  cursor: pointer;
}
.RITheme_JobAlertCheckBox__YUgHM{
  display: flex;
  align-items: center;
}
.RITheme_buttonBoxAlert__1kwdf {
  background-color: #1778f2;
  color: white;
  padding: 10px 12px 11px 12px;
  font-weight: 700;
  font-size: 10px;
  border-radius: 0px 6px 6px 0px;
  border: 1px solid transparent;
}

.RITheme_buttonBoxAlert__1kwdf:hover {
  background-color: white;

  border-color: #8600b0;
  border-style: solid;
  color: #8600b0;
  cursor: pointer;
}
@media screen and (min-width: 1023px) {
  .RITheme_mainHeadingContainer__3yFue {
    font-size: 60px;
    padding: 0;
   
  }
  .RITheme_RiTextTransition__3K85u {
    color: #000;
    font-size: 40px;
    margin-top: -30px;
  }
  .RITheme_newHeadingFirst__1gRtg {
    margin-bottom: 0;
    font-size: 35px;
    font-weight: 800;
  }
  .RITheme_buttonBoxdual__24qgd {
    min-width: 25%;
  }
  .RITheme_buttonBoxdualSecond__OEQis {
    min-width: 25%;
  }
  .RITheme_buttonBoxLeft__3BCMQ {
    min-width: 300px;
  }

  .RITheme_buttonBoxLeft__3BCMQ {
    min-width: 300px;
  }
}

.SignUp_mainContainer__1drSy {
  padding: 20px;
  background-color: white;
}

.SignUp_inputFields_check__1q5U7 {
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
}
.SignUp_checkbox__3ln42 {
  cursor: pointer;
}
.SignUp_checkbox_text__cbIiw {
  color: #858585;
  align-items: center;
  margin-left: 4px;
 
}
.SignUp_forgot_password__3nPiI {
  color: #858585;
  text-align: center;
  margin-top: 15px;
}

.SignUp_input_container__1Yn4v {
  margin-top: 25px;
}
.SignUp_LinkText__2LvlH{
  text-decoration:none ;
  color: #1778f2;
}
.SignUp_login_with__3S72- {
  text-align: center;
  color: #858585;
  margin: 30px 0px 8px 0px;
}
.SignUp_terms_link__DITol {
  color: #1778f2;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 10px;
}
.SignUp_checkbox__3ln42 {
  padding: 2px;
}
.SignUp_ErrorMessage__1CY8n {
  color: #ff2222;
  text-align: start;

  font-size: 14px;
  margin: 0px;
}
.SignUp_CheckIcon__zdl2W{
  height: 24px;
  width: 24px;
  color: #2ec72e;
}
.SignUp_error_message__1-S3t {
  color: rgb(250, 0, 0);
  display: flex;
  text-align: start;
  align-items: center;

  font-size: 14px;
  margin: 0px;
}

.SignUp_LoadingBtn__1wA-Q {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 8px 8px;
  width: 50%;
  margin-top: 25px;
  border: none;
}
.SignUp_back_button__29Ti- {
  background: #fff;
  border: none;
  margin-top: 15px;
  cursor: pointer;
}
.SignUp_freelancerlink__CNdYH {
  display: block;
  color: #13a900;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  padding-top: 30px;
}
.SignUp_nameFields__1UAXF {
  display: grid;
}
.SignUp_lastName__2rjy8 {
  margin-left: 0px;
}
.SignUp_EmailIcon__3cLF2 {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  border: 1px solid #ece9e9;
  margin: 10px 0px 20px 0px;
  font-size: 18px;
  justify-content: space-between;
}
.SignUp_EmailText__JzQZx {
  padding-top: 8px;
}
.SignUp_EmailIconText__3fszS {
  font-size: 14px;
}
.SignUp_inputFieldsWrapper__1atmt {
  display: flex;
  justify-content: space-between;
}
.SignUp_inputFields__1KOoC {
  width: 48%;
}
.SignUp_loginLink__1Idmj {

  color: #858585;
  text-align: center;
  margin: 15px;
}
.SignUp_MainHeading__3QlIM{
  text-align: center;
  font-size: 30px;
  padding-top: 20px;
}

@media screen and (min-width: 1024px) {
  .SignUp_mainContainer__1drSy {
    padding: 20px;
    text-align: center;
  }
  .SignUp_inputFields_check__1q5U7 {
    align-items: center;
  }
  .SignUp_freelancerlink__CNdYH {
    display: block;
    color: #13a900;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    padding-top: 8px;
    margin-top: 30px;
    font-weight: bold;
  }
  .SignUp_mainContainer__1drSy:h1 {
    font-size: 35px;
    font-weight: 700;
  }
  .SignUp_forgot_password__3nPiI {
    display: none;
  }
  .SignUp_loginLink__1Idmj {
   
    color: #858585;
    text-align: center;
    margin: 15px;
  }
  .SignUp_Pagewrapper__1Rkod {
    background-color: #f5f5f5;
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .SignUp_Maincontainer_border__bYSNh {
    width: 40%;
    margin: auto;
    border-width: 1px;
    border-color: rgb(223, 223, 223);
    border-style: solid;
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .SignUp_nameFields__1UAXF {
    display: flex;
  }
  .SignUp_lastName__2rjy8 {
    margin-left: 20px;
  }
}

.Joinpage_Joinpage_header__19hrF {
  width: 90%;
  margin: 40px auto;
  border-width: 0px;
  border-color: rgb(223, 223, 223);
  border-style: solid;
  background-color: #fff;
}

.Joinpage_heading__1LQVZ {
  font-size: 25px;
  font-weight: bold;
  margin: 40px 15px 20px 15px;
  text-align: center;
}

.Joinpage_client_join__1BdK7 {
  border: 1px solid lightgrey;
  background-color: #f6f6f6;
  padding: 10px;
  border-radius: 5px;
  margin: 15px;
  cursor: pointer;
}
.Joinpage_client_joincolor__1X2cA {
  border: 1px solid lightgrey;
  background-color: #fbfdff;
  padding: 10px;
  border-radius: 5px;
  margin: 15px;
  cursor: pointer;
}

.Joinpage_freelancer_join__1oi_- {
  border: 1px solid grey;
}

.Joinpage_Joinpage_input__2jt55 {
  float: right;
  cursor: pointer;
}

.Joinpage_Joinpage_flex__2P5ey {
  display: flex;
  justify-content: left;
  padding: 23px 5px;
  align-items: center;
}

.Joinpage_Joinpage_text__WbAjt {
  padding: 0px;
  font-size: 15px;
}
.Joinpage_Joinpage_imgfreelancer__ozrK9 {
  width: 75%;
  padding-top: 7px;
}

.Joinpage_Joinpage_img__2bjw1 {
  width: 75%;
  padding-top: 7px;
}

.Joinpage_ActionButton__1DktQ {
  text-align: center;
  padding-bottom: 10px;
}
.Joinpage_ActionButton_disable__yaty9 {
  text-align: center;
  padding-bottom: 10px;
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
.Joinpage_login__nSaJ_ {
  color: #1778f2;
  cursor: pointer;
}
.Joinpage_last_text__3DRkf {
  text-align: center;
  font-size: 15px;
  padding-bottom: 30px;
}


@media screen and (min-width: 1024px) {
  .Joinpage_displey_flex__2IWq8 {
    display: flex;
    justify-content: center;
  }
  .Joinpage_mainContainer__2q3bq {
    background-color: rgb(223, 223, 223);
    padding-top: 30px;
    padding-bottom: 50px;
  }
  .Joinpage_client_join__1BdK7 {
    width: 32%;
    height: 20%;
    cursor: pointer;
  }
  .Joinpage_client_joincolor__1X2cA {
    width: 32%;
    height: 20%;
    cursor: pointer;
  }

  .Joinpage_Joinpage_flex__2P5ey {
    display: block;
    text-align: center;
  }

  .Joinpage_Joinpage_img__2bjw1 {
    width: 24%;
    padding-top: 5px;
    padding-bottom: 2px;
  }
  .Joinpage_Joinpage_imgfreelancer__ozrK9 {
    width: 25%;
    padding-top: 7px;
    padding-bottom: 10px;
  }
  .Joinpage_Joinpage_header__19hrF {
    width: 60%;
    margin: 40px auto;
    border-width: 1px;
    border-color: rgb(223, 223, 223);
    border-style: solid;
  }
 
}

.ForgotPassword_mainContainer__1RhcW {
  padding: 2px 20px;
  background-color: white;
}
.ForgotPassword_Maincontainer_border__18UUX {
  background-color: white;
}
.ForgotPassword_inputFields_check__3QyOp {
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
}
.ForgotPassword_forgot_password__2zLK- {
  color: #858585;
  text-align: center;
  margin-top: 15px;
}
.ForgotPassword_LoadingBtn__XqIpl {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 8px 8px;
  width: 50%;
  margin-top: 25px;
  border: none;
}
.ForgotPassword_resend_code__2478V {
  text-align: center;
  margin-top: 10px;
  color: #1778f2;
}
.ForgotPassword_resend_code_hover__37nbC {
  text-align: center;
  margin-top: 10px;
  color: #1778f2;
  cursor: pointer;
}
.ForgotPassword_resend_code_hover__37nbC:hover {
  color: #858585;
}
.ForgotPassword_checkbox__3qPMC {
  padding: 2px;
}
.ForgotPassword_para_text__17SA6 {
  margin-top: 40px;
}
.ForgotPassword_error_message__1MrpP {
  color: rgb(250, 0, 0);
  display: flex;
  align-items: center;
}
.ForgotPassword_ActionBtn__1llRl {
  display: flex;
  justify-content: center;
}
.ForgotPassword_back_button__3nuVd {
  background: #fff;
  border: none;
  cursor: pointer;
  margin-top: 15px;
}
.ForgotPassword_LoadingBtn__XqIpl {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 10px 10px;
  width: 55%;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
}
.ForgotPassword_MobileNumber__3KnTg {
  padding-bottom: 30px;
}
@media screen and (min-width: 1024px) {
  .ForgotPassword_mainContainer__1RhcW {
    padding: 0px 30px 40px;
  }
  .ForgotPassword_Pagewrapper__2MhB6 {
    background-color: #f5f5f5;
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .ForgotPassword_Maincontainer_border__18UUX {
    width: 40%;
    margin: auto;
    border-width: 1px;
    border-color: rgb(223, 223, 223);
    border-style: solid;
    margin-top: 20px;
  }
}

.MobileVerification_mainContainer__1v0MQ {
  padding: 2px 20px;
  background-color: white;
}
.MobileVerification_Maincontainer_border__1TW-7 {
  background-color: white;
}
.MobileVerification_para_text__3BfxE {
  margin-top: 20px;
}
.MobileVerification_forgot_password__3dzjQ {
  color: #858585;
  text-align: center;
  margin-top: 60px;
}
.MobileVerification_resend_code__37oVM {
  text-align: center;
  margin-top: 10px;
  color: #1778f2;
  cursor: pointer;
}
.MobileVerification_error_message__2dMrm {
  color: rgb(250, 0, 0);
  text-align: center;
}
.MobileVerification_LoadingBtn__3EpMc {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 10px 10px;
  width: 55%;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
}
.MobileVerification_resend_code_message__aPdYP {
  text-align: center;
  margin-top: 10px;
  color: #858585;
}
.MobileVerification_ActionBtn__3_EvE {
  display: flex;
  justify-content: center;
}
.MobileVerification_outer_otp_div__afH3N {
  width: 190px;
  overflow: hidden;
  position: relative;
  margin: auto;
}

.MobileVerification_otp_div__feLPx {
  position: sticky;
  left: 0;
}

.MobileVerification_otp_input__22VGY {
  padding-left: 10px !important;
  letter-spacing: 33px;
  font-size: 30px !important;
  border: 0 !important;
  background-image: linear-gradient(
    270deg,
    #616161 70%,
    hsla(0, 0%, 100%, 0) 0
  );
  background-position: bottom;
  background-size: 50px 2px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 220px !important;
  min-width: 220px;
  border-radius: 0;
  outline: none;
  margin: 20px 0px;
}
.MobileVerification_back_button__2gHHY {
  background: #fff;
  border: none;
  margin-top: 15px;
  cursor: pointer;
}
@media screen and (min-width: 1024px) {
  .MobileVerification_mainContainer__1v0MQ {
    padding: 20px 30px;
  }
  .MobileVerification_Pagewrapper__1mt0z {
    background-color: #f5f5f5;
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .MobileVerification_Maincontainer_border__1TW-7 {
    width: 40%;
    margin: auto;
    border-width: 1px;
    border-color: rgb(223, 223, 223);
    border-style: solid;
    margin-top: 20px;
  }
}

.EmailVerification_mainContainer__3jpmE {
  padding: 2px 20px 10px 20px;
  background-color: white;
}
.EmailVerification_Maincontainer_border__1YWxr {
  background-color: white;
}
.EmailVerification_error_message__3o0XH {
  color: rgb(250, 0, 0);
  text-align: center;
}
.EmailVerification_para_text__3BPbQ {
  margin-top: 10px;
}
.EmailVerification_LoadingBtn__17kcW {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 10px 10px;
  width: 55%;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
}
.EmailVerification_forgot_password__3fNKg {
  text-align: center;
  margin-top: 20px;
}
.EmailVerification_resend_code__3gYgy {
  color: #858585;
  text-align: center;
  margin-top: 10px;
}
.EmailVerification_resend_code_link__1yGP3 {
  color: #1778f2;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
}

.EmailVerification_ActionBtn__3_yh3 {
  display: flex;
  justify-content: center;
}
.EmailVerification_outer_otp_div__1oG55 {
  width: 190px;
  overflow: hidden;
  position: relative;
  margin: auto;
}

.EmailVerification_otp_div__1F4kH {
  position: sticky;
  left: 0;
}

.EmailVerification_otp_input__1J_ct {
  padding-left: 10px !important;
  letter-spacing: 33px;
  font-size: 30px !important;
  border: 0 !important;
  background-image: linear-gradient(
    270deg,
    #616161 70%,
    hsla(0, 0%, 100%, 0) 0
  );
  background-position: bottom;
  background-size: 50px 2px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 220px !important;
  min-width: 220px;
  border-radius: 0;
  outline: none;
  margin: 20px 0px;
}
.EmailVerification_back_button__2r78p {
  background: #fff;
  border: none;
  margin-top: 15px;
  cursor: pointer;
}
@media screen and (min-width: 1024px) {
  .EmailVerification_mainContainer__3jpmE {
    padding: 20px 30px;
  }
  .EmailVerification_Pagewrapper__1ywYV {
    background-color: #f5f5f5;
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .EmailVerification_Maincontainer_border__1YWxr {
    width: 40%;
    margin: auto;
    border-width: 1px;
    border-color: rgb(223, 223, 223);
    border-style: solid;
    margin-top: 20px;
  }
}

.Step2PAR_centerMain__22Wxk {
  padding: 20px;
  padding-top: 0px;
}
.Step2PAR_contentContainer__2qZIi {
  padding: 10px;
  margin-top: 20px;
  border-width: 1px;
  display: flex;
  border-color: grey;

  border-radius: 8px;
  border-style: solid;
}
.Step2PAR_lableDesign__2zH0_ {
  margin: 15px 0px 0px 0px;
  font-weight: 500;
  color: #000;
  display: flex;
}
.Step2PAR_input_container__2piyk {
  margin-top: 25px;
}
.Step2PAR_contentArea__2-7o6 {
  margin-left: 10px;
}
.Step2PAR_LoadingBtn__4DKhS {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 10px 10px;
  width: 55%;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
}
.Step2PAR_headingRequirementType__2oyp6 {
  font-weight: bold;
}
.Step2PAR_radioButton__T1m6j {
  color: yellow;
}
.Step2PAR_PARBtn_Link__39kMU {
  text-decoration: none;
}
.Step2PAR_ActionBtn__1TMyJ {
  display: flex;
  justify-content: center;
}
.Step2PAR_Heading_text__YBD0M {
  font-size: 30px;
  font-weight: bold;
}
.Step2PAR_error_message__1DO3Q {
  color: rgb(250, 0, 0);
  display: flex;
  align-items: center;
}
.Step2PAR_para_text__gH9sE {
  margin: 4px 1px 30px 1px;
}

.Step2PAR_HeadingText__OF0dt {
  margin: 10px 0px;
}
.Step2PAR_back_button__2St03 {
  background: #fff;
  border: none;
  padding-left: 15px;
  margin-bottom: 20px;
  cursor: pointer;
}
.Step2PAR_webImageArea__3u-Ay {
  display: none;
}

.Step2PAR_pageLoader__24IPN {
  text-align: center;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 1023px) {
  .Step2PAR_back_button__2St03 {
    padding-left: 10px;
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .Step2PAR_webPadding__3gokR {
    padding-left: 20px;
    padding-right: 20px;
  }

  .Step2PAR_webImageContainer__fw7QV {
    display: flex;
    justify-content: center;

    background-color: #f5f5f5;
    padding-top: 20px;
    padding-bottom: 50px;
  }

  .Step2PAR_webImageArea__3u-Ay {
    display: flex;
    flex-direction: column;
    background-color: #1678f2;
    height: auto;
    width: 40%;
    margin-top: 20px;
  }
  .Step2PAR_Heading_text_Web__3PBws {
    margin: 30px;
    font-size: 50px;
    font-weight: bold;
    color: white;
  }
  .Step2PAR_Heading_text__YBD0M {
    display: none;
  }
  .Step2PAR_para_text__gH9sE {
    display: none;
  }
  .Step2PAR_para_text_Web__1anmF {
    margin-left: 30px;
    font-size: 50px;
    font-size: 30px;
    color: white;
  }
}

.PostARequirementBackdropTheme_centerMain__vq9GD {
  padding: 20px;
  padding-top: 0px;
}

.PostARequirementBackdropTheme_back_button__1dhiR {
  background: #fff;
  border: none;
  padding-left: 15px;
  margin-bottom: 20px;
  cursor: pointer;
}

.PostARequirementBackdropTheme_Heading_text__25ZAW {
  font-size: 30px;
  font-weight: bold;
}

.PostARequirementBackdropTheme_para_text__2FMs7 {
  margin: 4px 1px 30px 1px;
}

.PostARequirementBackdropTheme_webImageArea__2oxFz {
  display: none;
}

@media screen and (min-width: 1023px) {
  .PostARequirementBackdropTheme_Maincontainer_border__2F-gT {
    padding: 0px 0px;
    width: 40%;
    background-color: white;
    margin-top: 20px;
  }
  .PostARequirementBackdropTheme_back_button__1dhiR {
    padding-left: 10px;
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .PostARequirementBackdropTheme_webPadding__3HcTE {
    padding-left: 20px;
    padding-right: 20px;
  }

  .PostARequirementBackdropTheme_webImageContainer__japSD {
    display: flex;
    justify-content: center;

    background-color: #f5f5f5;
    padding-top: 20px;
    padding-bottom: 50px;
  }

  .PostARequirementBackdropTheme_webImageArea__2oxFz {
    display: flex;
    flex-direction: column;
    background-color: #1678f2;
    height: auto;
    width: 40%;
    margin-top: 20px;
  }
  .PostARequirementBackdropTheme_Heading_text_Web__2irmD {
    margin: 30px;
    font-size: 50px;
    font-weight: bold;
    color: white;
  }
  .PostARequirementBackdropTheme_Heading_text__25ZAW {
    display: none;
  }
  .PostARequirementBackdropTheme_para_text__2FMs7 {
    display: none;
  }
  .PostARequirementBackdropTheme_para_text_Web__1adXa {
    margin-left: 30px;
    font-size: 50px;
    font-size: 30px;
    color: white;
  }
}

.RiProgressBar_mainContainer__t6OFS {
  margin-bottom: 30px;
}

.RiProgressBar_progressbar__2R5d8 {
  width: 100%;
  background-color: #ededed;
  height: 10px;
}

.RiProgressBar_progressbarStatus25__2ijqA {
  background-color: #1678f2;
  width: 25%;
  height: 10px;

  background-image: linear-gradient(to right, #00c9ff, #92fe9d);
}
.RiProgressBar_progressbarStatus50__3M0F7 {
  background-color: #1678f2;
  width: 50%;
  height: 10px;

  background-image: linear-gradient(to right, #00c9ff, #92fe9d);
}
.RiProgressBar_progressbarStatus75__3rlQJ {
  background-color: #1678f2;
  width: 75%;
  height: 10px;

  background-image: linear-gradient(to right, #00c9ff, #92fe9d);
}

.RiProgressBar_progressbarStatus100__22ikj {
  background-color: #1678f2;
  width: 100%;
  height: 10px;

  background-image: linear-gradient(to right, #00c9ff, #92fe9d);
}

.Step1PAR_mainContainer__1HSC4 {
  padding: 20px;
}
.Step1PAR_formContainer__3pg7A{
  margin-top: 18px;
}
.Step1PAR_pageLoader__3qiVp {
  text-align: center;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Step1PAR_centerMain__3nZIJ {
  padding: 20px;
  padding-top: 0px;
}
.Step1PAR_Heading__2PFmi {
  color: #0c2551;
}
.Step1PAR_input_container__svRAe {
  margin-top: 25px;
}
.Step1PAR_input_fields__38c3j {
  display: flex;
  flex-direction: column;
}
.Step1PAR_label__1UVPM {
  margin-top: 15px;
  font-weight: 800;
  color: #292e42;
}
.Step1PAR_input__2gUVX {
  padding: 12px;
  border: none;
  outline: none;
  margin: 7px 0px;
}
.Step1PAR_lableDesign__3obXN {
  margin: 15px 0px 0px 0px;
  font-weight: 500;
  align-items: center;
  color: #000;
  display: flex;
}
.Step1PAR_lablecompany__2gpr4 {
  margin: 13px 0px;
  font-weight: 500;
  color: #000;
  display: flex;
}
.Step1PAR_error_message__2ryuA {
  color: rgb(250, 0, 0);
  display: flex;
  align-items: center;
}
.Step1PAR_NumberStyle__3pfQm {
  text-align: right;
  padding: 0px 3px;
  font-size: 14px;
}
.Step1PAR_Icon__3E2-P {
  padding: 8px 0px 2px 0px;
}

.Step1PAR_form_upload_label__2LvS6 {
  cursor: pointer;
}
.Step1PAR_PopUpText__3XbeW {
  font-size: 12px;
  color: rgb(71, 70, 70);
}
.Step1PAR_uploadIcon__22stF {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.Step1PAR_LoadingBtn__3RLqu {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 10px 10px;
  width: 55%;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
}
.Step1PAR_uploadContainer__3OfLp {
  display: flex;
}
.Step1PAR_editIcon__1kbzt {
  padding: 8px 0px 0px 20px;
  height: 25px;
  cursor: pointer;
  width: 25px;
  color: rgb(16, 40, 71);
}
.Step1PAR_inputArea_container__3GfFk {
  width: 50%;
}
.Step1PAR_Logo_image__3QV1l {
  height: 30px;
  width: 30px;
}
.Step1PAR_selectoption__2WWrN {
  padding-left: 3px;
}
.Step1PAR_RadioOne__GyFK5 {
  border: 1px solid #ece9e9;
  padding: 8px;
  margin-top: 10px;
}
.Step1PAR_RadioOne__GyFK5:focus {
  box-shadow: 0.5px 0.5px 0.5px 0.5px #d1d0d0;
}
.Step1PAR_RadioTwo__CxLUy {
  border: 1px solid #ece9e9;
  padding: 8px;
  margin-top: 8px;
}
.Step1PAR_RadioTwo__CxLUy:focus {
  box-shadow: 0.5px 0.5px 0.5px 0.5px #d1d0d0;
}
.Step1PAR_TextInputRadioSelected__1m0-_ {
  margin-top: 24px;
}
.Step1PAR_PARBtn_Link__1CeuQ {
  text-decoration: none;
}
.Step1PAR_ActionBtn__3c-KY {
  display: flex;
  justify-content: center;
}
.Step1PAR_Heading_text__1WSs6 {
  font-size: 30px;
  font-weight: bold;
}

.Step1PAR_para_text__3USMr {
  margin: 4px 1px 30px 1px;
}

.Step1PAR_progressBarMargin__3yt1N {
  margin-top: 30px;
  margin-bottom: 30px;
}

.Step1PAR_HeadingText__cU_y_ {
  margin: 10px 0px;
}
.Step1PAR_back_button__XKN2- {
  background: #fff;
  border: none;
  padding-left: 15px;
  margin-bottom: 20px;
  cursor: pointer;
}
.Step1PAR_closeIcon__2F09m {
  cursor: pointer;
}
.Step1PAR_inputArea__2C7hS {
  padding: 10px 10px;
  outline: none;
  border: 1px solid #ece9e9;
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
  font-size: 18px;
}
.Step1PAR_inputfilds1__2L-EQ {
  cursor: pointer;
}
.Step1PAR_ImageInput__Day-J {
  cursor: pointer;
}
.Step1PAR_relatedSkillBox__gK_vf {
  border-width: 1px;
  border-radius: 5px;
  border-color: rgb(223, 223, 223);
  padding: 5px 10px;
  border-style: solid;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.Step1PAR_relatedSkillBox__gK_vf:hover {
  cursor: pointer;
  background-color: rgb(0, 0, 0);
  color: white;
}
.Step1PAR_relatedSkillContainer__2FmD8 {
  display: flex;
  flex-wrap: wrap;
  max-height: 200px;
  overflow: scroll;
}
.Step1PAR_relatedSkillHeading__3SJGD {
  font-size: 18px;
}
.Step1PAR_addIcon__2Znvm {
  margin-left: 5px;
}
.Step1PAR_htmlEditor__3AczT{
  border: 1px solid #ece9e9 ;
  margin: 10px 0;
}
.Step1PAR_htmlEditorFocus__6uaD-{
  border: 1px solid #1778f2 ;
  margin: 10px 0;
}
@media screen and (min-width: 1023px) {
  .Step1PAR_Maincontainer_border__2_bdS {
    padding: 0px 0px;
    width: 40%;
    background-color: white;
    margin-top: 20px;
  }

  .Step1PAR_webImageContainer__2E_fV {
    display: flex;
    justify-content: center;

    background-color: #f5f5f5;
    padding-top: 20px;
    padding-bottom: 50px;
  }

  .Step1PAR_webImageArea__2zWlR {
    display: flex;
    flex-direction: column;
    background-color: #1678f2;
    height: auto;
    width: 40%;
    margin-top: 20px;
  }

  .Step1PAR_Heading_text_Web__QyDbT {
    margin: 30px;
    font-size: 50px;
    font-weight: bold;
    color: white;
  }

  .Step1PAR_para_text_Web__wnjdD {
    margin-left: 30px;
    font-size: 50px;
    font-size: 30px;
    color: white;
  }

  .Step1PAR_Heading_text__1WSs6 {
    display: none;
  }
  .Step1PAR_para_text__3USMr {
    display: none;
  }
}

.AutoCompelete_mainDiv__1hZhQ {
  max-width: 800px;
  flex-direction: column;
  margin-bottom: 12px;
}
.AutoCompelete_input__2BeUo {
  width: 100%;
  height: 42px;
  margin-top: 5px;
  border: 1px solid #ededed;
  flex-direction: column;
  display: flex;
  padding: 12px;
  outline: none;
  font-size: 18px;
}
.AutoCompelete_skillSuggestionList__hhBD_ {
  padding: 6px;
}

.AutoCompelete_skillSuggestionList__hhBD_:hover {
  background-color: #ededed;
  cursor: pointer;
}
.AutoCompelete_dropdown_items_active__1DWxZ {
  background-color: #1678f2;
  color: #fff;
}
.AutoCompelete_primary__2IGwI {
  font-size: 12px;
  text-align: right;
  margin-right: 5px;
  font-weight: bold;
  font-style: italic;
}
.AutoCompelete_input__2BeUo {
  padding: 10px 10px;
  outline: none;
  border: 1px solid #ece9e9;
  margin: 10px 0px;
  font-size: 18px;
}
.AutoCompelete_input__2BeUo:focus {
  border-color: #1778f2;
}

.AutoCompelete_suggestion__1WvXd {
  max-width: 493px;
  border-bottom-left-radius: 5px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  margin-left: 3px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.AutoCompelete_suggestion__1WvXd p {
  padding: 6px;
  cursor: pointer;
  font-size: 15px;
  margin: 0;
}

.AutoCompelete_skillSuggestionList__hhBD_ {
  padding: 6px;
 
}

.AutoCompelete_skillSuggestionList__hhBD_:hover {
  background-color: #ededed;
  cursor: pointer;
}

p:hover {
  background-color: #ededed;
}
.AutoCompelete_icon__2V5Eb {
  position: absolute;
  margin-left: 49vw;
  margin-top: 13px;
  align-items: flex-end;
}

#AutoCompelete_Search__1G0sj {
  padding-left: 48px;
  padding-top: 1px;
  font-size: 22px;
  color: black;
  background-repeat: no-repeat;
  background-position: center;
  outline: 0;
}

#AutoCompelete_Search__1G0sj::-webkit-search-cancel-button {
  position: relative;
  right: 20px;
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background: black;
}
.AutoCompelete_searchIcon__1tdac {
  position: absolute;
  top: 12px;
  left: 15px;
}
.AutoCompelete_highlighted__3OXOg {
  background: #ededed;
}
.AutoCompelete_tagMainDiv__1kX0B {
  margin-bottom: 6px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
}
.AutoCompelete_suggestionTag__3nFYl {
  background-color: #1678f2;
  margin: 4px 4px;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px 7px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.AutoCompelete_suggestionTagFirst__1aA_5 {
  background: linear-gradient(45deg, #4568dc, #b06ab3);
  margin: 4px 4px;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px 7px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}
.AutoCompelete_closeIcon__1cNJc {
  margin-top: 4px;
  align-items: center;
  margin-left: 6px;
  float: right;
}

@media only screen and (min-width: 1024px) {
  .AutoCompelete_mainDiv__1hZhQ {
    flex-direction: column;
    margin: 0 0px;
  }
}

.Step3PAR_mainContainer__ZvVvw {
  padding: 20px;
}
.Step3PAR_SignIn_Heading__1nyeU {
  color: #0c2551;
}
.Step3PAR_input_fields__ghZ1A {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}
.Step3PAR_input_container__1lOyZ {
  margin-top: 25px;
}
.Step3PAR_lableDesign__3YnuV {
  margin: 15px 0px 0px 0px;
  font-weight: 500;
  color: #000;
  display: flex;
}
.Step3PAR_pageLoader__2ycYF {
  text-align: center;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Step3PAR_label__3GXEd {
  font-weight: 400;
  color: #292e42;
  font-family: "Roboto", sans-serif;
}
.Step3PAR_checkbox_text__26Rgc {
  color: #858585;
  font-size: 13px;
  margin-left: 4px;
}
.Step3PAR_formContainer__1hhUi {
  display: flex;
  flex-direction: column;
}
.Step3PAR_lableDesign__3YnuV {
  margin: 0px 0px 0px 0px;
  font-weight: 500;
  color: #292e42;
}
.Step3PAR_LoadingBtn__2ILA_ {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 10px 10px;
  width: 55%;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
}
.Step3PAR_CheckboxStyle__1Jle4 {
  margin: 10px 0px 20px 0px;
}
.Step3PAR_CheckBoxInput__1Hf_B {
  display: flex;
}

.Step3PAR_HeadingText__25jM7 {
  margin: 10px 0px;
}
.Step3PAR_radio__1waAa {
  margin: 13px 0px;
}
.Step3PAR_RadioOne__3O3u3 {
  border: 1px solid #ece9e9;
  padding: 8px;
}
.Step3PAR_RadioTwo__1AQJy {
  border: 1px solid #ece9e9;
  padding: 8px;

  margin-top: 8px;
}
.Step3PAR_error_message__3z7Xw {
  color: rgb(250, 0, 0);
  display: flex;
  align-items: center;
}
.Step3PAR_PARBtn_Link__C3xXT {
  text-decoration: none;
}
.Step3PAR_ActionBtn__2mDar {
  display: flex;
  justify-content: center;
}
.Step3PAR_Heading_text__3C8mp {
  margin: 0px;
}
.Step3PAR_para_text__221KA {
  margin: 4px 0px 15px 0px;
}
.Step3PAR_back_button__2-jmp {
  background: #fff;
  border: none;
  margin-top: 15px;
  cursor: pointer;
}
.Step3PAR_inputArea__wlDF1 {
  padding: 10px 10px;
  outline: none;
  border: 1px solid #ece9e9;
  margin: 10px 0px;
}
.Step3PAR_editButton__2645v {
  cursor: pointer;
  margin: -3px 20px;
}
@media screen and (min-width: 1023px) {
  .Step3PAR_Maincontainer_border__2D3G6 {
    padding: 0px 20px;
    width: 40%;
    margin: auto;
    background-color: white;
    border-width: 1px;
    border-color: rgb(223, 223, 223);
    border-style: solid;
    margin-top: 20px;
  }
  .Step3PAR_Pagewrapper__1WHpa {
    background-color: #f5f5f5;
    padding-top: 20px;
    padding-bottom: 50px;
  }
}

.Step4PAR_mainContainer___3Vvi {
  padding: 20px;
}
.Step4PAR_SignIn_Heading__2zZLv {
  color: #0c2551;
}
.Step4PAR_input_fields__1xWHB {
  display: flex;
  flex-direction: column;
}
.Step4PAR_input_container__3x6GL {
  margin-top: 25px;
}
.Step4PAR_label__17qf2 {
  margin: 15px 0px 0px 0px;
}
.Step4PAR_PopUpText__231Ci {
  font-size: 12px;
  color: rgb(71, 70, 70);
}
.Step4PAR_pageLoader__3u7kH {
  text-align: center;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Step4PAR_checkbox_text__3kJT4 {
  color: #858585;
  font-size: 13px;
  margin-left: 4px;
}
.Step4PAR_CheckBoxInputBox__2EvYw {
  cursor: pointer;
}
.Step4PAR_LoadingBtn__2Fky4 {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 10px 10px;
  width: 55%;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
}
.Step4PAR_formContainer__WUXC_ {
  display: flex;
  flex-direction: column;
}
.Step4PAR_lableDesign__18m0T {
  margin: 15px 0px;
  font-weight: 800;
  color: #292e42;
}
.Step4PAR_CheckboxStyle__2wg7V {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border: 1px solid #ece9e9;
  margin: 7px 0px;
}
.Step4PAR_CheckBox_Text__1L3Dq {
  padding-left: 28px;
}
.Step4PAR_CheckBox_RightText__3oVjL {
  background-color: #292e42;
  padding: 0px 12px;
  height: 4vh;
  margin: -13px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: #fff;
  width: 70px;
  text-align: center;
}
.Step4PAR_CheckBoxInput__XOSJJ {
  display: flex;
}
.Step4PAR_CheckBoxInput_Text__3U8zu {
  padding-left: 5px;
  font-weight: bold;
  margin: 0;
}

.Step4PAR_TotalAmountText__3SpJY {
  text-align: right;
  padding: 5px 0px;
}
.Step4PAR_AmountText__2ckYH {
  font-weight: bold;
  font-size: 18px;
}
.Step4PAR_TermsCondition_Text__1WUMP {
  margin-top: 20px;
}
.Step4PAR_PARBtn_Link__xIFls {
  text-decoration: none;
}
.Step4PAR_ActionBtn__3jQXs {
  display: flex;
  justify-content: center;
}
.Step4PAR_Heading_text__3LY-I {
  margin: 0px;
}
.Step4PAR_para_text__mZxjK {
  margin: 4px 1px 15px 1px;
}
.Step4PAR_inputDiv__1BlMC {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.Step4PAR_inputText__DM4-M {
  width: 40%;
  margin-right: 20px;
}
.Step4PAR_budgetCurrency__3tP6d {
  display: flex;
  width: 60%;
}
.Step4PAR_inputArea__200zN {
  padding: 10px 10px;
  outline: none;
  width: 40%;
  border: 1px solid #ece9e9;
  display: flex;

  margin: 10px 0px;
  font-size: 18px;
}
.Step4PAR_curencySymbol__3oEvu{
  padding-right: 5px;
  margin-right: 5px;
  font-size: 18px;
  border-right: 1px solid gray;
}
.Step4PAR_inputArea__200zN:focus {
  border-color: #1778f2;
}
.Step4PAR_budgetContainer__1QUrd {
  display: flex;
  justify-content: space-between;
}
.Step4PAR_inputAreaBudget__8w6QD{
  border: none;
  width: 90%;
  font-size: 18px;
  outline: none;
}
.Step4PAR_inputArea_dropdown__2Vtcp {
  border: 1px solid #ece9e9;
  /* height: 45px; */
  width: 45%;
  font-size: 18px;
  outline: none;
  padding:  12px;
  cursor: pointer;
  background-color: white;
  margin: 10px 0px;
  color: #000;
  -webkit-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 10.127L12 18.127L20 10.127H4Z" fill="%238E8E93"/></svg>');
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: 98%;
}
.Step4PAR_inputSelect__3UXuy {
  width: 40%;
}
.Step4PAR_inputArea_currency__1ySwV {
  border: 1px solid #ece9e9;
  height: 45px;
  width: 46%;
  font-size: 18px;
  outline: none;
  padding-left: 8px;
  cursor: pointer;
  background-color: white;
  margin: 10px 0px;
  color: #000;
}
.Step4PAR_Icon__3F0jR {
  padding: 6px 0px 0px 2px;
}
.Step4PAR_lableDesign__18m0T {
  margin: 15px 0px 0px 0px;
  font-weight: 500;
  color: #000;
  display: flex;
}
.Step4PAR_error_message__1td9j {
  color: rgb(250, 0, 0);
  display: flex;
  align-items: center;
}
.Step4PAR_back_button__2vzND {
  background: #fff;
  border: none;
  margin-top: 15px;
  cursor: pointer;
}
.Step4PAR_AddonCardConatiner__332XO {
  width: 400px;
}
@media screen and (min-width: 1023px) {
  .Step4PAR_Maincontainer_border__O-ach {
    padding: 0px 20px;
    width: 40%;
    margin: auto;
    background-color: white;
    border-width: 1px;
    border-color: rgb(223, 223, 223);
    border-style: solid;
    margin-top: 20px;
  }
  .Step4PAR_Pagewrapper__3kl1T {
    background-color: #f5f5f5;
    padding-top: 20px;
    padding-bottom: 50px;
  }
}

.Step6PAR_modalContainer__nkFLX {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
}

.Step6PAR_modal__gsd_o {
  background-color: white;
  margin: auto;
  position: relative;
  font-weight: 500;
  width: 450px;
  padding: 40px 20px;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
  z-index: 30;
}
.Step6PAR_button__2cYhr {
  width: 70px;
  color: #f02f39;
  padding: 2px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.Step6PAR_ActionBtn__3MksP {
  display: flex;
  justify-content: space-around;
}

@-webkit-keyframes Step6PAR_slide-down__3OeT1 {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes Step6PAR_slide-down__3OeT1 {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
.Step6PAR_ModalCheckIcon__2bxUA {
  text-align: center;
}
.Step6PAR_ModalCheckTextFirst__3GxKB {
  text-align: center;
  padding-top: 16px;
  font-size: 14px;
  font-weight: bold;
}
.Step6PAR_ModalCheckTextSecond__1IIyB {
  text-align: center;
  padding-top: 16px;
  font-size: 16px;
  font-weight: bold;
}
.Step6PAR_modalcheck__Hg588 {
  height: 60px;
  width: 60px;
  margin-top: -90px;
  border: 1px solid;
  padding: 20px;
  border-radius: 50%;
  background-color: #0c2551;
  color: #fff;
  border-color: #0c2551;
}
.Step6PAR_ModalCheckIconText__2y1I4 {
  text-align: center;
  font-weight: bold;
}
@media screen and (max-width:768px) {
  .Step6PAR_ActionBtn__3MksP {
   
   display: flex;
   
   width: 100%;
   margin: auto;
  }
  .Step6PAR_ActionBtnContainer__1bXkR{
     width: 100%;
     margin: 10px;
  }
  
}
.Step5PAR_mainContainer__247ht {
  padding: 20px;
}
.Step5PAR_input_fields__H05eA {
  display: flex;
  flex-direction: column;
}
.Step5PAR_input_container__2qdga {
  margin-top: 25px;
}
.Step5PAR_textArea__1BXEi {
  padding: 13px 10px;
  border: 1px solid #ece9e9;
  height: 24px;
  margin: 10px 0px;
}
.Step5PAR_label__3DMsa {
  margin-top: 20px;
}
.Step5PAR_pageLoader__VmpfL {
  text-align: center;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Step5PAR_labelSkill__1Sb8Y {
  margin-top: 10px;
}
.Step5PAR_checkbox_text__2lyDQ {
  color: #858585;
  font-size: 13px;
  margin-left: 4px;
}
.Step5PAR_contentContainer__3qMyq {
  padding: 10px;
  margin-top: 20px;
  border-width: 1px;
  display: flex;
  border-color: grey;
  border-style: solid;
}
.Step5PAR_contentArea__1YQRZ {
  margin-left: 10px;
}

.Step5PAR_headingRequirementType__3iw8o {
  font-weight: bold;
}

.Step5PAR_buttonPlacement__3tee- {
  width: 70%;
  padding: 20px;
  margin: auto;
}

.Step5PAR_radioButton__2TAsG {
  color: yellow;
}
.Step5PAR_formContainer__2KF6P {
  display: flex;
  flex-direction: column;
}

.Step5PAR_CheckboxStyle__2_vSR {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border: 1px solid #ece9e9;
  margin: 20px 0px;
}
.Step5PAR_CheckBox_Text__3D8Cv {
  padding-left: 40px;
}
.Step5PAR_CheckBoxInput__29FcB {
  display: flex;
  align-items: center;
}
.Step5PAR_CheckBoxInput_Text__1VqVq {
  padding-left: 8px;
  font-weight: bold;
  margin: 0;
}
.Step5PAR_CheckboxStyle__2_vSR {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border: 1px solid #ece9e9;
  margin: 7px 0px;
}
.Step5PAR_CheckBox_Text__3D8Cv {
  padding-left: 28px;
}
.Step5PAR_CheckBox_RightText__3csfu {
  background-color: #292e42;
  padding: 0px 12px;
  height: 4vh;
  margin: -13px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: #fff;
  width: 8vw;
  text-align: center;
}
.Step5PAR_CheckBoxInput__29FcB {
  display: flex;
}
.Step5PAR_CheckBoxInput_Text__1VqVq {
  padding-left: 8px;
  margin: 0;
}
.Step5PAR_TotalAmountText__1l9xF {
  text-align: right;
  padding: 10px;
}
.Step5PAR_AmountText__2GIfb {
  font-weight: bold;
  font-size: 18px;
}
.Step5PAR_TermsCondition_Text__27K_I {
  margin-top: 20px;
}
.Step5PAR_RiTextInputsStyle__1A7h6 {
  margin-top: 18px;
}
.Step5PAR_RiTextInputsDropDown__1utHg {
  margin-top: 14px;
}
.Step5PAR_ActionBtn__oW6hS {
  display: flex;
  justify-content: center;
}
.Step5PAR_Heading_text__1jKtz {
  margin: 0px;
}
.Step5PAR_para_text__iLoUC {
  margin: 4px 1px 15px 1px;
}
.Step5PAR_back_button__cpQzh {
  background: #fff;
  border: none;
  margin-top: 15px;
  cursor: pointer;
}
.Step5PAR_lableDesign__1QD2g {
  margin: 15px 0px 0px 0px;
  font-weight: 500;
  color: #000;
  display: flex;
}
.Step5PAR_LoadingBtn__2uvUV {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 10px 10px;
  width: 55%;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
}
.Step5PAR_LogoImage__2sLhw {
  padding: 10px 10px;
  outline: none;
  display: flex;
  text-align: center;
  height: 200px;
  width: 50%;
  margin: 10px 0px 30px;
  font-size: 18px;
}
@media screen and (min-width: 1023px) {
  .Step5PAR_Maincontainer_border__tgUdf {
    padding: 0px 20px;
    width: 40%;
    margin: auto;
    background-color: white;
    border-width: 1px;
    border-color: rgb(223, 223, 223);
    border-style: solid;
    margin-top: 20px;
  }
  .Step5PAR_Pagewrapper__3w9oH {
    background-color: #f5f5f5;
    padding-top: 20px;
    padding-bottom: 50px;
  }
}
.Step5PAR_CheckBox_Text__3D8Cv {
  padding-left: 28px;
}
.Step5PAR_CheckBoxInput_Text__1VqVq {
  padding-left: 8px;
  margin: 0;
}
.Step5PAR_RadioOne__1IGOH {
  border: 1px solid #ece9e9;
  padding: 8px;
  margin-top: 15px;
}
.Step5PAR_RadioTwo__2YyLt {
  border: 1px solid #ece9e9;
  padding: 8px;
  margin-top: 8px;
}
.Step5PAR_TextInputRadioSelected__1Sp1P {
  margin-top: 20px;
}
.Step5PAR_selectoption__3_n0y {
  margin-left: 10px;
}
.Step5PAR_Skillcontainer__2VK-v {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 14px;
}
.Step5PAR_Skill__3Q6Zu {
  width: 100%;
  padding: 5px 0px;
  margin: 0px 4px;
}

.AboutUs_para_text_container__2g0IV {
  margin: 0px 15px;
}

.AboutUs_para_text__14MxL {
  margin: 10px 0px 30px 0px;
  font-size: 16px;
}

.AboutUs_theFutureOfWork__WQk4d {
  color: gray;
  font-size: 20px;
}
.AboutUs_yellowbar__28XDO {
  display: flex;
  flex-direction: column;
  padding: 22px 22px 0px;
  align-items: center;
  background-color: #f2c910ef;
}
.AboutUs_yellowtitle__1gDRd {
  font-size: 22px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  text-align: center;
}

.AboutUs_para_text_mid__SpPZz {
  font-size: 16px;
  margin: 10px;
  margin-left: 0px;
  color: #3f3f3f;
  font-weight: 500;
}

.AboutUs_midques__1sXo_ {
  font-weight: 600;
  color: #0c3568;
}
.AboutUs_futurecharter__30oTl {
  color: rgb(211, 5, 5);
  font-size: 20px;
  font-weight: 600;
}

.AboutUs_applybtn__3CRPQ {
  height: 10px;
}

.AboutUs_bluebar__1-_q- {
  display: flex;
  flex-direction: column;
  padding: 22px 22px 0px;
  align-items: center;
  background-color: #2c3333;
}

.AboutUs_bluetitle__3lNmr {
  font-size: 22px;
  font-weight: 600;
  display: flex;
  color: #fff;
  justify-content: center;
  text-align: center;
}
.AboutUs_imgFirstBloc__Aaq7k {
  margin: 14px auto;
}
.AboutUs_bluehead__1hNt- {
  background-color: #00233e;
  height: 300px;
}
.AboutUs_greyhead__RNJI1 {
  background-color: #e6e6e6;
  height: 570px;
}
.AboutUs_headBg__3m2Z_ {
  position: relative;
}
.AboutUs_headImg__idzCl {
  width: 90%;
  height: 65%;
  left: 3%;
  padding: 10px;
  top: 3%;
  position: absolute;
  display: block;
  text-align: center;
}

.AboutUs_swpname__Ufeci {
  color: #a70606;
  font-weight: 600;
  font-size: 26px;
  text-align: center;
  padding-top: 10px;
}
.AboutUs_swpdetailhead__2NRyk {
  color: #201f1f;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
.AboutUs_logo__hwnSZ {
  margin: 20px auto 0px;
  padding: 4px;
}
.AboutUs_headImgphotoWeb__ZyXdC {
  display: none;
}
.AboutUs_headImgphotoMob__1KUYI {
  display: block;
}
.AboutUs_bgYllow__WaTNn {
  background-color: yellow;
}
.AboutUs_green__2Pfwi {
  width: 30%;
  background-color: green;
}
.AboutUs_logoweb__2dwIm {
  display: none;
}
.AboutUs_bnnrDetailsHead__2rRvi {
  display: none;
}
.AboutUs_overlay__3O1B3 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
}

.AboutUs_modal__2XjXB {
  background-color: white;
  margin: auto;
  position: relative;
  font-weight: 500;
  top: -25px;
  width: 84%;
  padding: 20px 0px;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
  z-index: 30;
}
.AboutUs_closeModal__3wm8W {
  position: absolute;
  top: 4px;
  right: 10px;
  padding: 6px;
}
.AboutUs_Message__3yzcs {
  display: block;
  font-size: 15px;
  padding: 10px 12px 20px 12px;
  text-align: center;
  font-weight: 400;
  color: #000;
}
.AboutUs_LoaderCenter__14P6X {
  text-align: center;
}
.AboutUs_msgbtmcenter__bEJIs {
  text-align: center;
}
.AboutUs_title__yl8JX {
  display: block;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  margin-top: 9px;
  color: #000;
}

.AboutUs_paraContainer__3wHQo {
  padding: 10px 0px 10px 20px;
}
.AboutUs_imgContainer__24Fc9 {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
.AboutUs_imgParaContainer__lGFu8 {
  width: 100%;
}
.AboutUs_lastParaContainer__30tGY {
  padding-top: 20px;
}
@media screen and (min-width: 1023px) {
  .AboutUs_para_text_container__2g0IV {
    margin: 0px 30px;
    padding: 30px;
  }

  .AboutUs_yellowbar__28XDO {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 22px;
  }
  .AboutUs_bluebar__1-_q- {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 22px;
  }
  .AboutUs_imgFirstBloc__Aaq7k {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .AboutUs_colorBoxBg__3IeDa {
    display: flex;
    flex-direction: row;
    background-color: #e6e6e6;
  }
  .AboutUs_bluehead__1hNt- {
    height: auto;
    width: 260px;
  }
  .AboutUs_headImg__idzCl {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .AboutUs_logoweb__2dwIm {
    display: block;

    padding-top: 10px;
  }

  .AboutUs_logo__hwnSZ {
    display: none;
  }

  .AboutUs_headImgphotoWeb__ZyXdC {
    display: block;
    margin-right: 20px;
  }
  .AboutUs_headImgphotoMob__1KUYI {
    display: none;
  }
  .AboutUs_swpname__Ufeci,
  .AboutUs_swpdetailhead__2NRyk {
    text-align: start;
  }

  .AboutUs_redStripe__1pEeL {
    background-color: #a70606;
    height: 100px;
    width: 30px;
    margin-right: 5px;
  }
  .AboutUs_redstripeBox__36LBF {
    display: flex;
  }

  .AboutUs_redstripeBoxOuter__WT39i {
    display: flex;
    margin-top: 15px;
  }
  .AboutUs_redstripeText__1VbGc {
    font-size: 17px;

    text-align: start;
  }
  .AboutUs_bnnrparaDetails__YlsvR {
    font-size: 18px;
    font-weight: 500;
    color: #201f1f;
    text-align: start;
  }
  .AboutUs_swpname__Ufeci {
    font-size: 40px;
  }
  .AboutUs_swpdetailhead__2NRyk {
    font-size: 26px;
    color: #201f1f;
    font-weight: 700;
  }
  .AboutUs_bnnrDetailsHead__2rRvi {
    display: block;
  }
  .AboutUs_modal__2XjXB {
    background-color: white;
    margin: auto;
    position: relative;
    font-weight: 500;
    top: -25px;
    width: 30%;
    padding: 23px 0px;
    border-radius: 14px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
    z-index: 30;
  }
  .AboutUs_Message__3yzcs {
    padding: 12px;
    font-size: 20px;
    color: #000;
  }

  .AboutUs_title__yl8JX {
    display: block;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    margin-top: 18px;
    color: #000;
  }
  .AboutUs_imgContainer__24Fc9 {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    align-items: center;
    justify-content: space-between;
  }
  .AboutUs_imgParaContainer__lGFu8 {
    width: 72%;
  }
}

.ContactUs_ContactUs_mainheader__3GQ2D {
  background-color: white;
  margin: 10px 10px 10px 10px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.ContactUs_para_text__29zEY {
  margin: 30px 20px;
}
.ContactUs_contactForm_container__o0Cor {
  margin-top: 20px;
}
.ContactUs_ErrorMessage__1j4vk {
  color: #ff2222;
  font-style: italic;
  font-size: 14px;
  margin: 0px;
}
.ContactUs_ActionButton__3mz-F {
  display: flex;
  justify-content: center;
}

.ContactUs_faq_padding__1JPlQ {
  padding: 30px 0;
}
.ContactUs_faq_firstheading__RHbMy {
  font-size: 30px;
  font-family: "Poppins";
}
.ContactUs_faq_secondheading__30a68 {
  color: #5d5d5d;
  font-size: 16px;
  padding: 5px 0px 25px 0px;
  font-family: "Manrope";
}
.ContactUs_LoadingBtn__2gWOw {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 10px 10px;
  width: 55%;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
}
@media screen and (min-width: 768px) {
  .ContactUs_para_text__29zEY {
    margin: 40px 14vw 50px 14vw;
  }
  .ContactUs_ContactUs_mainheader__3GQ2D {
    margin: 10px 10px 10px 0px;
  }
}

.Faqdropdown_faq_question__VSg7V {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding-bottom: 15px;
  font-family: "Manrope";
  font-size: 15px;
  font-weight: 500;
  width: 100%;
}
.Faqdropdown_faq_questiondiv__oJfJ9 {
  display: flex;
  border-bottom: 1px solid #dfdfdf;
}

.Faqdropdown_faq_answer__16Lgu {
  border-bottom: 1px solid #dfdfdf;
  margin-top: 20px;
  padding-bottom: 20px;
  color: #5d5d5d;
  font-family: "Manrope";
  font-size: 15px;
}

.Faqdropdown_faq_border__rhi8- {
  margin: 15px 0px;
}

.Faqdropdown_faq_icon__2FeqM {
  width: auto;
}

@media screen and (min-width: 1024px) {
  .Faqdropdown_faq_icon__2FeqM {
    width: auto;
  }
  .Faqdropdown_faq_question__VSg7V {
    font-size: 18px;
  }

  .Faqdropdown_faq_answer__16Lgu {
    font-size: 16px;
  }
}

.RIHeader_headerMainContainer__2TnR- {
  width: 100%;
  align-items: center;
  background-color: #ffffff;
  display: flex;
}

.RIHeader_menuContainerTop__1sR9c {
  justify-content: space-between;
  display: flex;
  width: 100%;
  margin-right: 30px;
  margin-left: 25px;
  align-items: center;
}
.RIHeader_menuContainerTopMobile__2Yfrc {
  display: flex;
  margin-left: 25px;
  align-items: center;
}
.RIHeader_MenuBtnLink__3yJ_I{
  text-decoration: none;
  color: #000;
}
.RIHeader_MenuBtnLink__3yJ_I:hover{

  color: #1778f2;
}
.RIHeader_headerWeb__30935 {
  background-color: #ffffff;
  display: flex;
  padding: 15px;

  box-shadow: 0 4px 2px -2px rgb(236, 236, 236);
  align-items: center;
  max-width: 1600px;
  margin: auto;
}
.RIHeader_headerMobile__3yCjZ {
  padding: 15px;
}
.RIHeader_topMenuHeads__2ViVs {
  display: flex;
}

.RIHeader_menubuttons__2Qh3u {
  margin-left: 20px;
}
.RIHeader_menubuttonsLogin__1X686{
  margin-left: 20px;
  font-weight: 600;

}

.RIHeader_menubuttons__2Qh3u:hover {
  color: #1778f2;

  cursor: pointer;
}
.RIHeader_topMenuHeadsRight__3r228 {
  display: flex;
  align-content: center;
  align-items: center;
}

.RIHeader_menuDropdown__2tExc {
  display: block;
  position: absolute;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transition: all 0.5s ease-out;
  padding: 20px;
  background-color: white;
  float: right;
  right: 30px;
  top: 65px;
  border-radius: 10px;
  visibility: inherit;
  visibility: visible;
  z-index: 1;
}
.RIHeader_menuDropdownHide__na1pS {
  display: none;
}

.RIHeader_linkTag__1Lckj {
  text-decoration: none;
}
.RIHeader_iconmm__2XWdy {
  margin: auto;
}
.RIHeader_userMenuOptions__1erRH {
  padding-left: 10px;
}
.RIHeader_iconAndText__1Sgha {
  display: flex;
  align-content: center;
  padding: 10px;
  align-items: center;
  color: #000;
}
.RIHeader_iconAndText__1Sgha:hover{
  color: #1778f2;

}
.RIHeader_userMenuOptions__1erRH:hover {
  color: #1778f2;

  cursor: pointer;
}

.RIHeader_headerMainContainer_Mobile__TVSuQ {
  position: relative;
  min-height: 70px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
}

.RIHeader_PARBtn_Link__3RlnZ {
  text-decoration: none;
}
.RIHeader_ActionButton__3Z-Pu {
  display: flex;
  margin-right: 20px;
}
.RIHeader_back_button__3VOGP {
  background-color: #ffffff;
  border: none;
  cursor: pointer;
}
.RIHeader_riLogo__2KoxZ {
  margin-left: 30px;
}
.RIHeader_riLogo_Mobile__24adf {
  margin-left: 10px;
}

.RIHeader_riLogo_MobileBack__qqJfF {
  margin: auto;
  padding: 8px;
}
.RIHeader_heading1__1iAfq {
  font-size: 18px;
  padding: 5px;
  padding-right: 15px;
font-weight: 600;
  color: black;
}
.RIHeader_heading1__1iAfq:hover {
  cursor: pointer;
  color: #1778f2;
}
.RIHeader_hamburger__2mM9V {
  padding-left: 15px;
  color: #69737c;
}

.RIHeader_backIcon__3lIMj {
  color: #0c2551;
  padding: 5px;
  padding-left: 2%;
}
.RIHeader_join__18BeW {
  padding: 5px;
  padding-left: 2%;
}
.RIHeader_menuOption__16EIY {
  display: flex;
  align-self: center;

  align-items: center;
  justify-content: space-between;
  width: auto;

  margin-right: 30px;
}
.RIHeader_menuTitle__1dyPm {
  font-weight: bold;

  margin-right: 30px;
  color: rgb(36, 36, 36);
}

.RIHeader_menuTitle__1dyPm:hover {
  color: rgb(209, 209, 209);
  cursor: pointer;
}

.RIHeader_logoAndMenu__2IWtA {
  display: flex;
  padding-left: 2%;
}
.RIHeader_menuWrapper__1Jnl_ {
  background-color: #ffffff;
  height: 100%;
}

.RIHeader_close_button__2h1Bg {
  display: flex;
  justify-content: flex-end;
  color: #ffffff;
}
.RIHeader_crossIcon__3joYv {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.RIHeader_JoinBtn_Link__3QlAU {
  text-decoration: none;
  color: #fff;
}
.RIHeader_drawer_menu_header__3nJxS {
  background-color: #1778f2;
  padding: 15px 15px 33px 15px;
}
.RIHeader_menuProfile_image__1NXTq {
  width: 85px;
  height: 85px;
  background-color: #a38585;
  border-radius: 50%;
}
.RIHeader_menu_profile__P-zAA {
  display: flex;
  align-items: center;
  width: 93%;
  justify-content: space-evenly;
  color: #ffffff;
}
.RIHeader_menuProfile_name__3aoDP {
  margin: 0px;
}
.RIHeader_menuProfile_button__3OoV- {
  padding: 4px 14px;
  margin-top: 13px;
  border-radius: 10px;
  border: none;
}
.RIHeader_menuNav_contaner__2yaNN {
  background-color: #ffffff;
}
.RIHeader_menuNav__2-R6k {
  padding: 24px 30px;
}
.RIHeader_link_container__3tX_0 {
  display: flex;
  align-items: center;
  height: 50px;
  font-weight: 500;
}
.RIHeader_link__2cE0L {
  text-decoration: none;
  color: #000;
  margin-left: 15px;
}
.RIHeader_border__2L8RD {
  border-bottom: 1px solid #bdb8b8;
  margin: 30px 0px 30px 15px;
}
.RIHeader_link_bottom__2mld4 {
  color: #0c2551;
  text-decoration: none;
  margin-left: 15px;
}
.RIHeader_menu_icon__3jaB4 {
 /*  color:linear-gradient(45deg, rgb(255, 116, 116), transparent); */
 color: white;
 padding: 5px;

 background: -webkit-linear-gradient(45deg,#00c6ff, #0072ff);
 /*  height: 22px;
  width: 22px; */
}
.RIHeader_menu_icon_noBg__19RqK{
  
}

.RIHeader_menu_nav__hYOss {
  padding: 0px 30px 24px 30px;
}
.RIHeader_linkDesignNone__1Z6od {
  text-decoration: none;
  color: #191919;
}
.RIHeader_overlay__1x52n {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
}

.RIHeader_modal__3M9WO {
  background-color: white;
  margin: auto;
  position: relative;
  font-weight: 500;
  top: -25px;
  width: 84%;
  padding: 20px 0px;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
  z-index: 30;
}
.RIHeader_closeModal__1LzMD {
  position: absolute;
  top: 4px;
  right: 10px;
  padding: 6px;
}
.RIHeader_Message__3WynN{
  display: block;
  font-size: 15px;
  padding: 10px 12px 20px 12px;
  text-align: center;
  font-weight: 400;
}
.RIHeader_LoaderCenter__2vwJO{
  text-align: center;
}
.RIHeader_msgbtmcenter__2MANl{
  text-align: center;
}
.RIHeader_title__2z6fs{
  display: block;
  font-size: 18px;
  text-align: center;
  font-weight:500;
  margin-top: 9px;
}
.RIHeader_loginHighlight__1uM94{
  font-weight: bold;
}
@media screen and (min-width: 1023px) {
  .RIHeader_modal__3M9WO {
    background-color: white;
      margin: auto;
      position: relative;
      font-weight: 500;
      top:-25px;
      width: 30%;
      padding: 23px 0px;
      border-radius: 14px;
      box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
      z-index: 30;
  }
  .RIHeader_Message__3WynN{
    padding: 12px;
    font-size: 20px;
  }

  .RIHeader_title__2z6fs{
      display: block;
      font-size: 20px;
      text-align: center;
      font-weight:500;
      margin-top: 18px;
  }

  
}
.ProfileIcon_iconContainer__3yQw5 {
  border-radius: 50px;
  color: white;
  background-color: #1778f2;
}
.ProfileIcon_iconContainer__3yQw5:hover {
  background-color: #f5f5f5;
  color: rgb(0, 0, 0);
}
.ProfileIcon_iconContainerLeftMenu__3bVi1 {
  border-radius: 50px;
  background-color: white;
  color: #1778f2;
}
.ProfileIcon_innerInitial__U99B- {
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
}
.ProfileIcon_innerInitialLeftMenu__3MGrf {
  padding: 28px;
  font-size: 18px;
  font-weight: bold;
}

.ProfileIcon_smalliconContainer__1-p34 {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  background-color: #f5f5f5;
  margin-left: 4px;
  margin-right: 4px;
  text-align: center;
}
.ProfileIcon_smallinnerInitial__3hh-N {
  padding: 6px;
  font-size: 12px;
  font-weight: bold;
}
.ProfileIcon_smalliconContainer__1-p34:hover {
  background-color: #1778f2;
  color: white;
  cursor: pointer;
}

.ProfileIcon_profilePicture__2tLr9 {
  object-fit: cover;
  
  border-radius: 50px;
  border: 1px solid #bbb;
 
}
.ProfileIcon_topProfilePicture__2-5vb{
  width: 60px;
  height: 60px;
  border: 1px solid #bbb;
  cursor: pointer;
  object-fit: cover;
  border-radius: 50%;
}
.ProfileIcon_profileImage__Fr-Eo {
  width: 40px;
  height: 40px;
}
.ProfileIcon_profileImageLeftMenu__1FGvO {
  width: 60px;
  height: 60px;
}
.ProfileIcon_logooverlaped__3GBjC {
  display: flex;
  justify-content: end;
  margin: 1rem;
}
.ProfileIcon_freelancerProfileContainer__1Woar {
  margin-left: -5%;
}
.ProfileIcon_logo_images__1Lzat {
  width: 100%;
  height: 100%;
  border: 1px solid #fff;
  cursor: pointer;
  object-fit: cover;
  border-radius: 50%;
}

.ProfileIcon_profileImageDisplay__qcycP {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: whitesmoke;

  align-items: center;
  display: inline-grid;
}

.ProfileIcon_profileImageDisplay__qcycP:hover {
  cursor: pointer;
}
.ProfileIcon_nameAlign___tEMF {
  text-align: center;
}
.ProfileIcon_requirementcard_icons__2ZcJE {
  width: 50px;
  height: 50px;
  border: 1px solid #bbb;
  cursor: pointer;
  object-fit: cover;
  border-radius: 50%;
}

.CustomModalTheme_cancelModel__1JZgX {
  text-align: end;
}

.CustomModalTheme_modalContainer__NlSwM {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
}

.CustomModalTheme_modal__RIzwJ {
  background-color: white;
  margin: auto;
  position: relative;
  font-weight: 500;
  width: 500px;
  padding: 20px;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
  z-index: 2;
  padding-bottom: 30px;
}
.CustomModalTheme_backIcon__37im7 {
  cursor: pointer;
  height: 25px;
}
.CustomModalTheme_button__xce9u {
  width: 70px;
  color: #1778f2;
  padding: 2px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.CustomModalTheme_modalContainerWidth__3HUQK {
  background-color: white;
  margin: auto;
  position: relative;
  font-weight: 500;
  width: 602px;
  padding: 20px;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
  z-index: 2;

  padding-bottom: 30px;
}
.CustomModalTheme_mainContainer__1hMJY {
  padding: 0px 30px;
}
@media screen and (max-width: 1023px) {
  .CustomModalTheme_modal__RIzwJ {
    width: 80%;
  }
  .CustomModalTheme_modalContainerWidth__3HUQK {
    margin-left: 20px;
    margin-right: 20px;
  }
  .CustomModalTheme_mainContainer__1hMJY {
    padding: 0px 15px;
  }
}

.ProposalDetails_ProposalTitle__JZae3 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 30px;
}
.ProposalDetails_mainHeading__2BKvt {
  font-weight: 800;
  font-size: 25px;
  padding-bottom: 20px;
  text-align: center;
}
.ProposalDetails_nameContainer__OfYSQ {
  display: flex;

}

.ProposalDetails_skilltagsContainer__2ml9y {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.ProposalDetails_skillTags__eBqk2 {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  background-color: #f5f5f5;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 10px;
}
.ProposalDetails_reqBox__vkTzX{
padding: 5px;
  border: 1px solid #bbb;
  margin-top: 10px;
  margin-bottom: 10px;
}
.ProposalDetails_coloredIcon__27uUW {
  margin: 0 4px 0 20px;
}
.ProposalDetails_budgetContainer__17HWC {
  margin: 20px 0;
  font-weight: 500;
}
.ProposalDetails_freelancerProposal__ahmwV {
  font-size: 17px;
  font-weight: 400;
}
.ProposalDetails_budgetUnit__3CK8N {
  font-size: 12px;
}
.ProposalDetails_title__3zD-P {
  
font-weight: 400;
}
.ProposalDetails_depositRequested__3r3lt {
  font-size: 17px;
  font-weight: bold;
}
.ProposalDetails_heading__1hddv {
  font-weight: bold;
  font-size: 17px;
}
.ProposalDetails_budgetheading__wBW_9{
  font-weight: bold;
  font-size: 17px;
  text-align: right;
}
.ProposalDetails_buttonContainer__2OVhM {
  display: flex;
  margin-top: 10px;
 
  margin: auto;
  justify-content: space-between;
}
.ProposalDetails_addonNameIcon__3PHeH{
  margin: 0px 3px 0px 10px ;
}
.ProposalDetails_addonContainer__x5FGh{
  display: flex;
  align-items: center;
}
.ProposalDetails_addanName__PX95H{
display: flex;
}
.ProposalDetails_rejectCustomButton__UzRe7{
  padding: 10px;
}
.ProposalDetails_thumbsUpIcon__3OEAP{
 
  font-size: 40px;
  color: #1878F2;
 
  
}
.ProposalDetails_headingWithIcon__1W-iT{
  text-align: center;
}
@media screen and (min-width: 1023px) {
  .ProposalDetails_buttonContainer__2OVhM {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .ProposalDetails_chatButton__2bB4o {
    margin-right: 30px;
  }
}

.ReportaBug_top_link__1CpvD {
  margin: 10px 0px 30px 0px;
  color: #707070;
}

.ReportaBug_home_link__2XZVl {
  text-decoration: none;
  color: #707070;
}

.ReportaBug_home_link__2XZVl:first-child {
  color: #1778f2;
}


.ReportaBug_reportabug_bgcolor__18jwe {
  background: #f7f7f8;
  padding: 20px;
}

.ReportaBug_reportabug_header__2MLJA {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}
.ReportaBug_reportabug_heading__2WmuW {
  padding-bottom: 10px;
}

.ReportaBug_reportabug_border__2uY7P {
  width: 70%;
  height: 100px;
  text-align: center;
  opacity: 0;
}

.ReportaBug_reportaabug_image__2UoIF {
  height: 50px;
  width: 50%;
  padding-top: 25px;
}

.ReportaBug_input_rozgar__20rJD {
  border: 2px dotted #ece9e9;
  width: 70%;
  height: 145px;
  margin-bottom: -100px;
  margin-top: 10px;
  text-align: center;
}
.ReportaBug_reportabug_dropdown__2tiEh {
  width: 100%;
  padding: 13px 10px;
  border-color: #ece9e9;;
  cursor: pointer;
  margin: 10px 0px;
  height: 45px;
  color: #707070;
  outline: none;
  background-color: #fff;
  text-decoration: none;

}
.ReportaBug_ErrorMessage__1FFLB {
  color: #ff2222;
  font-size: 12px;
  margin-top: 1px;
}
.ReportaBug_reportabug_Button__2_Zdv {
  background-color: #1778f2;
  color: white;
  height: auto;
  width: 60%;
  border-radius: 6px;
  padding: 10px 10px 10px 10px;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;

}
.ReportaBug_LoadingBtn__mnpbj {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 10px 10px;
  width: 55%;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
}



@media screen and (min-width:768px) {
  .ReportaBug_reportabug_bgcolor__18jwe {
      background: #f7f7f8;
      padding: 20px 80px;
  }

  .ReportaBug_reportabug_header__2MLJA {
      padding: 20px 50px;
      width: 60%;
      margin: auto;
  }

  .ReportaBug_input_rozgar__20rJD {
      padding-top: 30px;
      width: 40%;
      height: 140px;
  }

  .ReportaBug_reportabug_border__2uY7P {
      width: 40%;
      height: 111px
  }

  .ReportaBug_reportabug_heading__2WmuW {
      padding-bottom: 10px;
  }

}
.HomePage_HomePageCotainer_web__1SCMq {
  background-image: linear-gradient(to right, #f7f7f7, #f4efeb);
  padding: 40px;
}
.HomePage_HomePageCotainer__1oG2B {
  background-image: linear-gradient(to right, #e8e7e5, #e8e3df);
  padding: 40px 38px 20px;
}
.HomePage_pageLoader__3u-LO {
  text-align: center;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.HomePage_headingMain__1EqeT {
  color: #000000;
  
  font-weight: bolder;
  font-size: 40px;
}
.HomePage_headingPositionMain__2qItW {
  position: absolute;
  margin-left: 20px;
  width: 80%;
  margin-top: 40px;
  color: white;
  font-size: 40px;
  
}

.HomePage_headingMainSecondPart__3Yzwi {
  margin-top: -20px;
 
  color: #1778f2;
  font-weight: bolder;
  font-size: 40px;
  
}

.HomePage_topTextFirstLine__198eT {
  margin: 0px;
}
.HomePage_topTextSecondLine__KfEj4 {
  margin: 6px;
}
.HomePage_topTextContainer__2TL35 {
  text-align: center;
}
.HomePage_topThirdLine__2lt-6 {
  text-align: center;
}
.HomePage_PARBtn_Link__UdFiq {
  text-decoration: none;
}
.HomePage_arrowAndButtonText__33g06{
  display: flex;
  align-items: center;
  margin-top: -20px;
}
.HomePage_arrowAndButtonText__33g06:hover{
  color: rgb(53, 0, 79);
}

.HomePage_arrowAndButtonTextMain__1PMCY{
  display: flex;
  align-items: center;
  
}
.HomePage_arrowAndButtonTextMain__1PMCY:hover{
  color: rgb(53, 0, 79);
}
.HomePage_headingSecondForH__3LpEU{
  margin-top: -20px;
}

.HomePage_imageText__1Gt8G {
  text-align: center;
  color: #fff;
}

.HomePage_backgroundColor__2CyIA {
  width: 100%;
  height: 300px;
  color: #125552;
}

.HomePage_ActionButton_container__3vZ9i {
  position: absolute;

}
.HomePage_HireFreelancers__1uW2T {
  text-align: center;
}
.HomePage_WhyChooseRI__3K5lk {
  margin: 20px;
}
.HomePage_heading_frelancer__1uPri {
  margin: 20px;
}
.HomePage_HireFreelancers_webView__191vH {
  display: flex;
  justify-content: space-around;
  margin: 30px;
}

.HomePage_bannerContent__jE3Bz {
  color: white;
  font-weight: 300;
}
.HomePage_overlay__15zui {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
}

.HomePage_modal__2yPN_ {
  background-color: white;
  margin: auto;
  position: relative;
  font-weight: 500;
  top: -10%;
  width: 90%;
  padding: 20px 0px;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
  z-index: 30;
}
.HomePage_closeModal__2Gc-C {
  position: absolute;
  top: 4px;
  right: 10px;
  padding: 6px;
}
.HomePage_Message__1TgDd{
  display: block;
  font-size: 15px;
  padding: 10px 12px 20px 12px;
  text-align: center;
  font-weight: 400;
}
.HomePage_LoaderCenter__3VTdR{
  text-align: center;
}
.HomePage_msgbtmcenter__2nUVT{
  text-align: center;
}
.HomePage_title__2DGE6{
  display: block;
  font-size: 18px;
  text-align: center;
  font-weight:500;
  margin-top: 9px;
}

.HomePage_reqHeading__3zwqZ{
  font-weight: 800;
  font-size: 25px;
 
}
.HomePage_borderGreen__1x6XC{
  height: 1px;


  background-color: #4EA656;
  margin-top: 20px;
}
.HomePage_headingAndBorder__2Wk0x{
  padding: 20px;
  padding-left: 30px;
  padding-right: 30px;
}
.HomePage_MobviewBanner_image__3LF1A{
  max-height: 375px;
  background-color: #f7f8f8;
}

@media screen and (min-width: 1023px) {
  
  .HomePage_bannerContent__jE3Bz {
    margin-top: 2%;

    width: 50%;
  }
  .HomePage_headingMain__1EqeT{
    font-size: 60px;
  }
  .HomePage_headingMainSecondPart__3Yzwi{
    font-size: 60px;
    margin-top: -30px;
  }
  .HomePage_headingPositionMain__2qItW {
    margin-left: 50px;

    margin-top: 60px;

    font-size: 60px;
  }
  .HomePage_bannerContent__jE3Bz {
    color: white;
    margin-top: 20px;
    font-weight: 300;
    width: 50%;
    font-size: 24px;
  }
  .HomePage_modal__2yPN_ {
    background-color: white;
      margin: auto;
      position: relative;
      font-weight: 500;
      top:-25px;
      width: 30%;
      padding: 23px 0px;
      border-radius: 14px;
      box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
      z-index: 30;
  }
  .HomePage_Message__1TgDd{
    padding: 12px;
    font-size: 20px;
  }
  
  .HomePage_title__2DGE6{
      display: block;
      font-size: 20px;
      text-align: center;
      font-weight:500;
      margin-top: 18px;
  }
  .HomePage_MobviewBanner_image__3LF1A{
    max-height:inherit;
  }
}

.FutureOfWork_mainContainer__3EdXD {
  padding: 15px;
  background-color: #edffff;
}
.FutureOfWork_missionStatement__1ivHC {
  color: #1e1e1e;
  margin: 0px;
  font-size: 20px;
  padding: 10px;
}

.FutureOfWork_knowMore__1YEz4 {
  text-align: right;
  color: #9398a1;
  font-weight: 500;
  font-size: 20px;
  padding: 10px;

  align-items: center;
  align-content: center;
  justify-content: flex-end;
  display: flex;
}

.FutureOfWork_knowMore__1YEz4:hover {
  cursor: pointer;
  color: #1778f2;
}

.FutureOfWork_iconArrow__3IM1Y {
  margin-top: 3px;
}

.FutureOfWork_PARBtn_Link__3MhD7 {
  text-decoration: none;
}

@media screen and (min-width: 1023px) {
  .FutureOfWork_mainContainer__3EdXD {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    padding: 6%;
  }

  .FutureOfWork_webLayout__1kiYm {
    display: grid;
    width: 70%;
    padding-top: 20px;
  }
  .FutureOfWork_missionStatement__1ivHC {
    font-size: 18px;
    padding: 10px;
  }
}

.HowRiCanHelp_HowRiCanHelpContainer__1TLc3 {
  padding: 15px;
  text-align: center;
  background-color: #f5f5f5;
  padding-bottom: 25px;
}

.HowRiCanHelp_iconContainer__2b-JQ {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}
.HowRiCanHelp_headingofEachSection__3nzv_ {
  font-size: 20px;
  font-weight: bold;
  margin-left: 20px;
  margin-bottom: 10px;
}

.HowRiCanHelp_sectionContainer__3AtOQ {
  margin-top: 20px;
  padding: 10px;
}

.HowRiCanHelp_PARBtn_Link__1OHbm {
  text-decoration: none;
  color: #1e1e1e;
}

.HowRiCanHelp_proTip__2Z9P4 {
  font-weight: 400;
  font-size: 14px;
  font-style: italic;
}

.HowRiCanHelp_tipIcon__3fYLD {
  margin-top: 20px;
}

@media screen and (min-width: 768px) {
  .HowRiCanHelp_container__3OVTw {
    padding-top: 40px;
    width: 95%;
    align-content: center;
    align-self: center;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
  .HowRiCanHelp_HowRiCanHelpContainer__1TLc3 {
    padding: 40px;
    margin-bottom: 40px;
    margin-top: 40px;
  
  }
  .HowRiCanHelp_sectionContainer__3AtOQ {
    width: 25%;
  }

  .HowRiCanHelp_headingofEachSection__3nzv_ {
    margin-left: 0px;
    font-size: 18px;
    margin-bottom: 20px;
  }
  .HowRiCanHelp_iconContainer__2b-JQ {
    display: grid;
    align-content: flex-start;
    align-items: center;
  }
  .HowRiCanHelp_icon__Ztefc {
    
    display: flex;
    justify-content: center;
    align-self: center;
    text-align: center;
    margin-bottom: 20px;
  }
  .HowRiCanHelp_subHeadingWeb__-_m-_{
    height: 100px;
  }
  .HowRiCanHelp_tipIcon__3fYLD {
    margin-top: 40px;
  }
}

.NewHeadingRI_mainHeadingContainer__1nuE6 {
 
  
  text-align: center;
}
.NewHeadingRI_newHeadingFirst__27c4k{
  font-size: 25px;
  font-weight: 800;
}

.NewHeadingRI_thirdLine__2qJzR {
  color: #252525;
  font-size: 20px;
  padding-top: 5px;
}
.NewHeadingRI_thirdLineWhite__3PLU1 {
  color: #fff;
  font-size: 20px;
}

.NewHeadingRI_headingTwo__3ayTB {
  margin-top: -10px;
  line-height: 1.2;
}


@media screen and (min-width: 1023px) {
  .NewHeadingRI_newHeadingFirst__27c4k{
    font-size: 35px;
  }
}



.HireForOptions_backGround__2II7f {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
}

.HireForOptions_mainContainer__393b_ {
  overflow-x: scroll;
  display: flex;
  width: 100%;
  margin-top: 10px;
}

.HireForOptions_rectangleBox__2WQRz {
  height: 340px;
  width: 200px;
  min-width: 200px;
  color: white;
  margin: 8px;
  padding: 20px;
}
.HireForOptions_hireforoptions_padding__1_sJZ {
  padding-bottom: 10px;
  height: 310px;
}

.HireForOptions_subHeadingStyle__2Yz-9 {
  margin-top: 5px;
}

.HireForOptions_headingStyle__sGAcx {
  font-weight: bold;
  font-size: 22px;
}

.HireForOptions_blue__IlcNi {
  background-color: #365aa9;
}

.HireForOptions_brown__A8mZs {
  background-color: #486a31;
}

.HireForOptions_green__3zWIY {
  background-color: #125552;
}

.HireForOptions_white__2aby_ {
  background-color: #fea11b;
}

.HireForOptions_knowMore__3sai- {
  text-align: right;
  color: #ffffff;
  font-weight: 500;
  font-size: 20px;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  display: flex;
}

.HireForOptions_knowMore__3sai-:hover {
  cursor: pointer;
  color: #1778f2;
}

.HireForOptions_bulletSection__3U122 {
  font-size: 15px;
  margin-top: 20px;
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
}
.HireForOptions_bulletSectionWithMargin__2oekd {
  font-size: 15px;
  margin-top: 10px;
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
}

@media screen and (min-width: 1023px) {
  .HireForOptions_backGround__2II7f {
    background-color: white;
    padding: 40px;
  }

  .HireForOptions_mainContainer__393b_ {
    justify-content: center;
   
  }

 
  .HireForOptions_mainContainer__393b_ {
    overflow-x: hidden;
  }
  .HireForOptions_subHeadingStyle__2Yz-9 {
    font-size: 14px;
  }
  .HireForOptions_bulletSection__3U122 {
    font-size: 14px;
  }
  .HireForOptions_bulletSectionWithMargin__2oekd {
    font-size: 14px;
  }
}
.HireForOptions_rectangleBox__2WQRz {
  height: 350px;
  width: 200px;

}
@media screen and (min-width: 1280px) {
  .HireForOptions_rectangleBox__2WQRz {
    margin: 25px;
  }
  .HireForOptions_subHeadingStyle__2Yz-9 {
    font-size: 15px;
  }
}

.JoinAsFreelancer_mainContainer__6hhQQ {
  margin-top: 10px;
}

.JoinAsFreelancer_bannerContent__3YANK {
  position: absolute;
  width: 100%;
}

.JoinAsFreelancer_PARBtn_Link___JEpV {
  text-decoration: none;
  margin-top: 0px;
}

.JoinAsFreelancer_ActionButton__122vc {
  align-self: center;
  margin: auto;
  justify-content: center;
  display: flex;
  width: 100%;
}

.JoinAsFreelancer_firstHeading__1c-X9 {
  width: 80%;
  padding-top: 20px;
  margin: auto;
  margin-top: 9%;
  color: white;
  font-size: 28px;
  font-family: 'Manrope', sans-serif;
  text-align: center;
  justify-content: center;
  display: grid;
}
.JoinAsFreelancer_Heading__Cfk4O {
  width: 100%;
  margin: auto;
  color: white;
  font-size: 25px;
  font-family: 'Manrope', sans-serif;
  text-align: center;
  justify-content: center;
  display: grid;
  font-weight: 400;
}

.JoinAsFreelancer_secondHeading__2qB0D {
  font-size: 20px;
  margin: auto;
  color: #FCD017;
  text-align: center;
  justify-content: center;
  display: grid;
  font-weight: bold;
  margin-top: 5%;
}

.JoinAsFreelancer_imageBanner__odcCe {
  display: none;
}
.JoinAsFreelancer_imageBannermob__QEfPA {
  width: 100%;
  max-height: 400px;
  display: block;
}

@media screen and (min-width: 1023px) {

  .JoinAsFreelancer_mainContainer__6hhQQ {
    width: 100%
  }

  .JoinAsFreelancer_firstHeading__1c-X9 {
    width: 100%;
    font-size: 45px;
    padding-top: 20px;
    margin-top: 4%;
  }
  .JoinAsFreelancer_Heading__Cfk4O {
    font-size: 50px;
  }

  .JoinAsFreelancer_bannerContent__3YANK {
    width: 100%;
  }

  .JoinAsFreelancer_secondHeading__2qB0D {
    font-size: 40px;
    margin-top: 0%;

  }
  .JoinAsFreelancer_imageBanner__odcCe {
   

    display: block;
  }
  .JoinAsFreelancer_imageBannermob__QEfPA {
    display: none;
  }
}
@media screen and (min-width: 1280px) {
  .JoinAsFreelancer_firstHeading__1c-X9 {
    width: 100%;
    margin-top: 6%;
  }

}

@media screen and (min-width: 1366px) {
  .JoinAsFreelancer_firstHeading__1c-X9 {
    width: 100%;
    font-size: 60px;
    margin-top: 5%;
  }
  .JoinAsFreelancer_Heading__Cfk4O {
    font-size: 60px;
  }
  .JoinAsFreelancer_secondHeading__2qB0D {
    font-size: 50px;
    margin-top: 1%;

  }

}
.RequestACallBack_mainContainer__2R8gz {
  height: auto;
  background-color: #f5f5f5;
  padding: 40px;
}

.RequestACallBack_headingArea__wQLmO {
  margin-top: 25px;
  margin-bottom: 30px;
}

.RequestACallBack_callHeading__3ypnz {
  font-size: 18px;
  text-align: center;
}

.RequestACallBack_ActionButton__3C37Q {
  justify-content: center;
  display: flex;
}

.RequestACallBack_PARBtn_Link__viZ7r {
  text-decoration: none;
  margin-top: 20px;
}

.SkillsBasedWork_skillOption__x5axb {
  padding: 7px;
  border: 1.5px solid #59acbf;
  border-radius: 8px;
  text-align: center;
  margin: 5px;

  color: #59acbf;
  cursor: pointer;
}
.SkillsBasedWork_knowMore__2r1a9 {
  text-align: right;
  color: #9398a1;
  font-weight: 500;
  font-size: 20px;
  padding: 10px;

  align-items: center;
  align-content: center;
  justify-content: flex-end;
  display: flex;
}
.SkillsBasedWork_knowMore__2r1a9:hover {
  cursor: pointer;
  color: #1778f2;
}
.SkillsBasedWork_tagDisplay__1ib_5 {
  display: flex;
  width: 400px;
  flex-wrap: wrap;
}

.SkillsBasedWork_linkScroll__3PBcj {
  display: flex;
}
.SkillsBasedWork_mainContainer__Pn2Wq {
  padding: 15px;
  background-color: #f3f9ff;
  padding-bottom: 30px;
}
.SkillsBasedWork_setoverFlow__EJd91 {
  overflow: scroll;
  margin-top: 20px;
}
.SkillsBasedWork_setoverFlow__EJd91::-webkit-scrollbar {
  display: none;
}

.SkillsBasedWork_PARBtn_Link__3kPCH {
  text-decoration: none;
}
@media screen and (min-width: 1023px) {
  .SkillsBasedWork_mainContainer__Pn2Wq {
    padding: 50px;
    height: 280px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;
  }

  .SkillsBasedWork_skillOption__x5axb:hover {
    padding: 10px;
    border: 1.5px solid #59acbf;
    border-radius: 8px;
    text-align: center;
    margin: 5px;
    font-size: 12px;
    background-color: #59acbf;
    color: #f3f9ff;
  }
  .SkillsBasedWork_setoverFlow__EJd91 {
    overflow: hidden;
    margin-top: 20px;
  }
 
}
@media screen and (min-width: 768px) {
  .SkillsBasedWork_mainContainer__Pn2Wq {
    padding: 50px;
    height: 250px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;
  }
  .SkillsBasedWork_setoverFlow__EJd91 {
    overflow: scroll;
    margin-top: 20px;
  }
  .SkillsBasedWork_skillOption__x5axb {
    padding: 10px;
    border: 1.5px solid #59acbf;
    border-radius: 8px;
    text-align: center;
    margin: 5px;
    font-size: 12px;
    color: #59acbf;
  }
  .SkillsBasedWork_knowMore__2r1a9{
   padding: 25px 10px;
 
  }
  .SkillsBasedWork_tagDisplay__1ib_5 {
    display: flex;
   
    width: 660px;
    flex-wrap: wrap;
  }
}

.NewRequirementCard_mainContainer__LqvU9 {
  padding: 15px;
  background-color: white;
  margin: 10px;
}

.NewRequirementCard_headingFromProps__xIIsp {
  padding: 20px;
  font-size: 20px;
  font-weight: bold;
}

.NewRequirementCard_pageLoader__2r6xs {
  text-align: center;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.NewRequirementCard_mainCardContainer__18JQy {
  width: 90%;

  padding: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(219, 219, 219);
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}
.NewRequirementCard_imagecontainer__1eEfo {
  width: 90px;
  background-color: rgb(219, 219, 219);
}
.NewRequirementCard_workType__VdAgy {
  color: indigo;
  font-weight: bolder;
  margin-left: 10px;
}



.NewRequirementCard_skillImageContainer__3n3P5 {
  display: flex;
  height: 40%;
}
.NewRequirementCard_skillContainer__1Nbke {
  font-size: 18px;
  font-weight: bold;
  color: #000;
}
.NewRequirementCard_compnayLocationDate__1C_pL{
  color: #000;
}
.NewRequirementCard_titleArea__3rhml {
  margin-top: 10px;
  color: grey;
}
.NewRequirementCard_workStatus__oqff1 {
  background-color: #d6ffe3;
  border-radius: 5px;
  padding: 5px;

  font-weight: bold;
  font-size: 14px;
  color: #43c962;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.NewRequirementCard_actionContainer__3-yuc {
  height: 100px;
}
.NewRequirementCard_ActionButton__2q0Um {
  text-align: right;
}
.NewRequirementCard_PARBtn_Link__30Gak {
  text-decoration: none;
  
}
.NewRequirementCard_postedDate__1SKt4 {
  font-style: italic;
  color: grey;
  margin-left: 10px;
  font-size: 12px;
}
.NewRequirementCard_budget__1-fy7 {
  font-weight: bold;
  font-size: 22px;
  color: #000;
}

.NewRequirementCard_iconAndButtonContainer__2Z2Tr {
  display: flex;
  justify-content: right;
  align-content: center;
  align-items: center;
}

.NewRequirementCard_workPolicy__L9lB- {
  padding: 3px;
  background-color: #3db2be;
  color: white;
  border-radius: 3px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
}
.NewRequirementCard_workPolicyAndWorkType__2STtu {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.NewRequirementCard_statusAnddate__290Mw {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.NewRequirementCard_Commission__xb-0a {
  color: #fea11b;
}

.NewRequirementCard_Contract__1SFvs {
  color: #486a31;
}
.NewRequirementCard_One-Time__3KeTx {
  color: #365aa9;
}
.NewRequirementCard_Monthly__1PMF1 {
  color: #125552;
}

.NewRequirementCard_knowMore__DpGFS {
  text-align: right;
  color: #9398a1;
  font-weight: 500;
  font-size: 20px;
  padding: 10px;

  align-items: center;
  align-content: center;
  justify-content: flex-end;
  display: flex;
}

.NewRequirementCard_knowMore__DpGFS:hover {
  cursor: pointer;
  color: #1778f2;
}

.NewRequirementCard_iconArrow__wcIaA {
  margin-top: 3px;
}

.NewRequirementCard_heartIcon__dYQJ- {
  cursor: pointer;
}
.NewRequirementCard_mainCardContainer__18JQy:hover {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}
.NewRequirementCard_cardHeightSetting__3FwDO {
  cursor: pointer;
}
.NewRequirementCard_budgetUnit__1uTCn {
  font-size: 14px;
  font-weight: 500;
}
.NewRequirementCard_AnchorTag__GrvP9{
  text-decoration: none;
}
@media screen and (min-width: 1023px) {
  .NewRequirementCard_webLayout__2agPY {
    display: flex;

    padding-left: 30px;
    padding-right: 30px;
  }
  .NewRequirementCard_mainContainer__LqvU9 {
    padding: 0px;
  }
  .NewRequirementCard_mainCardContainer__18JQy {
    margin-left: 10px;
    margin-right: 10px;
    padding: 15px;
  }
  .NewRequirementCard_cardHeightSetting__3FwDO {
    height: 250px;
  }

  .NewRequirementCard_knowMore__DpGFS {
    margin-right: 3%;
    margin-bottom: 20px;
  }
}

.RISkeletonLoading_maincontainer__25Mjz {
  width: 97%;
  border: 1px solid rgb(228, 228, 228);
  border-radius: 5px;
  margin: 20px 10px;
  padding-top: 10px;
  padding: 30px;
  align-items: center;
}

.RISkeletonLoading_container__3b91o {
  width: 78%;
  border: 1px solid rgb(228, 228, 228);
  border-radius: 5px;
  margin: 20px 10px;
  padding-top: 10px;
  padding: 30px;
  align-items: center;
}

.RISkeletonLoading_skeletonline__2sKQt {
  overflow: hidden;
  position: relative;
  margin-bottom: 16px;
  background: #eee;
  border-radius: 3px;
  margin-right: 10px;
  width: 100%;
}

.RISkeletonLoading_displey__3b03Z {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.RISkeletonLoading_displey_rozgaar__2fzBB {
  display: flex;
  justify-content: space-between;
  overflow: scroll;
  width: 100%;
}

.RISkeletonLoading_skeleton_recommended__3xRtj {
  padding: 10px;
}

@-webkit-keyframes RISkeletonLoading_skeleton-animation__2gkHr {
  0% {
    right: 100%;
  }

  100% {
    right: -128px;
  }
}

@keyframes RISkeletonLoading_skeleton-animation__2gkHr {
  0% {
    right: 100%;
  }

  100% {
    right: -128px;
  }
}

.RISkeletonLoading_skeletonline__2sKQt:before {
  display: block;
  position: absolute;
  width: 50%;
  height: 500px;
  background: linear-gradient(90deg, rgba(214, 210, 210, 0), rgb(221, 221, 221), rgba(255, 255, 255, 0));
  content: "";
  -webkit-animation: RISkeletonLoading_skeleton-animation__2gkHr 2s infinite;
          animation: RISkeletonLoading_skeleton-animation__2gkHr 2s infinite;
}

.RISkeletonLoading_webLayout__1RaEU {
  display: flex;
  justify-content: center;
  margin: 10px 30px;
  padding: 10px;
}

.RISkeletonLoading_flex__Ghjgd {
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
  margin-top: -20px;
  width: 70%;
  margin: auto;
}

.RISkeletonLoading_skeleton_article__15mDe {
  background: #fff;
}

.RISkeletonLoading_articleRozgaar_maindiv__3EwZq {
  width: 70%;
  margin: auto;
}

.RISkeletonLoading_skeleton_detailarea__bL9IF {
  padding: 30px 20px 0px 20px;
  margin: 10px;
  background-color: #fff;
  height: 230px;
}

.RISkeletonLoading_detail_flex__1bnBU {
  display: flex;

}

.RISkeletonLoading_skeleton_pageheader__1V7xB {
  height: 150px;
  background-color: #fff;
  margin-top: 10px;
  padding-top: 10px;
}

.RISkeletonLoading_pageheader_margin__3tuPW {
  padding-top: 40px;
  padding-left: 20px;
}

.RISkeletonLoading_applicationdetail__35Rb6 {

  padding-top: 50px;
  height: 150px;
  text-align: -webkit-right;

}
.RISkeletonLoading_displey_fivefreelancer__1as1J {
  display: flex;
  justify-content: center;
}

.RISkeletonLoading_displey_addon__1D43a {
  display: flex;
  justify-content: space-between;
  border: 1px solid rgb(232, 232, 232);
  border-radius: 20px;
  padding: 20px 20px 10px 20px;
  margin: 10px;
}

.RISkeletonLoading_displey_ProposalsList__MUWE9 {

  border: 1px solid rgb(232, 232, 232);
  border-radius: 7px;
  padding: 20px 20px 10px 20px;
  margin: 10px;
}
.RISkeletonLoading_skeletonlineCircle__3kxYh{
  background: #eee;
  border-radius: 50%;
 height: 20px;
  width: 20px;
  margin-right: 12px;
  margin-top: 14px;
}
.RISkeletonLoading_skeletonlineCircleCantainer__2q9qt{
  display: flex;
  padding: 12px 0px 8px 0px;
  border-bottom: 1px solid lightgrey;
}
.RISkeletonLoading_skeletonlineMainContainer__2VUbI{
  width: 100%;
  padding-left: 12px;
}
.RISkeletonLoading_fAQContainer__3eN9L{
  display: flex;
  justify-content: space-between;
  padding: 20px 0px 20px 0px;
  border-bottom: 1px solid lightgrey;
}
@media screen and (max-width:425px) {
  .RISkeletonLoading_maincontainer__25Mjz {
    margin: 20px 0px;
  }

  .RISkeletonLoading_container__3b91o {
    margin: 20px 0px;
  }

  .RISkeletonLoading_webLayout__1RaEU {
    display: block;
    margin: 10px 0px;
  }

  .RISkeletonLoading_articleRozgaar_maindiv__3EwZq {
    width: 90%;
  }

  .RISkeletonLoading_skeletonline__2sKQt {
    width: auto;
  }

  .RISkeletonLoading_flex__Ghjgd {
    display: block;
    padding-top: 20px;
    margin-top: -20px;

    width: 100%;
    margin: auto;
  }

  .RISkeletonLoading_skeletonflex__2TXs6 {
    display: flex;
    justify-content: space-between;
    margin: 20px;
  }

}

.MatchedFreelancer_mainContainer__36C4X {
  padding: 15px;
  align-content: center;
  align-items: center;
  margin-top: 20px;
}
.MatchedFreelancer_contentArea__k0502 {
  padding: 10px;
  margin-top: 20px;
  text-align: center;
  font-size: 18px;

}
.MatchedFreelancer_PARBtn_Link__3_gKc {
  text-decoration: none;
}
.MatchedFreelancer_webImage__2Dyd5 {
  display: none;
}
.MatchedFreelancer_mapWidthMobile__3rIPN {
  display: flex;
  margin: auto;
}
.MatchedFreelancer_mapWidthWeb__39MZp {
  display: none;
}

@media screen and (min-width: 1023px) {
  .MatchedFreelancer_mainContainer__36C4X {
    display: flex;
    width: 90%;
    padding: 30px;
    justify-content: space-between;
  }
  .MatchedFreelancer_contentArea__k0502 {
    width: 35%;
    margin-top: 5%;
    justify-content: center;
    display: grid;
  }
  .MatchedFreelancer_mapWidthWeb__39MZp {
    display: inline;
  }
  .MatchedFreelancer_mobileImage__3kR1i {
    display: none;
  }
  .MatchedFreelancer_webImage__2Dyd5 {
    display: inline;
  }
}

.MatchedFreelancer_headingForSEO__3Ft7y{
  font-size: 25px;
  font-weight: 800;
}
@media screen and (min-width: 1366px) {
  .MatchedFreelancer_mainContainer__36C4X {
    margin: auto;
    width: 80%;
  }
}

.ArticleAreaWeb_ArticlesFreelancers_maindiv__sidhn {
  background: #f5f5f5;
  padding-top: 20px;
  padding-bottom: 2%;
}

.ArticleAreaWeb_ArticlesFreelancers_Display_Web__3HH2O {
  margin: auto;
}
.ArticleAreaWeb_ArticlesFreelancers_image__3ljSf {
 
  object-fit: cover;
}

.ArticleAreaWeb_imageAndArticleText_Mob__qnaG1 {
  display: flex;
  margin: 10px;
  margin-top: 20px;
}
.ArticleAreaWeb_ArticlesFreelancers_headingBold_Web__2w2l9 {
  font-weight: bold;
  font-size: 14px;
  color: #000;
}

.ArticleAreaWeb_ArticlesFreelancers_headingBold_Web__2w2l9:hover {
  color: #1878f2;
  cursor: pointer;
}
.ArticleAreaWeb_subhedingArticle_Web__3rVG_ {
  font-size: 12px;
  color: gray;
}
.ArticleAreaWeb_subhedingArticle_Web__3rVG_:hover {
  color: rgb(0, 0, 0);
  cursor: pointer;
}
.ArticleAreaWeb_articlesHeadingSubHeading__HKQiU {
  margin-left: 10px;
}
.ArticleAreaWeb_Anchor_Tag__2usPR {
  text-decoration: none;
}

.ArticleAreaWeb_noImageTag__3G_c8{
  width: 120px;
  height: 60px;
  background-color: rgb(225, 225, 225);
}
@media screen and (min-width: 1024px) {
  .ArticleAreaWeb_noImageTag__3G_c8{
  width: 250px;
  height: 100px;
  
  }
  .ArticleAreaWeb_ArticlesFreelancers_Display_Web__3HH2O {
    width: 60%;
    display: flex;
    margin: auto;

    justify-content: space-around;
    align-items: flex-start;
  }

  .ArticleAreaWeb_ArticlesFreelancers_Container_Web__yroGB {
    width: 100%;
  }

  .ArticleAreaWeb_imageAndArticleText_Web__3LM0A {
    width: 70%;
  }
  .ArticleAreaWeb_ArticlesFreelancers_headingBold_Web__2w2l9 {
    font-weight: bold;
    font-size: 14px;
  }
  .ArticleAreaWeb_subhedingArticle_Web__3rVG_ {
    font-size: 12px;
    color: gray;
  }
  .ArticleAreaWeb_ArticlesFreelancers_Container__SeNGP {
    display: block;
    width: 28%;
    border: none;
  }
  .ArticleAreaWeb_ArticlesFreelancers_image__3ljSf {
   
    object-fit: cover;
  }
}

.CreqDetail_mainContainer__VKZRm{
    margin-bottom: 10%;
}

.CreqDetail_titleSkill__3shgT{
    font-size: 16px;
    font-weight: 600;
}
.CreqDetail_threeDots__2dEgY{
    cursor: pointer;
}
.CreqDetail_reqSection__3e3Ue{
    padding: 20px;
    padding-left: 30px;
    padding-right: 30px;
}
.CreqDetail_dateAndStatusContainer__3DKWl{
    margin-top: 10px;
    align-items: center;
    display: flex;

    
    
}
.CreqDetail_dateArea__F2atK{
    font-size: 12px;
    color:#878787 ;
    margin-left: 10px;
    font-weight: 600

}
.CreqDetail_newTag__12C_Z{
    padding: 2px 10px;
    border-radius: 10px;
    background-color: #3E70B4;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 600;
    color: white;
    font-size: 12px;
}

.CreqDetail_mainReqPoints__2naTG{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10%;

}
.CreqDetail_reqPointsContainer__zRZ8G{

}

.CreqDetail_priceFont__1hQ7z{
    font-size:14px;
    font-weight: 600;
}
.CreqDetail_priceType__fsenJ{
    font-size: 12px;
    color:#878787 ;
    font-weight: 600;
}

.CreqDetail_shortDesc__1LyLm{
    margin-top: 10%;
    font-size: 14px;
}

.CreqDetail_skillTags__Skx50{
    padding: 3px 10px;
    background-color: #E3ECE4;
    font-size: 12px;
    width: -moz-fit-content;
    /* width: fit-content; */
    border-radius: 10px;
    margin: 5px;
    font-weight: 500;
}
.CreqDetail_skillTagContainer__2GntN{
    display: flex;
    margin-top: 5%;

 
}
.CreqDetail_buttonContainer__QgqqV{
    margin-top: 20px;
}
.CreqDetail_greenButton__1Y0oE{
    width: 70%;
    background-color: #4EA656;
    padding: 3px 5px;
    color: white; 
    text-align: center;
    border-radius: 25px;
   font-weight: 500;
    margin: auto;
}
.CreqDetail_borderGreen__2rMpc{
    height: 1px;

    background-color: #4EA656;
    margin-top: 40px;
}


.CreqDetail_moreJobsButton__1zP5m{
    padding: 5px 10px;
    border: 1px solid #4EA656;
    border-radius: 25px;
    text-align: center;
    width: 80%;
    margin: auto;
    font-size: 16px;
    font-weight: 600; 
    
}

.CreqDetail_skilltagsContainer__1duC9 {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5%;
  }
.CreqDetail_moreJobsButton__1zP5m:hover{
    background-color:#4EA656 ;
    color: white;
}

.CfutureOfWork_mainContainer__1ov09{
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #395CA6;
    color: white;
    padding-bottom: 10%;
}

.CfutureOfWork_heading__2RB6q{
    font-size: 30px;
    font-weight: 800;
    margin-top: 10%;
}
.CfutureOfWork_contentDesc__1BwQA{
    margin-top: 20px;
   

}
.CfutureOfWork_futureImage__12gbl{
    margin: auto;
    display: flex;
    margin-top: 10px;
    width: -webkit-fill-available;
    

}

.CfutureOfWork_whiteBorder__25_T3{
    height: 1px;
    background-color: white;
    margin-top: 20px;
    margin-bottom: 20px;

}

.CfutureOfWork_buttonContainer__34ADM{
    margin-top: 20px;
}
.CfutureOfWork_greenButton__1B5hO{
    width: 50%;
    background-color: #ffffff;
    padding: 3px 5px;
    color:  #395CA6; 
    text-align: center;
    border-radius: 25px;
   font-weight: 500;
  
}

.CfutureOfWork_greenButton__1B5hO:hover{
    background-color: aqua;
    color: white;

}
.ChowCanWeHelp_mainContainer__3Syie{
    padding: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10%;
   
}

.ChowCanWeHelp_heading__2WMaJ{
    font-size: 30px;
    font-weight: 600;
  
}

.ChowCanWeHelp_pointAndIcons__2O8em{
display: flex;
margin-top: 10%;
}
.ChowCanWeHelp_icons__15FOM{
    width: 30px;
    height: 30px;
    margin-right: 10px;
    margin-top: 3px;

}

.ChowCanWeHelp_pointHeading__2hms4{
    font-weight: 800;
    font-size: 18px;
}

.ChowCanWeHelp_pointDesc__7JBMH{
    color:#878787 ;
}
.TrustedBy_mainLogoDiv__1AgtH {
  width: 100%;
  margin-top: -10px;
  height: 90px;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-content: center;
  margin: auto;
}

.TrustedBy_logoArea__2ZzSV {
  width: 18%;
}


.TrustedBy_mapdivMobile__hV2VD {
  padding: 5px 0px;
  outline: none;
}
.TrustedBy_mancontainerMobile__Afldp {
  background-color: #282f39;
  width: 100%;
  margin-top: -5px;
}
.TrustedBy_mob_imgMobile__1856n {
 
  margin: auto;
}

.TrustedBy_mainContainer__2DUsM {
  background-color: #282f39;
  margin-top: -10px;
  align-items: center;
}
.TrustedBy_mapdiv__1Phzv {
  height: 50px;
  margin: auto;
  padding-top: 30px;
}

.TrustedBy_mob_img__17x70 {
  width: 60%;
  margin: auto;
  outline: none;
}

@media screen and (min-width: 768px) {
  .TrustedBy_logoArea__2ZzSV {
    width: 100px;
    height: 100px;
  }
  .TrustedBy_logoAreaWeb__-ZOb7{
    width: 100px;
    height: 100px;
  }
  .TrustedBy_mainLogoDiv__1AgtH {
    max-width: 1366px;
    margin-top: -10px;
  }
 
}

.TermsConditions_top_link__1tdAf {
  margin: 28px 0px;
  color: #707070;
}
.TermsConditions_home_link__13smd {
  text-decoration: none;
  color: #707070;
}
.TermsConditions_home_linkTerm__2EjH6{
  color: #707070;
  text-decoration: none;
}
.TermsConditions_home_link__13smd:first-child {
  color: #1778f2;
}
.TermsConditions_terms_container__lg_ff {
  margin: 20px;
}
.TermsConditions_terms_para__nvXYK {
  margin: 20px 0px;
  text-align: justify;
}
.TermsConditions_terms_heading__1_wLR {
  color: #707070;
  text-decoration: none;
  color: #1778f2;
}
.TermsConditions_ordered_list__1Yr_m {
  margin: 30px 0px;
  text-align: justify;
}
.TermsConditions_ordered_li__328w1 {
  margin: 20px 0px;
}
.TermsConditions_ordered_li__328w1::marker {
  margin-left: 10px;
}
.TermsConditions_subHeading_overview__2KNRA {
  margin-top: 5px;
  font-weight: 400;
}
.TermsConditions_overView_heading__34yW1 {
  margin: 0;
  margin-top: 30px;
  font-weight: 600;
}
@media screen and (min-width: 1023px) {
  .TermsConditions_terms_container__lg_ff {
    margin: 40px 13vw;
  }
  .TermsConditions_terms_heading__1_wLR {
    margin-bottom: 35px;
  }
}

.PrivacyPolicy_top_link__1J44_ {
  margin: 28px 0px;
  color: #707070;
}
.PrivacyPolicy_home_link__IxLGq {
  text-decoration: none;
  color: #707070;
}
.PrivacyPolicy_home_link__IxLGq:first-child{
  color: #1778f2;
}
.PrivacyPolicy_privacy_container__lHgqf {
  margin: 20px;
}
.PrivacyPolicy_privacy_para__OJ0rI {
  margin: 20px 0px 30px;
}
.PrivacyPolicy_privacy_heading__3H_Jd {
  color: #1778f2;
}
.PrivacyPolicy_ordered_li__SXrQy {
  margin: 20px 0px;
}
.PrivacyPolicy_boldText__1coAb {
  font-weight: 700;
}
@media screen and (min-width: 1023px) {
  .PrivacyPolicy_privacy_container__lHgqf {
    margin: 40px 13vw;
  }
  .PrivacyPolicy_privacy_heading__3H_Jd {
    margin-bottom: 35px;
  }
}

.LoginWithOTP_mainContainer__wR0jQ {
  padding: 0px 30px 40px;
  background-color: white;
}
.LoginWithOTP_loginWith_Otp__1bUn8 {
  color: #1778f2;
  text-align: right;
  text-decoration: none;
  cursor: pointer;
}
.LoginWithOTP_loginWith_Otp__1bUn8 {
  color: #1778f2;
  text-align: right;
  text-decoration: none;
  cursor: pointer;
}
.LoginWithOTP_Maincontainer_border__-Y73T {
  background-color: white;
}
.LoginWithOTP_inputFields_check__16uJt {
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
}
.LoginWithOTP_forgot_password__3LcOF {
  color: #858585;
  text-align: center;
  margin-top: 15px;
}
.LoginWithOTP_resend_code__2PlQV {
  text-align: center;
  margin-top: 10px;
  color: #1778f2;
}
.LoginWithOTP_resend_code_hover__k24Xh {
  text-align: center;
  margin-top: 10px;
  color: #1778f2;
  cursor: pointer;
}
.LoginWithOTP_resend_code_hover__k24Xh:hover {
  color: #858585;
}
.LoginWithOTP_checkbox__3eK87 {
  padding: 2px;
}
.LoginWithOTP_para_text__VzQby {
  margin-top: 40px;
}
.LoginWithOTP_error_message__T_SUL {
  color: rgb(250, 0, 0);
  display: flex;
  align-items: center;
}
.LoginWithOTP_ActionBtn__1GE1b {
  display: flex;
  justify-content: center;
}
.LoginWithOTP_back_button__3EDTg {
  background: #fff;
  border: none;
  margin-top: 15px;
  cursor: pointer;
}
.LoginWithOTP_MobileNumber__2egmJ {
  padding-bottom: 30px;
}
.LoginWithOTP_LoadingBtn__4TU3U {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 10px 10px;
  width: 55%;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
}
.LoginWithOTP_actionButtonContainer__3ZjX8 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (min-width: 1024px) {
  .LoginWithOTP_mainContainer__wR0jQ {
    padding: 0px 30px 40px;
  }
  .LoginWithOTP_Pagewrapper__Rs5pV {
    background-color: #f5f5f5;
    padding-top: 20px;
    padding-bottom: 50px;
    height: 100vh;
  }

  .LoginWithOTP_Maincontainer_border__-Y73T {
    width: 40%;
    margin: auto;
    border-width: 1px;
    border-color: rgb(223, 223, 223);
    border-style: solid;
    margin-top: 20px;
  }
}

.LeftMenu_rightMenuMain__24Q61 {
  display: flex;
  border-width: 1px;
  border-style: solid;
  background-color: rgb(255, 255, 255);

  border-color: #e2e2e2;
  border-top-width: 0px;
  overflow-x: scroll;
  overflow-y: hidden;
}
.LeftMenu_rightMenuMain__24Q61::-webkit-scrollbar {
  display: none;
}
.LeftMenu_leftMenu__3XTo6 {
  padding: 10px;

  margin-left: 10px;
  text-align: center;
  width: -webkit-max-content;
  width: max-content;
}
.LeftMenu_leftMenuSelected__AmfLK {
  padding: 10px;

  margin-left: 10px;
  text-align: center;
  width: -webkit-max-content;
  width: max-content;
  color: #1678f2;
  cursor: pointer;
  border-radius: 3px;
  border-bottom: 4px;
  border-style: solid;
  border-left: none;
  border-top: none;
  border-right: none;
}
.LeftMenu_leftMenu__3XTo6:hover {
  cursor: pointer;

}
.LeftMenu_leftMenuHeading__3Zb8h {
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;

  display: flex;
  align-items: center;
}
.LeftMenu_menuText__13HuN {
  margin-left: 0px;
  width: inherit;
}
.LeftMenu_horiTag__1tl_o {
  width: 90%;
  text-align: center;
  border-width: 1px;
  border-color: #f1f1f1;
  border-style: solid;
}
.LeftMenu_linkdesign__2MSve {
  text-decoration: none;
  color: #171717;
}
@media (min-width: 768px) {
  .LeftMenu_rightMenuMain__24Q61 {
    width: 200px;
    height: 700px;
    display: inherit;
    overflow: inherit;
    margin: 10px;
    padding-top: 20px;
  }
  .LeftMenu_leftMenu__3XTo6 {
    display: flex;
    align-items: center;
    text-align: inherit;
    width: inherit;
  }
  .LeftMenu_menuText__13HuN {
    margin-left: 10px;
  }
  .LeftMenu_leftMenuSelected__AmfLK {
    color: #1678f2;
    cursor: pointer;
    border-radius: 3px;
    border-right: 4px;
    border-style: solid;
    border-left: none;
    border-top: none;
    border-bottom: none;
  }

  .LeftMenu_leftMenu__3XTo6:hover {
    color: #1678f2;
    cursor: pointer;
 
  }
}

.NewClientDashboard_pageLayout__3-8L4 {
  background-color: #f5f6fb;
  display: flex;
}
.NewClientDashboard_widgetsContainer__3W0nV {
  width: 100%;
  padding-bottom: 5%;
}

.NewClientDashboardMobile_pageLayout__2jy7D {
  background-color: #f5f6fb;
  padding-top: 10px;
}
.NewClientDashboardMobile_widgetsContainer__LTuZU {
  width: 100%;
}

.NoRequirment_mainContainer__U7Gr0 {
  margin-top: 10px;

  margin-left: 10px;
  background-color: white;
  padding: 30px;
  display: flex;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.NoRequirment_contentContainer__dOTbw {
  text-align: center;
  width: 100%;
  margin: auto;
}
.NoRequirment_mainHeaing__2oavZ {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}
.NoRequirment_mainContent__1nHCo {
  color: grey;
  width: 80%;
  margin: auto;
}
.NoRequirment_bottomText__2_Qfe {
  color: grey;

  justify-content: center;
}

.NoRequirment_imageArea__28_mq {
  text-align: right;
  margin-right: 30px;
  margin: auto;
}
.NoRequirment_blueText__2xwrP {
  color: #1678f2;
  margin-left: 5px;
  text-decoration: none;
}
.NoRequirment_blueText__2xwrP {
  cursor: pointer;
}

@media (min-width: 768px) {
  .NoRequirment_bottomText__2_Qfe {
    display: flex;
  }
  .NoRequirment_mainContainer__U7Gr0 {
    margin-left: 0px;
    width: 70%;
  }
}

.RecRequirmentCard_mainCardContainer__3UK5r {
  background-color: white;

  padding: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(219, 219, 219);
  margin: 10px;
  margin-top: 20px;

  border-radius: 5px;
}
.RecRequirmentCard_imagecontainer__3IaSO {
  width: 90px;
  background-color: rgb(219, 219, 219);
}
.RecRequirmentCard_workType__mWiga {
  color: indigo;
  font-weight: bolder;

  margin-left: 10px;
}

.RecRequirmentCard_twoSectionDivider__13C4a {
  display: flex;
  justify-content: space-between;
}

.RecRequirmentCard_skillContainer__29ssP {
  font-size: 25px;
  font-weight: bold;
  margin-top: 20px;
}

.RecRequirmentCard_titleArea__3H3VU {
  margin-top: 10px;
}

.RecRequirmentCard_postType__2RcCi {
  margin-left: 5px;
}
.RecRequirmentCard_skillTags__PCbpv {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  color: #707070;
  background-color: #f5f5f5;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 10px;
  margin-top: 10px;
  font-size: 13px;
  font-weight: 600;
}
.RecRequirmentCard_skilltagsContainer__2bTiy {
  display: flex;
  flex-wrap: wrap;
}

.RecRequirmentCard_statusAndMenu__-lbs1 {
  color: #43ca62;
  font-size: 20px;
}
.RecRequirmentCard_menuWithStatus__O928z {
  display: flex;
  align-items: center;
}
.RecRequirmentCard_dropDownStatus__z3wUW {
  align-self: flex-end;
}
.RecRequirmentCard_rightSection__13TOX {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 2%;
}
.RecRequirmentCard_icondots__3mcTl {
  margin-top: 6px;
}
.RecRequirmentCard_icondots__3mcTl:hover {
  cursor: pointer;
}
.RecRequirmentCard_workPolicy__3CQ3k {
  padding: 3px 7px;

  background-color: #3db2be;
  color: white;
  border-radius: 3px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
}
.RecRequirmentCard_iconContainer__1iQW8 {
  display: flex;
  margin-bottom: 5px;
}

.RecRequirmentCard_workingType__3W2Vs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.RecRequirmentCard_mobileMenuOption__2K0aW {
  display: flex;
}
.RecRequirmentCard_statusAnddate__2JoQh {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;

  color: grey;
}
.RecRequirmentCard_highlightText__2rTrh {
  font-weight: bold;
  margin-top: 10px;
}

.RecRequirmentCard_Commission__3isz8 {
  color: #fea11b;
}

.RecRequirmentCard_Contract__38vhd {
  color: #486a31;
}
.RecRequirmentCard_One-Time__3WjOF {
  color: #365aa9;
}
.RecRequirmentCard_Monthly__1ptuU {
  color: #125552;
}
.RecRequirmentCard_requirementCardButton__1HNox {
  padding: 10px;
  border-width: 1px;
  border-color: #365aa9;
  border-style: solid;
  margin-top: 10px;
  color: #365aa9;
  font-size: 14px;
}
.RecRequirmentCard_requirementCardButton__1HNox:hover {
  cursor: pointer;
}
.RecRequirmentCard_buttonWithIconContainer__24Gu1 {
  display: flex;
  justify-content: end;
}
.RecRequirmentCard_colorBlue__3hWK4 {
  color: #365aa9;
  font-weight: normal;
}
.RecRequirmentCard_AddonName__3rS1m{
  font-size: 15px;
  color: #7c7c7c;

}
.RecRequirmentCard_tagFeatured__1GOwG {
  margin-right: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #7c7c7c;
  border-radius: 20px;
  padding: 3px 8px;

}
.RecRequirmentCard_featuredListing__HI03H{
  display: flex;
  margin: 18px 0;

}


.RecRequirmentCard_addonNameIcon__6B0ZU {
  margin-right: 5px;
 
}
.RecRequirmentCard_addonContainer__Dps7Y {
  display: flex;
}
@media (min-width: 768px) {
  .RecRequirmentCard_mainContainer__3cg67 {
    margin: 0px;
  }
  .RecRequirmentCard_mainCardContainer__3UK5r {
    margin-right: 10px;
  }
  .RecRequirmentCard_tagFeatured__1GOwG {
    margin-right: 0px;
    margin-left: 10px;
  }
  .RecRequirmentCard_featuredListing__HI03H{
    display: flex;
    justify-content: flex-end;
  }
  .RecRequirmentCard_leftSectionWidth__3eov_ {
    width: 50%;
    margin: 2%;
  }

  .RecRequirmentCard_highlightText__2rTrh {
    margin-top: 0;
    justify-content: end;

    display: flex;
  }
  .RecRequirmentCard_workingType__3W2Vs {
    justify-content: inherit;
  }
}

.RIDropDownMenu_mainContainer__3Inqn {
  background-color: rgb(255, 255, 255);
  border-width: 1px;
  border-color: rgb(224, 224, 224);
  border-style: solid;
  position: absolute;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transition: all 0.5s ease-out;
  right: 10%;
}
.RIDropDownMenu_menuOptions__2NMH_ {
  padding: 12px 10px;
}
.RIDropDownMenu_TotalAmountText__1aIDS {
  text-align: right;
  padding: 5px 20px;
}
.RIDropDownMenu_menuOptions__2NMH_:hover {
  cursor: pointer;
  color: gray;
}

.RIDropDownMenu_buttonAlignment__1JbMv {
  text-align: center;
  margin: 0px;
}
.RIDropDownMenu_closePostHeading__3lx_g {
  font-size: 24px;
  margin-top: -10px;
}
.RIDropDownMenu_closePostButtons__3kA-y {
  width: 220px;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}
.RIDropDownMenu_btnContainer__17Gqq {
  padding: 10px 0px 3px 0px;
}
.RIDropDownMenu_closePostHeading__3lx_g {
  font-size: 20px;
}
.RIDropDownMenu_closePostButtonsConatiner__AuPfv {
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
  .RIDropDownMenu_closePostButtons__3kA-y {
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
  }
  .RIDropDownMenu_btnContainer__17Gqq {
    padding: 20px 0px 10px 0px;
  }
  .RIDropDownMenu_closePostButtonsConatiner__AuPfv {
    display: flex;
    justify-content: center;
  }
  .RIDropDownMenu_mainContainer__3Inqn {
    right: 25%;
  }
}

.SharePost_ShareIcon__13A_m {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.SharePost_shareIconContainer__1u9CC {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 25px;
}
.SharePost_iconColor__1cZys {
  color: white;
  height: 27px;
  width: 27px;
}
.SharePost_iconHref__24ln1{
  margin-top: 10px;
  padding: 0 5px;
}
.SharePost_iconHrefSmall__1wiKC{
  margin-top: 8px;
  padding: 0 5px;
}
.SharePost_iconBg__Zx8RV {
  border-radius: 50%;
  height: 60px;
  width: 60px;
  display: flex;

  margin: 14px;
  align-items: center;
  justify-content: center;
}
.SharePost_Email__1hxv0 {
  background-color: #7f7f7f;
}

.SharePost_LinkedIn__3u0fo {
  background-color: #007fb1;
}
.SharePost_Whatsapp__23Avo {
  background-color: #25d366;
}
.SharePost_Facebook__2j4BF {
  background-color: #007bff;
}
.SharePost_Twitter__18BeB {
  background-color: #00aced;
}
.SharePost_textInputLabel__3RTfl {
  padding: 10px 10px;
  outline: none;
  border: 1px solid #ece9e9;
  margin: 10px 0px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
}
.SharePost_copyIcon__130W3 {
  border: none;
  outline: none;
  cursor: pointer;
}
.SharePost_textInput__2wLFP {
  border: none;
  width: 100%;
  outline: none;
}
.SharePost_error_message__37F1j {
  color: #007bff;
  position: absolute;
  padding-top: 50px;
  width: 80%;
  text-align: right;
}

.WorkRequirementsDashboard_mainContainer__1PMql {
  background-color: white;
  margin-top: 10px;
  margin-right: 10px;
  padding: 10px;
  height: 100%;
}
.WorkRequirementsDashboard_headingMain__1YqKZ {
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
  margin-top: 20px;
}

.WorkRequirementsDashboard_subHeading__1oEn5 {
  color: grey;
  margin-left: 10px;
}

.WorkRequirementsDashboard_cardArea__3OIPf {
  width: 70%;
}


.HireType_hireTypeMainDiv__73den{
    margin-top: 60px;
}
.HireType_mapDiv__1i-rs{
    display: flex;
    width: 100%;
    margin-bottom: 30px;
    
}
.HireType_detailDiv__2zPIy{
    width: 55%;
    margin-left: 10px;
    color: #707070;
}
.HireType_border__3k0Om{
    height: 50px;
    margin-right:10px ;
    margin-top: 5px;
}
.HireType_typeDiv__11grb{
    font-weight: 800;
    width: 30%;
    text-align: right;
    padding-right: 20px;
   
}
.HireTypeWeb_mainDiv__k64J1 {
  margin-top: 50px;
  margin-left: 70px;
}

.HireTypeWeb_contentDiv__2oFA5 {
  display: flex;
  margin-bottom: 40px;
}
.HireTypeWeb_secondTypeDiv__2HK5r {
  width: 40%;
  text-align: left;
  font-weight: 800;
  font-size: 40px;
  margin-left: 43px;
}
.HireTypeWeb_typeDiv__3bFIK {
  width: 40%;
  text-align: right;
  font-weight: 800;
  font-size: 40px;
  margin-right: 49px;
}
.HireTypeWeb_secondParagraph__3TTp7 {
  width: 40%;
  padding: 10px;
  text-align: right;
  margin-right: 30px;
}
.HireTypeWeb_paragraph__2lE5p {
  width: 40%;
  padding: 10px;
  margin-left: 30px;
}
.HireTypeWeb_borderDivFirst__2wdY8 {
  border: 2px solid #375ba7;
  height: 70px;
  margin-top: 20px;
}
.HireTypeWeb_borderDivSecond__1TpJ8 {
  border: 2px solid #1c5652;
  height: 70px;
  margin-top: 20px;
}
.HireTypeWeb_borderDivThird__34cEm {
  border: 2px solid #4b6a35;
  height: 70px;
  margin-top: 20px;
}

.HireTypeWeb_borderDivFourth__pfnYU {
  border: 2px solid #1c5652;
  height: 70px;
  margin-top: 20px;
}

.HowToHireSec_mainDiv__2xUbY {
  margin-top: 40px;
}
.HowToHireSec_firstContentDiv__3AzTH {
  display: flex;
  margin-bottom: 30px;
}
.HowToHireSec_firstSerial__2o9ek {
  color: #375ba7;
  text-align: right;
  font-size: 52px;
  font-weight: 700;
  margin-top: 6px;
  width: 18%;
  margin-right: 32px;
}
.HowToHireSec_secondSerial__2h5nE {
  color: #1c5652;
  font-size: 48px;
  font-weight: 700;
  width: 18%;
  margin: auto;
  margin-top: 6px;
  text-align: left;
}
.HowToHireSec_fourthSerial__NG5aF {
  color: #fa9e27;
  font-size: 48px;
  font-weight: 700;
  width: 18%;
  margin: auto;
  margin-top: 6px;
  text-align: left;
}
.HowToHireSec_thirdSerial__3KH5c {
  color: #4b6a35;
  text-align: right;
  font-size: 52px;
  font-weight: 700;
  margin-top: 6px;
  width: 18%;
  margin-right: 32px;
}
.HowToHireSec_contentInnerDiv__6F9ge {
  margin-top: 16px;
  width: 75%;
}
.HowToHireSec_contentHeading__rhsU7 {
  font-weight: 800;
  font-size: 21px;
  margin-bottom: 10px;
}
.HowToHireSec_contentHeadingSecond__ySIbW {
  font-weight: 800;
  font-size: 21px;

  margin-bottom: 10px;
  text-align: right;
}
.HowToHireSec_firstParagraph__26g15 {
  text-align: left;
  color: #5d5d5d;
  margin-right: 7px;
}
.HowToHireSec_secondParagraph__12ntf {
  text-align: right;
  color: #5d5d5d;
  margin-left: 7px;
}
@media screen and (min-width: 768px) {
  .HowToHireSec_firstSerial__2o9ek {
    color: #375ba7;
    text-align: right;
    font-size: 48px;
    font-weight: 700;
    margin-top: 8px;
    width: 40%;
    margin-right: 35px;
  }
  .HowToHireSec_secondSerial__2h5nE {
    color: #1c5652;
    font-size: 48px;
    font-weight: 700;
    width: 40%;
    margin-left: 35px;
    margin-top: 8px;
  }
  .HowToHireSec_fourthSerial__NG5aF {
    color: #fa9e27;
    font-size: 48px;
    font-weight: 700;
    width: 40%;
    margin-left: 35px;
    margin-top: 8px;
  }
  .HowToHireSec_thirdSerial__3KH5c {
    color: #4b6a35;
    text-align: right;
    font-size: 48px;
    font-weight: 700;
    margin-top: 8px;
    width: 40%;
    margin-right: 35px;
  }
}

.HowToHire_contentDiv__1OqAV {
  padding: 20px;
}

.HowToHire_readMore__2hBe7 {
  font-size: 11px;
  background: #f1f7ff;
  width: 20%;
  padding: 2px;
  text-align: center;
  color: #1778f2;
  border-radius: 10px;
}

.HowToHire_heading__2b1pv {
  font-size: 25px;
  font-weight: 700;
  line-height: 1.2;
  margin-top: 10px;
  margin-bottom: 10px;
}

.HowToHire_subHeading__8R5Kh {
  font-weight: 600;
  font-size: 13px;
}

.HowToHire_faqContent__1xLMW {
  margin-bottom: 10px;
  font-size: 14px;
}

.HowToHire_faqContentDiv__1gCl- {
  margin-top: 30px;
}

.HowToHire_faqSubHeading__2rHo4 {
  font-weight: 800;
  margin-bottom: 8px;
  font-size: 14px;
}

.HowToHire_bannerMob__3oFg3 {

  margin-top: 10px;
}

.HowToHire_faqHeading__3Vu8A {
  font-weight: 800;
  font-size: 48px;
  letter-spacing: 1px;
  text-align: center;
}

.HowToHire_workTypeHeading__1eaSQ {
  margin-top: 60px;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
}

.HowToHire_inputDiv__3_f3E {
  margin-top: 20px;
  display: flex;
  width: 100%;
  padding: 5px;
  border: 1px solid #dfdfdf;
  cursor: pointer;
}

.HowToHire_icon__1TDbL {
  font-size: 25px;
  color: #dfdfdf;
}

.HowToHire_inputArea__2sTF4 {
  width: 100%;
  border: none;
  outline: none;
}

.HowToHire_workTypeSubHeading__1wabG {
  text-align: center;
  font-weight: 600;
}

.HowToHire_PARBtn_Link__2Lt7L {
  text-decoration: none;
}

.HowToHire_faq_ActionButton__1sF9W {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .HowToHire_faqMainDiv__TDKVF {
    margin-top: 30px;
    justify-content: center;
    padding-left: 10px;
  }

  .HowToHire_faqContentDiv__1gCl- {
    width: 30%;
  }

  /* .readMore {
    font-size: 10px;
    background: #f1f7ff;
    width: 30%;
    padding: 1px;
    color: #1778f2;
    border-radius: 10px;
  } */

  .HowToHire_inputDiv__3_f3E {
    margin: auto;
    margin-top: 20px;
    display: flex;
    width: 90%;
    padding: 5px;
    border: 1px solid #dfdfdf;
  }

  .HowToHire_mainDiv__3-vbT {
    padding: 60px;
  }

  .HowToHire_imageDiv__2bokH {
    display: flex;
  }

  .HowToHire_hireImage__2Fac4 {
    margin-left: 35px;
  }

  .HowToHire_headingDiv__1Z1KD {
    width: 50%;
    margin-top: 100px;
  }

  .HowToHire_helpYouHireDiv__yK_YX {
    margin-top: 70px;
  }

  .HowToHire_searchInput__CQren {
    width: 100%;
    outline: none;
    border: none;
    padding-top: 4px;
  }

  .HowToHire_workTypeHeading__1eaSQ {
    margin-top: 80px;
    text-align: center;
    font-size: 40px;
    font-weight: 800;
  }

  .HowToHire_workTypeSubHeading__1wabG {
    text-align: center;
    font-weight: 600;
  }

  .HowToHire_PARBtn_Link__2Lt7L {
    text-decoration: none;
  }

  .HowToHire_inputDiv__3_f3E {
    padding: 6px;
    display: flex;
    justify-content: center;
    width: 50%;
    border-radius: 5px;
    border: 1px solid #999999;
    margin: auto;
    align-items: center;
    cursor: pointer;
  }

  .HowToHire_searchIcon__1UJiZ {
    margin-left: 50px;
  }

  .HowToHire_mainHeading__4TM2t {
    margin-top: 25px;
    font-weight: 800;
    font-size: 40px;
    line-height: 1.2;
  }

  .HowToHire_subHeading__8R5Kh {
    font-size: 18px;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 25px;
  }

  .HowToHire_faqHeading__3Vu8A {
    font-weight: 800;
    font-size: 50px;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 45px;
  }

  .HowToHire_mapDiv__3oDVl {
    margin-top: 20px;
    justify-content: center;
    padding: 30px;
  }

  .HowToHire_faqInnerDiv__3Anod {
    width: 30%;
    padding-right: 45px;
  }

  .HowToHire_faqSubHeading__2rHo4 {
    font-weight: 800;
    font-size: 18px;
  }

  .HowToHire_faqText__2KFwg {
    font-size: 15px;
  }

  .HowToHire_readMore__2hBe7 {
    width: 80px;
    margin-top: 10px;
    font-size: 12px;
    padding: 4px;
    text-align: center;
    border-radius: 10px;
    color: #1778f2;
    background-color: #f1f7ff;
    cursor: pointer;
  }

  .HowToHire_hoetohireSec__mlx3y {
    width: 80%;
    margin: auto;
  }

  .HowToHire_faq_ActionButton__1sF9W {
    display: flex;
    justify-content: center;
  }
}
.Transaction_mainContainer__ObeqV {
  background-color: white;
  padding: 10px;
  margin-top: 10px;
  margin-right: 10px;
}

.Transaction_headingMain__19ir9 {
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  background-color: white;

  margin-top: 10px;
  margin-right: 10px;
}

.Transaction_amountsSection__3pFv4 {
  display: flex;
  width: 40%;
  justify-content: space-between;
  margin-left: 20px;
  padding: 20px;
}

.Transaction_amount__3uIZr {
  font-size: 25px;
  font-weight: bold;
}
.Transaction_subHeading__2X0TS {
  font-size: 16px;
}
.Transaction_gridView__2qxLv {
  display: grid;
  text-align: center;
}

.Transaction_menuContainer__2IdL- {
  display: flex;
  margin-left: 20px;
  padding: 20px;
  padding-bottom: 5px;
  width: 30%;
  justify-content: space-between;
}

.Transaction_menuHeading__FaJ-E {
  font-size: 20px;
  font-weight: bold;
}
.Transaction_menuHeading__FaJ-E:hover {
  cursor: pointer;
  color: #1678f2;
}

.Transaction_menuHeadingSelected__2_2jL {
  font-size: 20px;
  font-weight: bold;
  border-bottom-width: 2px;
  border-bottom-color: #1678f2;
  border-bottom-style: ridge;
}
.Transaction_menuHeadingSelected__2_2jL:hover {
  cursor: pointer;
}
.Transaction_mainTable__1r2ID {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}
.Transaction_headingTable__3J_Zf {
  text-align: center;
  width: -webkit-max-content;
  width: max-content;
  padding: 0px 20px;
}
.Transaction_headingTableText__1qVVy{
  text-align: center;
  width: -webkit-max-content;
  width: max-content;
  padding: 8px 10px;
}
.Transaction_tableMobileAlign__2eKUM {
  width: -webkit-max-content;
  width: max-content;
}
.Transaction_selectionBox__EWrwZ {
  border-width: 1px;
  border-color: #1678f2;
  border-style: ridge;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px;
  display: flex;
}

.Transaction_arrowIcon__3oevW {
  margin-top: 4px;
  margin-left: 2px;
  font-size: 12px;
}

.Transaction_mobileTableView__2voX3 {
  overflow: scroll;
}
.Transaction_blank_Notification_div__31MtO {
  text-align: center;
  padding-top: 20vh;
  height: 430px;
   background-color: white;
  margin-top: 10px;
  margin-right: 10px;
}

.Transaction_blank_Notification_text__rCZwv {
  font-size: 20px;
  margin: auto;
  padding-top: 20px;
  font-weight: 700;
}
.Transaction_loaderConatiner__5mA_9{
  height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MyProposals_mainContainer__Am8U_{
    padding: 10px;
    background-color: white;
    
    margin-bottom: 10px;
   
    margin-right: 10px;
    margin-left: 10px;
    
}

.MyProposals_proposalBox__1Qf7C{
padding: 10px;
}

.MyProposals_updateDate__3uWMe{
    font-size: 12px;
    color: gray;
   
}

.MyProposals_rejectedColor__3gSuG{
  
    color: #D0342C;
}

.MyProposals_accptedColor__2sywU{
    color: #228B22;
}

.MyProposals_mainHeading__3e9Vc{
    font-weight: 800;
    padding: 10px;
    font-size: 25px;
}

.MyProposals_noProposalHeading__2vNai{
    padding: 10px;
}

.MyProposals_proposalAmount__1o95w{
   
    font-weight: 800;

}
.MyProposals_chatIcon__1zQXE{
    display: flex;
    align-items: center;
    margin-top: 5px;
    justify-content: end;
}
.MyProposals_chattag__3ampL{
    margin-left: 5px;
}

@media (min-width: 768px) {
    .MyProposals_mainContainer__Am8U_ {
          
    margin-right: 0px;
    margin-left: 0px;
        border: 1px solid #c8c8c8;
        padding: 20px;
        max-height: 500px;
        overflow: scroll;
        margin-top: 10px;
    }
    .MyProposals_proposalBox__1Qf7C{
        justify-content: space-between;
    
  
    }
    
  }
.ProfileHealth_mainContainer__1HXTy {
  background-color: white;
  padding: 15px;
  margin-top: 10px;
  margin-right: 10px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 10px;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.ProfileHealth_nameAndDetailArea__tesZS {
  background: linear-gradient(45deg, #134e5e, #71b280);
  background-color: rgb(61, 119, 80);
  padding: 10px;
  color: white;
}
.ProfileHealth_infoArea__2I7_7 {
  padding: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(133 177 160);
  font-weight: bold;
  font-size: 14px;
  font-weight: 14px;
}
.ProfileHealth_infoAreaIcon__1uZke {
  margin-right: 10px;
}
.ProfileHealth_nameWithInitials__2glbn {
  font-size: 20px;
  font-weight: bold;
}

.ProfileHealth_memberShipContent__28N7R {
  text-align: right;
  font-weight: bold;
}
.ProfileHealth_fullName__3v7n- {
  font-size: 20px;
}

.ProfileHealth_headingContainer__1FDIs {
  padding: 10px;
  margin-top: 10px;
}
.ProfileHealth_accontStatusHeading__27s9T {
  font-weight: bold;
  font-weight: 14px;
}
.ProfileHealth_hireAssistant__lSIcp {
  display: grid;
}

.ProfileHealth_progressbar__3RPKM {
  width: 80%;
  margin-left: 10px;
  border-radius: 5px;

  background-color: #dcf0fa;
}

.ProfileHealth_profileTask__2VdqT {
  padding: 10px;
  background-color: rgb(243, 243, 243);
  color: #1678f2;
  font-weight: bold;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  margin-bottom: 20px;
}

.ProfileHealth_accountBalance__2NN5I {
  font-weight: bold;
  text-align: center;
}
.ProfileHealth_accontStatussub__2gZnC {
  font-size: 14px;
  color: gray;
}

@media (min-width: 768px) {
  .ProfileHealth_mainContainer__1HXTy {
    margin-left: 0px;
  }
}

.RozgaarIsProtected_mainContainer__1vkqk {
  padding: 15px;
  background-color: white;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
 
  height: -webkit-fit-content;
 
  height: -moz-fit-content;
 
  height: fit-content;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.RozgaarIsProtected_mainHeading__3dG7p {
  font-weight: 800;
  padding: 10px;
  font-size: 25px;
}
.RozgaarIsProtected_contentContainer__1-LvQ {
  margin-top: 15px;
  display: flex;
  align-items: center;
}
.RozgaarIsProtected_content__3OjV- {
  display: flex;
  padding-top: 10px;
  align-items: center;
}
.RozgaarIsProtected_buttonText__1UmRe {
  color: #1678f2;
  font-weight: bold;
  margin-top: 15px;
  text-align: right;
  font-size: 12px;
}
.RozgaarIsProtected_buttonText__1UmRe:hover {
  color: rgb(179, 179, 179);
  cursor: pointer;
}
.RozgaarIsProtected_RozgaarIsProtected_image__4liAD {
  font-size: 20px;
}
.RozgaarIsProtected_RozgaarIsProtected_text__24E0K {
  font-weight: 600;
 
  color: #000;
  margin-left: 10px;
}

@media (min-width: 1024px) {
  .RozgaarIsProtected_mainContainer__1vkqk {
    margin-left: 0px;
  }
}

.SuggestiveSkills_mainContainer__3zLQz {
  padding: 10px;
  background-color: white;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; 
  margin-right: 10px;
  margin-left: 10px;
  padding-bottom: 15px;
  margin-top: 10px;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.SuggestiveSkills_SkillTab__Ld6O3 {
  border-width: 1px;
  border-radius: 5px;
  border-color: rgb(223, 223, 223);
  padding: 5px 10px;
  border-style: solid;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 5px;
  font-size: 14px;
}
.SuggestiveSkills_tabContainer__2GE2J {
  display: flex;
  flex-wrap: wrap;
}
.SuggestiveSkills_tabContainerText__1a3mh{
  margin-left: 10px;
  color: grey;
}
.SuggestiveSkills_PARLink__wd3iu{
  cursor: pointer;
  color:  #1678f2;
  text-decoration: none;
}
.SuggestiveSkills_SkillTab__Ld6O3:hover {
  cursor: pointer;
  background-color: rgb(0, 0, 0);
  color: white;
}

.SuggestiveSkills_mainHeading__1qquh {
  font-weight: 800;
  padding: 10px;
  font-size: 25px;
}
@media (min-width: 768px) {
  .SuggestiveSkills_mainContainer__3zLQz {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.WorkPlace_welcomeMessage__2q4Ii {
  background-color: white;
  margin-right: 10px;
  margin-top: 10px;
  padding: 20px;
  display: grid;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.WorkPlace_firstWelcome__3o4q0 {
  font-size: 20px;

  display: flex;
}

.WorkPlace_snippetsCollection__2lWBj {
  display: flex;
}
.WorkPlace_welcomeName__Z0TUw {
  margin-left: 5px;
  font-weight: bold;
}
.WorkPlace_bannerContainer__1kuxw {
  margin-left: 10px;
  margin-right: 10px;
}
.WorkPlace_banner__3pNzS {
 
  margin-top: 18px;
}
.WorkPlace_noreqAndProfile__1DVwI {
  display: flex;
  justify-content: space-between;
}

.WorkPlace_reqAndUpgradeOption__-SDL2 {
  display: flex;
}
.WorkPlace_RequirementCard_Package__3YWAj {

  background-color: white;
  padding: 10px;
  margin-top: 10px;
}

.WorkPlace_mainHeading__2_FYB {
  font-weight: 800;
  padding: 10px;
  font-size: 25px;
}
.WorkPlace_pageDivider__1UwtF{
  display: flex;
}
.WorkPlace_leftArea__1mciP{

  width: 65%;
  margin-right: 10px;
  }

.WorkPlace_rightArea__2LOT6{
  width: 35%;
  margin-right: 10px;
}
.WorkPlace_loaderConatiner__mv-I5{
  
 background-color: #fff;
 width: 45%;
 display: flex;
 align-items: center;
 justify-content: center;
}
@media (min-width: 768px) {
  .WorkPlace_welcomeMessage__2q4Ii {
    display: flex;
  }
  .WorkPlace_bannerContainer__1kuxw {
    margin-left: 0px;
  }

  .WorkPlace_reqAndUpgradeOption__-SDL2 {
    justify-content: space-between;
  }
}

.FrequentlyAskedQuestions_faq_head__2RbpZ {
  background: #fff;
  padding: 30px;
}
.FrequentlyAskedQuestions_faq_padding__ZQ0G6 {
  padding: 40px 0;
}
.FrequentlyAskedQuestions_faq_firstheading__1_5AL {
  font-size: 30px;
  font-family: "Poppins";
}
.FrequentlyAskedQuestions_faq_secondheading__2U-HW {
  color: #5d5d5d;
  font-size: 16px;
  padding-top: 15px;
  font-family: "Manrope";
}
.FrequentlyAskedQuestions_faq_ActionButton__NBarj {
  display: flex;
  justify-content: center;
}
.FrequentlyAskedQuestions_faq_SkeletonLoading__haZDa{
  padding-top: 40px;
}
@media screen and (min-width: 1024px) {
  .FrequentlyAskedQuestions_faq_question_mainhead__2UfdP {
    background-color: #f5f5f5;
    padding: 40px 100px;
  }
  .FrequentlyAskedQuestions_faq_secondheading__2U-HW {
    color: #5d5d5d;
    font-size: 20px;
    font-family: "Manrope";
  }
  .FrequentlyAskedQuestions_faq_firstheading__1_5AL {
    font-size: 40px;
    font-family: "Poppins";
  }
}

.AddOnRequirements_mainContainer__21UFn {
  margin: 10px;
  padding: 20px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  padding-left: 30px;
  background-color: white;
}

.AddOnRequirements_mainHeading__3Cq6v {
  font-weight: bold;
  font-size: 18px;
}
.AddOnRequirements_addOnContainer__2IJxx {
  display: flex;
  padding: 20px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  justify-content: space-between;
  margin-top: 10px;
  max-width: 85%;
  align-items: center;
}
.AddOnRequirements_addOnHeading__3-XcB {
  font-weight: bold;
}

.AddOnRequirements_AddonPrice__2NdL5 {
  background-color: #617fff;

  height: 50px;
  width: 50px;
  display: grid;
  border-radius: 50%;
  color: white;
}
.AddOnRequirements_AddonDescription__3BFaw {
  width: 80%;
}
.AddOnRequirements_AddonAmount__3rCHD {
  font-size: 14px;
  font-weight: bold;
  margin: auto;
}
.AddOnRequirements_optionContainer__2ci-c {
  margin-top: 20px;
}
.AddOnRequirements_AddOnCheckBox__latEf {
  display: flex;
  width: 88%;
}
.AddOnRequirements_AddOnCheckBoxText__1IwFo {
  padding-left: 8px;
}
.AddOnRequirements_totalAmount__3SMqH {
  text-align: right;
  max-width: 85%;
  padding: 20px 0px 0px 20px;
}
.AddOnRequirements_payButton___4A6n {
  text-align: center;
 
}
.AddOnRequirements_AddOnCheckBoxInput__1-FLe {
  cursor: pointer;
  flex: 0 0 16px;
}
@media screen and (min-width: 1023px) {
  .AddOnRequirements_payButton___4A6n {
    text-align: center;
   max-width: 90%;
  }
}
.ApplicationDetail_mainContainer__1Zd9l {
  background-color: white;
  margin: -10px 10px 10px 10px;
  padding: 10px 20px 20px;
}

.ApplicationDetail_budgetContainer__3aALO {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
}
.ApplicationDetail_ApplicationDetail_image__3-gIk {
  width: 30px;
  height: 30px;
}

.ApplicationDetail_budgetAmount__3BleT {
  font-size: 14px;
  color: #7c7c7c;
}
.ApplicationDetail_workType__8OOD- {
  font-size: 14px;
  color: #7c7c7c;
  text-align: center;
}
.ApplicationDetail_budgetHeading__KjLN9 {
  text-align: center;
  font-size: 14px;
}
.ApplicationDetail_budgetAndWork__99ru7 {
  margin-left: 10px;
  text-align: center;
}

.ApplicationDetail_contentAlign__2YHPW {
  text-align: center;
  margin-top: 10px;
}
.ApplicationDetail_featuredListing__3K_Jm {
  display: none;
}
.ApplicationDetail_tagFeatured__gEOxb {
  margin-left: 10px;
  display: flex;
  align-items: center;
  
  margin-bottom: 10px;
}

.ApplicationDetail_sponsoredPost__2SoLK {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ApplicationDetail_alignButton__24GiH {
  text-align: right;
}

.ApplicationDetail_addonNameIcon__3svMo {
  margin-right: 5px;
  width: 20px;
}
.ApplicationDetail_addonContainer__zooa5 {
  display: flex;
  align-items: center;
  border: 1px solid #7c7c7c;
  border-radius: 20px;
  padding: 3px 8px;
}
.ApplicationDetail_Commission__1VbYp {
  color: #fea11b;
}

.ApplicationDetail_Contract__3bu1d {
  color: #486a31;
}
.ApplicationDetail_One-Time__2-NTZ {
  color: #365aa9;
}
.ApplicationDetail_Monthly__1JowF {
  color: #125552;
}
.ApplicationDetail_AddonName__28qkJ {
  font-size: 15px;
  color: #7c7c7c;
}
.ApplicationDetail_ActionButton__1fWOV {
  display: flex;
  justify-content: center;
}
.ApplicationDetail_PARBtn_Link__fEQd1 {
  text-decoration: none;
}
@media (min-width: 768px) {
  .ApplicationDetail_budgetAmount__3BleT {
    font-size: 30px;
    color: #000;
  }
  .ApplicationDetail_mainContainer__1Zd9l {
    margin: 10px;
    margin-left: 0;
  }
  .ApplicationDetail_budgetContainer__3aALO {
    align-items: baseline;
    justify-content: end;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .ApplicationDetail_workType__8OOD- {
    font-weight: bold;
    color: #496a32;
    text-align: right;
  }
  .ApplicationDetail_budgetHeading__KjLN9 {
    text-align: end;
  }
  .ApplicationDetail_featuredListing__3K_Jm {
    justify-content: end;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    margin-left: 20px;
  }
  .ApplicationDetail_sponsoredPost__2SoLK {
    justify-content: end;
  }
  .ApplicationDetail_contentAlign__2YHPW {
    text-align: right;
  }
  .ApplicationDetail_AddonName__28qkJ {
    font-size: 14px;
  }
}

.ProposalsList_mainContainer__gjePL {
  background-color: white;
  margin: 10px;

  padding: 10px 20px 20px;
}

.ProposalsList_mainHeading__YQapW {
  font-weight: bold;
  font-size: 18px;
}
.ProposalsList_listOfProposals__2wPw1 {
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  padding: 20px;
  margin-top: 10px;
}

.ProposalsList_iconAndUsername__1O7kd {
  display: flex;
  align-items: center;
}
.ProposalsList_budgetAmount__1KSvC {
  font-weight: 600;
  margin-top: 20px;
}
.ProposalsList_iconContainer__39IDp {
  background-color: rgb(228, 228, 228);
  border-radius: 50%;
}
.ProposalsList_profileImage__3Rdd2 {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.ProposalsList_freelancerName__i6FVA {
  font-size: 14px;
  margin-left: 10px;
  font-weight: bold;
}
.ProposalsList_freelacerSkill__3rq-l {
  font-size: 12px;
}
.ProposalsList_nameAndSkillContainer__Y-4WK {
  margin-left: 10px;
}

.ProposalsList_budgetPricingFreelancer__1tAYQ {
  display: flex;
  justify-content: space-between;
}

.ProposalsList_acceptButton__2Evat {
  padding: 5px 10px 5px 10px;
  
  background-color: green;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  font-weight: bold;
  font-size: 12px;
}
.ProposalsList_viewProposalButton__lowLw{
  padding: 5px 10px 5px 10px;
  border:1px solid #1878F2;
  color:#1878F2;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  font-weight: bold;
  font-size: 12px;
}
.ProposalsList_viewProposalButton__lowLw:hover{

  background-color:#1878F2;
  color:#ffffff;
  cursor: pointer;
  


}
.ProposalsList_rejectButton__29m2H {
  padding: 5px 10px 5px 10px;

  background-color: rgb(255, 57, 83);
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  font-weight: bold;
  font-size: 12px;
  margin-left: 5px;
}
.ProposalsList_buttonContainer__BPMmZ {
  display: flex;
  justify-content: end;
  margin-top: 20px;
  align-items: center;
}

.ProposalsList_messageIcon__3AKuN {
  color: #0099cc;
  font-size: 25px;
  margin-right: 10px;
}
.ProposalsList_messageIcon__3AKuN:hover{
  color: #4700cc;
  cursor: pointer;
}

.ProposalsList_cnacelIcon__3fTRN {
  color: #f2163b;
  font-size: 20px;
  margin-left: 10px;
}

.ProposalsList_rejectButton__29m2H:hover {
  background-color: #a1122a;
  cursor: pointer;
}
.ProposalsList_acceptButton__2Evat:hover {
  background-color: rgb(0, 182, 0);
  cursor: pointer;
}
.ProposalsList_notificationCounter__GOtzd {
  background-color: red;
  padding: 3px;
  color: white;
  border-radius: 50%;
  position: absolute;
  margin-top: -12px;
  margin-left: 11px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}

.ProposalsList_budgetUnit__3v4AX {
  font-size: 16px;
}
.ProposalsList_closePostButtons__3Crfh{
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.ProposalsList_dateProposal__1rvd0{
color: gray;
font-size: 12px;
}
.ProposalsList_proposalPointsHeading__1y1Pg{
  font-weight: 800;
}
.ProposalsList_proposalResponse__-R1qo{
  font-weight: 400;
}
.ProposalsList_statusPayment__3ttHM{
  color: green;
  font-weight: 800;
  
}
.ProposalsList_statusPaymentRejected__2x0Nj{
  color: rgb(255, 7, 11);
  font-weight: 800;
}
@media (min-width: 768px) {
.ProposalsList_closePostButtons__3Crfh{
  width: 50%;
  display: flex;
  justify-content: space-between;
}
.ProposalsList_mainContainer__gjePL{
  margin-left: 0px;
}

}
.RequirementDetailArea_mainContainer__1CTkK {
  background-color: white;
  margin: 10px 10px 0px 10px;
  padding: 10px;
}

.RequirementDetailArea_title__2ZWVh {
  font-size: 20px;
  font-weight: bold;
}

.RequirementDetailArea_dateAndPostType__8tD30 {
  color: grey;
  font-weight: 500;
  font-size: 14px;
  font-style: italic;
  margin-top: 20px;
}

.RequirementDetailArea_contentIndetail__1pDxb {
  margin-top: 20px;
  font-size: 14px;
}

.RequirementDetailArea_logoPlacement__Z29QU {
  margin-top: 20px;
}

.RequirementDetailArea_Comapany_Name__3N9nu {
  padding-top: 10px;
}

.RequirementDetailArea_skilltagsContainer__10FIj {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
}

.RequirementDetailArea_CompanyLogo__3zfKW {
  max-width: 120px;
  max-height: 300px;
}

.RequirementDetailArea_skillTags__Vi5r3 {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  background-color: #f5f5f5;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 10px;
}

.RequirementDetailArea_location__1rncW {
  margin-top: 20px;
}

.RequirementDetailArea_featuredListing__1QkT4 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

.RequirementDetailArea_tagFeatured__29mP0 {
  display: flex;
  align-items: center;
  border: 1px solid #7c7c7c;
  border-radius: 20px;
  padding: 2px 7px;
  margin: 3px;
}

.RequirementDetailArea_addonNameIcon__du9_4 {
  margin-right: 5px;
  width: 20px;
}

.RequirementDetailArea_AddonName__re4Fg {
  font-size: 15px;
  color: #7c7c7c;
}

.RequirementDetailArea_addonContainer__2xf8S {
  display: flex;
  align-items: center;
}
.RequirementDetailArea_dateAndPostType__8tD30 {
  color: grey;
  font-weight: 500;
  font-size: 14px;
  font-style: italic;
  padding-bottom: 5px;
}
.RequirementDetailArea_dateAndPostTypeSection__2SIhM {
  margin-top: 10px;
}
.RequirementDetailArea_LocationSection__363UX {
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .RequirementDetailArea_skillTags__Vi5r3 {
    margin: 10px;
  }
  .RequirementDetailArea_mainContainer__1CTkK {
    background-color: white;
    margin: 10px;
    padding: 20px;
  }
  .RequirementDetailArea_title__2ZWVh {
    width: 70%;
  }
  .RequirementDetailArea_featuredListing__1QkT4 {
    display: none;
  }
}

.RequirementPageHeader_mainContainer__-6dNh {
  padding: 20px;
  background-color: #315add;

  color: white;
}
.RequirementPageHeader_commission__W1288 {
  background-color: #fea11b;
}

.RequirementPageHeader_contract__25vLK {
  background-color: #486a31;
}
.RequirementPageHeader_oneTime__35jaD {
  background-color: #365aa9;
}
.RequirementPageHeader_monthly__3KNng {
  background-color: #125552;
}

.RequirementPageHeader_workingPolicy__3eOSj {
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
}
.RequirementPageHeader_mainSkill__7vMoR {
  font-size: 27px;
  font-weight: 600;
  padding-left: 10px;
  margin: 0px;
  color: #fff;
}
.RequirementPageHeader_mainSkillStatus__NnmSN {
  font-size: 25px;
  font-weight: 600;
  padding-left: 10px;
  margin: 8px 0px 5px 0px;
  color: #fff;
  font-style: italic;
}
.RequirementPageHeader_mainSkillContainer__1yhwG {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

/* .skillAndStatus {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
} */
.RequirementPageHeader_backArrow__VuRjm {
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
  cursor: pointer;
}
.RequirementPageHeader_arrowAndMenuOption__rZNGN {
  display: flex;
  justify-content: space-between;
}
.RequirementPageHeader_menuOption__15INV {
  display: flex;
  margin-top: 12px;
}
.RequirementPageHeader_menuButton__3_Fl- {
  padding: 3px 5px;
  border: 1px solid white;
  font-size: 14px;
  margin-left: 10px;
  border-radius: 3px;
}
.RequirementPageHeader_menuButton__3_Fl-:hover {
  background-color: white;
  cursor: pointer;
  color: black;
}
.RequirementPageHeader_contractGreen__3EQ76 {
  color: #caffa6;
}
.RequirementPageHeader_OneTimeBlue__2icVL {
  color: #d5dfff;
}
.RequirementPageHeader_monthlyGrey__i4bxy {
  color: #85fffa;
}
.RequirementPageHeader_comYellow__VeUl2 {
  color: #4c2c00;
}
@media screen and (min-width: 1023px) {
  .RequirementPageHeader_skillAndStatus__gYb8U {
    display: flex;
    justify-content: space-between;
  }
  .RequirementPageHeader_mainSkill__7vMoR {
    font-size: 30px;
    font-weight: 600;
    padding-left: 10px;
    margin: 0px;
    color: #fff;
  }
}

.TopFiveFreelancer_mainContainer__1izZw {
  margin: 10px;
  padding: 20px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  padding-left: 30px;
  background-color: white;
}
.TopFiveFreelancer_mainHeading__2ci76 {
  font-weight: bold;
  font-size: 18px;
}
.TopFiveFreelancer_profilesContainer__2CRPd {
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.TopFiveFreelancer_profileImageDisplay__rjhoa {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: whitesmoke;

  align-items: center;
  display: inline-grid;
}
.TopFiveFreelancer_eachProfileSet__27YNw {
  margin: 10px 50px;
  margin-top: 20px;

  text-align: center;
}
.TopFiveFreelancer_name__BoSgM {
  font-weight: bold;
  margin-top: 10px;
}
.TopFiveFreelancer_skills__1Zcsi {
  font-size: 16px;
}

.TopFiveFreelancer_connectButton__1dP7Z {
  color: #1678f2;
  font-weight: bold;
  font-size: 12px;
  margin-top: 5px;
  cursor: pointer;
}
.TopFiveFreelancer_hirebutton__2zlRd {
  padding: 5px 10px;

  background-color: green;
  color: white;

  margin-top: 10px;
}

.TopFiveFreelancer_noApplication__16Sik {

  text-align: center;

}
.TopFiveFreelancer_contentAndIcon__1X5Sy {
  margin: auto;
  margin-top: 30px;
  text-align: center;
}
.TopFiveFreelancer_iconArea__1I-Pa {
  height: 50px;
  width: 50px;
  
}
.TopFiveFreelancer_Image_Profile__2fjTp {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  cursor: pointer;
}
@media (min-width: 768px) {
  .TopFiveFreelancer_profilesContainer__2CRPd {
    display: flex;
  }
}

.WaitListUsers_mainContainer__3lf1Y {
  background-color: white;
  margin: 10px;
  margin-left: 0;
  padding: 10px 20px 20px;
}
.WaitListUsers_mainHeading__8jmAO {
  font-weight: bold;
  font-size: 18px;
}
.WaitListUsers_iconContainer__ke0ur {
  padding: 20px;
  margin-left: 20px;
  background-color: rgb(228, 228, 228);
  border-radius: 50%;
}
.WaitListUsers_freelancerName__373ps {
  font-weight: bold;
}

.WaitListUsers_nameAndSkillContainer__PNiVN {
  margin-left: 20px;
}
.WaitListUsers_iconAndUsername__3rX_P {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.WaitListUsers_connnetOption__3Vah0 {
  color: #1678f2;
  font-weight: bold;
  font-size: 14px;
}

.WaitListUsers_connnetOption__3Vah0:hover {
  cursor: pointer;
}

.ClientRequirementDetail_pageLayout__2hEKy {
  background-color: #f5f6fb;
}
.ClientRequirementDetail_pageColumn__peSvP {
  display: flex;
  justify-content: center;
}
.ClientRequirementDetail_leftArea__2eLne {
  width: 70%;
}
.ClientRequirementDetail_rightArea__yoq0D {
  width: 30%;
}
.ClientRequirementDetail_pageLoader__VO7eX {
  text-align: center;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ClientRequirementDetail_closePostHeading__2wXuC {
  font-size: 24px;
  margin-top: -10px;
}
.ClientRequirementDetail_closePostButtons__3wXhk {
  width: 220px;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}
.ClientRequirementDetail_btnContainer__2CpSf {
  padding: 10px 0px 3px 0px;
}
.ClientRequirementDetail_closePostHeading__2wXuC {
  font-size: 20px;
}
.ClientRequirementDetail_closePostButtonsConatiner__2J8oe {
  display: flex;
  justify-content: center;
}
@media (min-width: 768px) {
  .ClientRequirementDetail_closePostButtons__3wXhk {
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
  }
  .ClientRequirementDetail_btnContainer__2CpSf {
    padding: 20px 0px 10px 0px;
  }
  .ClientRequirementDetail_closePostButtonsConatiner__2J8oe {
    display: flex;
    justify-content: center;
  }
}

.Payments_mainContainer__1Hv1N {
  background-color: white;
  margin: 10px;
  padding: 20px;
  padding-left: 30px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.Payments_noApplication__1JAdD {
  margin-bottom: 20px;
  text-align: center;
}
.Payments_heading__kWoRf{
  text-align: center;
}
.Payments_mainHeading__1qiEF {
  font-size: 20px;
  font-weight: bold;
}
.Payments_headingContainer__1ciuF {

  max-width: 99%;
}
.Payments_paymentDetails__1D5bu {
  margin-top: 10px;
  padding: 20px 0px;
  max-width: 85%;
}
.Payments_title__9fClw {
  font-size: 13px;
}
.Payments_name__2Dvr- {
  font-weight: bold;
}.Payments_contentAndIcon__3zvWy {
  margin: auto;
  margin-top: 30px;
}
.Payments_iconArea__hDswi {
  font-size: 30px;
  margin: auto;

  margin-top: 30px;
  display: grid;
}

.Payments_profilePicture__1QYpK {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50%;
}
.Payments_profileContainer__2qEsf {
  align-items: center;
  display: flex;
}
.Payments_border__3-b3K {
  border-bottom: 1px groove #fdfdfd;
}
.Payments_idWrapper__2HVrD{
  margin-top: 10px;
}
.Payments_faq_ActionButton__2n__8{
  text-align: right;
  margin-right: 5px;
}
.Payments_tableheading__FEK06{
  font-weight: 300;
  font-size: 14px;
}
@media screen and (min-width: 1023px) {

  .Payments_headingContainer__1ciuF {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
}


.PersonalInformation_pageMainContainer__175zz {
  padding: 20px 40px;
  background-color: white;
  margin-right: 10px;
}
.PersonalInformation_formContainerCompany__M7Kcv {
  width: 80%;
  margin-top: 40px;
}

.PersonalInformation_InputContainer__1WB_5 {
  width: 80%;
  margin-top: 20px;
}
.PersonalInformation_formContainer__8BvUr {
  margin-top: 30px;
}
.PersonalInformation_disabledInput__2C4vm {
  pointer-events: none;
  opacity: 0.4;
}
.PersonalInformation_inputArea__3ykYQ {
  padding: 10px 10px;
  outline: none;
  border: 1px solid #ece9e9;
  margin: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
}
.PersonalInformation_inputFieldsWrapper__3SJGI {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.PersonalInformation_inputAdressWrapper__2Q8jL {
  margin-bottom: 5px;
  margin-top: 5px;
}
.PersonalInformation_alternateContainer__365UK {
  margin-top: -10px;
}
.PersonalInformation_CheckBoxInputDiv__2aXs4 {
  margin-bottom: 10px;
}
.PersonalInformation_submitbutton__MIJgR {
  width: 100%;
  text-align: center;
}
.PersonalInformation_inputFields__2YEYU {
  width: 47%;
}
.PersonalInformation_loaderLabel__3llAW {
  margin-top: 10px;
}

.PersonalInformation_AddAlternative__31w9Z {
  display: flex;
  align-items: center;
}
.PersonalInformation_AddAlternativeOpen__mKiFx {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.PersonalInformation_addIcon__1il-Y {
  margin-right: 10px;
  height: 18px;
  width: 18px;
  cursor: pointer;
}
.PersonalInformation_addIcon__1il-Y:hover {
  color: #1678f2;
}
.PersonalInformation_CheckBoxInput__ZPRzp {
  cursor: pointer;
}
.PersonalInformation_error_message__2wDf1 {
  color: rgb(250, 0, 0);
}
.PersonalInformation_editButton__d_rVY {
  cursor: pointer;
}
.PersonalInformation_editButton__d_rVY:hover {
  color: #1678f2;
}
.PersonalInformation_alterNateText__1USYI {
  cursor: pointer;
}

.PersonalInformation_personalInfoInput__30nm8 {
  margin-bottom: 20px;
}
.PersonalInformation_labelWithIcon__14vVN {
  display: flex;
  align-items: center;
}
::-webkit-calendar-picker-indicator {
  cursor: pointer;
}
.PersonalInformation_personalInfoInputEdit__33def {
  margin: 25px 0px;
}
.PersonalInformation_personalInfoInputState__XIHRD {
  margin: 35px 0px;
}



.PersonalInformation_form_upload_label__463A0 {
  display: flex;
  height: 120px;
  width: 120px;
  border-radius: 60px;
  cursor: pointer;
  border: 1px solid #ece9e9;
  align-items: center;
  flex-direction: column;
}
.PersonalInformation_uploadLogoText__3WpwV {
  margin-left: 30px;
}
.PersonalInformation_updateImage__nv6Xn {
  display: flex;
  height: 120px;
  width: 120px;
  border-radius: 60px;
  border: 1px solid #ece9e9;
  align-items: center;
}
.PersonalInformation_imageEditIcon__2j_Ck {
  display: flex;
  height: 30px;
  width: 30px;
  border-radius: 50px;
  cursor: pointer;
  background: white;
  margin-left: -39px;
  border: 1px solid #848484;
  align-items: center;
}
.PersonalInformation_uploadImagebutton__20UhJ {
  display: flex;
  height: 30px;
  width: 30px;
  border-radius: 50px;
  cursor: pointer;
  background: white;
  margin-left: 84px;
  position: absolute;
  margin-top: -34px;
  border: 1px solid #848484;
}

.PersonalInformation_uploadContainer__Bqu_G {
  display: flex;
  align-items: center;
}
.PersonalInformation_updatetImageContainer__snwJF {
  display: flex;
  align-items: end;
}
.PersonalInformation_uploadIcon__2Bync {
  cursor: pointer;
  color: gray;
  margin: auto;
}
.PersonalInformation_uploadImgText__33Ed1 {
  font-size: 12px;
  margin-top: -30px;
  text-align: center;
}



.PersonalInformation_scrollArea__O-r0L {
  background-color: white;
  display: flex;
  padding: 10px 10px 0px 10px;
  margin: 10px 0px;
}

.PersonalInformation_innerNavigation__1vMGb {
  display: flex;
}
.PersonalInformation_menuOptionContent__3T4_W {
  font-size: 20px;
  font-weight: bold;
  margin: 0px 15px 0px;
  font-weight: bold;
  color: black;
  text-decoration: none;
  padding-bottom: 8px;
}

.PersonalInformation_menuOptionContent__3T4_W:hover {
  cursor: pointer;
}
.PersonalInformation_companyInfoInputEdit__19dDo {
  margin-top: 20px;
}
.PersonalInformation_companyInfoInputState__2AM8- {
  margin-top: 20px;
}
.PersonalInformation_menuOptionSelected__1zPMQ {
  font-size: 20px;
  margin: 0px 15px 0px;
  font-weight: bold;
  color: #1678f2;
  text-decoration: none;
  border-bottom-width: 4px;
  border-bottom-color: #1678f2;
  border-bottom-style: solid;
  padding-bottom: 8px;
}
.PersonalInformation_menuOptionContent__3T4_W:hover {
  cursor: pointer;
}
.PersonalInformation_pageLoader__1SLu9 {
  text-align: center;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.PersonalInformation_closePostHeading__1SFI7 {
  font-size: 24px;
  margin-top: -10px;
}
.PersonalInformation_closePostButtons__3Bbv2 {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-top: 16px;
}
.PersonalInformation_nameHeading__172th {
  font-weight: bold;
  color: #1678f2;
  margin-bottom: 10px;
  font-size: 24px;
  text-transform: capitalize;
}
.PersonalInformation_socialIcons__2XPkf {
  margin-right: 5px;
  cursor: pointer;
}

@media (max-width: 1023px) {
  .PersonalInformation_scrollAreaContainer__jhsgf {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .PersonalInformation_form_upload_label__463A0 {
    height: 100px;
    width: 100px;
  }
  .PersonalInformation_personalInfoInput__30nm8 {
    margin-bottom: 10px;
  }
  .PersonalInformation_uploadImagebutton__20UhJ {
    margin-left: 73px;
    margin-top: -35px;
  }
  .PersonalInformation_nameHeading__172th {
    font-size: 22px;
  }
  .PersonalInformation_companyInfoInputEdit__19dDo {
    margin: 20px 0px;
  }
  .PersonalInformation_companyInfoInputState__2AM8- {
    margin: 34px 0px;
  }
  .PersonalInformation_scrollArea__O-r0L {
    display: flex;
    width: 550px !important;
  }
  /* .pageMainContainer {
    width: 80%;
  } */
  .PersonalInformation_personalInfoInputEdit__33def {
    margin: 20px 0px;
  }
  .PersonalInformation_personalInfoInputState__XIHRD {
    margin: 32px 0px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .PersonalInformation_AddAlternative__31w9Z {
    margin-bottom: 15px;
  }
}
.CancelProfile_closePostHeading__1rm8V {
  font-size: 24px;
  margin-top: -10px;
}
.CancelProfile_closePostHeading__1rm8V {
  font-size: 24px;
  margin-top: -10px;
}
.CancelProfile_closePostButtons__2liFn {
  width: 220px;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}
.CancelProfile_btnContainer__3q7KQ {
  padding: 10px 0px 3px 0px;
}
.CancelProfile_closePostHeading__1rm8V {
  font-size: 20px;
}
.CancelProfile_closePostButtonsConatiner__3aAOi {
  display: flex;
  justify-content: center;
}
@media (min-width: 768px) {
  .CancelProfile_closePostButtons__2liFn {
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
  }
  .CancelProfile_btnContainer__3q7KQ {
    padding: 20px 0px 10px 0px;
  }
  .CancelProfile_closePostButtonsConatiner__3aAOi {
    display: flex;
    justify-content: center;
  }
}

.VerifyOtpModal_error_message__3a_na {
  color: rgb(250, 0, 0);
  text-align: center;
}
.VerifyOtpModal_success_message__1VRO3 {
  color: #1778f2;
  text-align: center;
}
.VerifyOtpModal_para_text__24tqk {
  margin-top: 10px;
}
.VerifyOtpModal_LoadingBtn__2Pdmh {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 10px 10px;
  width: 55%;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
}
.VerifyOtpModal_forgot_password__lWhzz {
  text-align: center;
  margin-top: 20px;
}
.VerifyOtpModal_resend_code__YOzuB {
  color: #858585;
  text-align: center;
  margin-top: 10px;
}
.VerifyOtpModal_resend_code_link__3hHjr {
  color: #1778f2;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
}

.VerifyOtpModal_ActionBtn__lnzu0 {
  display: flex;
  justify-content: center;
}
.VerifyOtpModal_outer_otp_div__18Nzt {
  width: 190px;
  overflow: hidden;
  position: relative;
  margin: auto;
}

.VerifyOtpModal_otp_div__2lbSU {
  position: sticky;
  left: 0;
}

.VerifyOtpModal_otp_input__3BfRu {
  padding-left: 10px !important;
  letter-spacing: 33px;
  font-size: 30px !important;
  border: 0 !important;
  background-image: linear-gradient(
    270deg,
    #616161 70%,
    hsla(0, 0%, 100%, 0) 0
  );
  background-position: bottom;
  background-size: 50px 2px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 220px !important;
  min-width: 220px;
  border-radius: 0;
  outline: none;
  margin: 20px 0px;
}
.VerifyOtpModal_back_button__3xOPo {
  background: #fff;
  border: none;
  cursor: pointer;
}

.VerifyOtpModal_cancelModel__2ybDs {
  text-align: end;
}

.VerifyOtpModal_modalContainer__24Dd2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
}

.VerifyOtpModal_modal__2rXdj {
  background-color: white;
  margin: auto;
  
  position: relative;
  font-weight: 500;
  width: 40%;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
  z-index: 30;
}
.VerifyOtpModal_backIcon__311dt {
  cursor: pointer;
}
.VerifyOtpModal_button__2GMbV {
  width: 70px;
  color: #1778f2;
  padding: 2px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.VerifyOtpModal_mainContainer__1eTWE {
  padding: 0px 30px;
}
@media screen and (max-width: 1023px) {
  .VerifyOtpModal_modal__2rXdj {
    width: 80%;
   top: -25px;
  }
}

.SocialHandles_pageMainContainer__ZMyy1 {
  background-color: white;
  margin-right: 10px;
}
.SocialHandles_submitbutton__3mNrY {
  width: 100%;
  text-align: center;
}
.SocialHandles_mainHeading__3m8c- {
  font-size: 30px;

  font-weight: bold;
}
.SocialHandles_successMessage__25Iel {
  color: #1778f2;
}
@media (min-width: 1023px) {
  .SocialHandles_pageMainContainer__ZMyy1 {
    padding-top: 20px;
  }
}

.VerifyClientProfileEmail_error_message__3fLe6 {
  color: rgb(250, 0, 0);
  text-align: center;
}
.VerifyClientProfileEmail_para_text__3bE2l {
  margin-top: 10px;
}
.VerifyClientProfileEmail_LoadingBtn__2x5Pz {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 10px 10px;
  width: 55%;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
}
.VerifyClientProfileEmail_forgot_password__3Unuu {
  text-align: center;
  margin-top: 20px;
}
.VerifyClientProfileEmail_resend_code__3MKPt {
  color: #858585;
  text-align: center;
  margin-top: 10px;
}
.VerifyClientProfileEmail_resend_code_link__2UnxL {
  color: #1778f2;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
}

.VerifyClientProfileEmail_ActionBtn__3nGye {
  display: flex;
  justify-content: center;
}
.VerifyClientProfileEmail_outer_otp_div__3-vTP {
  width: 190px;
  overflow: hidden;
  position: relative;
  margin: auto;
}

.VerifyClientProfileEmail_otp_div__1vSN6 {
  position: sticky;
  left: 0;
}

.VerifyClientProfileEmail_otp_input__1b8-5 {
  padding-left: 10px !important;
  letter-spacing: 33px;
  font-size: 30px !important;
  border: 0 !important;
  background-image: linear-gradient(
    270deg,
    #616161 70%,
    hsla(0, 0%, 100%, 0) 0
  );
  background-position: bottom;
  background-size: 50px 2px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 220px !important;
  min-width: 220px;
  border-radius: 0;
  outline: none;
  margin: 20px 0px;
}
.VerifyClientProfileEmail_back_button__1rMwX {
  background: #fff;
  border: none;
  cursor: pointer;
}

.VerifyClientProfileEmail_cancelModel__2MdPF {
  text-align: end;
}

.VerifyClientProfileEmail_backdrop__1FffI {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.VerifyClientProfileEmail_modal__2dlQs {
  background-color: white;
  margin: auto;
  position: relative;
  font-weight: 500;
  width: 40%;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
  z-index: 30;
}
.VerifyClientProfileEmail_backIcon__Nz-w8 {
  cursor: pointer;
}
.VerifyClientProfileEmail_button__28UZv {
  width: 70px;
  color: #1778f2;
  padding: 2px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.VerifyClientProfileEmail_mainContainer__1PQwf {
  padding: 0px 30px;
}
@media screen and (max-width: 1023px) {
  .VerifyClientProfileEmail_modal__2dlQs {
    width: 80%;
  }
}

.AboutFreelancer_mainContainer__1rrJe {
  background-color: rgb(255, 255, 255);
  padding: 20px;
}
.AboutFreelancer_editBtn__2wFrP {
  display: flex;
  justify-content: flex-end;
}
.AboutFreelancer_heading__2x_9m {
  font-size: 20px;
  font-weight: bold;
}
.AboutFreelancer_SkillTab__ENuf8 {
  border-width: 1px;
  border-radius: 5px;
  border-color: rgb(223, 223, 223);
  padding: 5px 10px;
  border-style: solid;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 5px;
  font-size: 14px;
}
.AboutFreelancer_tabContainer__2YVZp {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}
.AboutFreelancer_SkillTab__ENuf8:hover {
  cursor: pointer;
  background-color: rgb(0, 0, 0);
  color: white;
}
.AboutFreelancer_mainHeading__xGXUq {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
}
@media (min-width: 1023px) {
  .AboutFreelancer_mainContainer__1rrJe {
    width: 60%;
    margin: 10px 0px 0px 10px;
  }
}

.MenuArea_mainContainer__FikgJ {
  background-color: white;
  margin-top: 10px;
}
.MenuArea_CrossIconContainer__20bBu {
  display: flex;
}
.MenuArea_experienceListContainer__NGcEp {
  border: 1px solid #ece9e9;
  padding: 10px;
  margin: 10px 20px;
  border-radius: 3px;
  width: 100%;
}
.MenuArea_updateImage__2G5tz {
  display: flex;
  
  margin: 10px;
  border-radius: 10px;
  border: 1px solid #ece9e9;
  align-items: center;
}
.MenuArea_fileIcon__24p5W {
  margin: 10px;
  border-radius: 10px;
  padding: 34px;
  border: 1px solid #ece9e9;
}
.MenuArea_menuContainer__J-oPx {
  padding: 10px;
  display: flex;
  background-color: #f0efef;
  justify-content: space-between;
  overflow: scroll;
}
.MenuArea_menuOptions__Tyyqc {
  margin-left: 20px;
  font-size: 20px;
  font-weight: bold;
}
.MenuArea_menuOptions__Tyyqc:hover {
  cursor: pointer;
  color: #1678f2;
}
.MenuArea_selectedMenu__3wA1A {
  border-bottom-width: 3px;
  border-bottom-color: #1678f2;
  margin-left: 20px;
  font-size: 20px;
  font-weight: bold;
  border-bottom-style: solid;
}
.MenuArea_portfolioImageContainer__2YLs8 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.MenuArea_selectedMenu__3wA1A:hover {
  cursor: pointer;
  color: #1678f2;
}
.MenuArea_portofolioImage__rXfyC {
  width: 100%;
}
.MenuArea_imageDiv__2tfCo {
  height: 200px;
  margin: 20px;
  width: 200px;
}
.MenuArea_imageMainArea__1fFz4 {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
}
.MenuArea_certificationListContainer__2y_X2 {
  border: 1px solid #ece9e9;
  padding: 10px;
  margin: 10px 20px;
  border-radius: 3px;
  width: 90%;
}
.MenuArea_CertificationContainer__215Ug {
  display: flex;
  width: 100%;
}
.MenuArea_editBtn__30csr {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
@media (min-width: 1023px) {
  .MenuArea_menuContainer__J-oPx {
    overflow: hidden;
  }
}

.ProfileImage_mainContainer__3bJjZ {
  background-color: #fff;
  width: 100%;
  padding-bottom: 30px;
}
.ProfileImage_uploadIcon__2OXHl {
  position: absolute;
  right: 130px;
  top: 119px;
}
.ProfileImage_mainProfileImageContainer__Eu2vD {
  height: 200px;
  background-color: green;
  width: 100%;
}
.ProfileImage_mainProfileImage__3z8ED {
  height: 200px;
  width: 100%;
  object-fit: cover;
}
.ProfileImage_editBtn__ZkVED {
  display: flex;
  justify-content: flex-end;
  margin: -60px 20px 44px 0px;
}
.ProfileImage_profilePictureNameContainer__3AvKu {
  margin-top: -153px;
  text-align: center;
  width: 100%;
  position: relative;
}
.ProfileImage_profilePicture__1HXjy {
  height: 150px;
  width: 150px;
  background-color: #f5f5f5;
  border-radius: 50%;
  margin: auto;
  display: flex;
    align-items: center;
    justify-content: center;
}
.ProfileImage_freelancerName__zROJs {
  font-size: 30px;
  font-weight: bold;
  padding: 10px 0px;
}
.ProfileImage_nameAndSkillContainer__sGJif {
  text-align: center;
}
.ProfileImage_skillCat__AEF_G {
  font-size: 20px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ProfileImage_updateImage__tHtvR {
  display: flex;
  height: 120px;
  width: 120px;
  border-radius: 60px;
  border: 1px solid #ece9e9;
  align-items: center;
}
.ProfileImage_editBtn__ZkVED {
  display: flex;
  justify-content: flex-end;
}
.ProfileImage_mainHeading__sNDCd {
  font-weight: bold;
  font-size: 18px;
}
.ProfileImage_socialContainer__3joGf {
  text-align: center;
  padding: 20px 0px 0px 0px;
}
.ProfileImage_iconContainer__3rOOO {
  display: flex;
  flex-direction: row;
  margin: 8px 0px;
  justify-content: center;
}
.ProfileImage_socialIcon__2qT_- {
  padding-right: 12px;
  cursor: pointer;
}
.ProfileImage_flagImage__3ITIw {
 
  margin-right: 10px;
  margin-top: 5px;
}
.ProfileImage_profilePictureName__3QWaN{
 text-align: center;
 font-size: 40px;
 font-weight: 600;

 
}
@media (min-width: 1023px) {
  .ProfileImage_profilePictureNameContainer__3AvKu {
    width: auto;
    margin-top: -70px;
    margin-left: 2%;
  }
  .ProfileImage_uploadIcon__2OXHl {
    position: absolute;
    right: 20px;
    top: 117px;
  }
  .ProfileImage_mainContainer__3bJjZ {
    background-color: #fff;
    width: 35%;
    padding-left: 60px;
    padding-bottom: 30px;
  }
  .ProfileImage_skillCat__AEF_G {
    font-size: 20px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .ProfileImage_nameAndSkillContainer__sGJif {
    text-align: left;
  }
  .ProfileImage_profilePicture__1HXjy {
    height: 150px;
    width: 150px;
    background-color: #f5f5f5;
    border-radius: 50%;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ProfileImage_socialContainer__3joGf {
    text-align: left;
    padding: 20px 0px;
  }
  .ProfileImage_iconContainer__3rOOO {
    display: flex;
    flex-direction: row;
    margin: 8px 0px;
    justify-content: unset;
  }
}

.WorkDetail_mainContainer__3tC8c {
  background-color: white;
  margin-right: 10px;
  margin-top: 10px;
  padding: 10px;
}
.WorkDetail_mainHeading__35awk {
  font-weight: bold;
  font-size: 18px;
}
.WorkDetail_workAvailability__16ma5 {
  padding-top: 10px;
}
.WorkDetail_sectionContainer__2YFP0 {
  margin-top: 20px;
}
.WorkDetail_editBtn__2r6w7 {
  display: flex;
  justify-content: flex-end;
}

.FreelancerProfileMain_pageLayout__3_605 {
  background-color: #f5f6fb;
}

.FreelancerProfileMain_leftArea__12rqX {
  width: 70%;
  margin-right: 10px;
}

.FreelancerProfileMain_pageContainer__29eOI {
  display: flex;
}
.FreelancerProfileMain_rightArea__3OGCK {
  width: 30%;
}
.FreelancerProfileMain_closeBtn__22B_w {
  display: flex;
  justify-content: flex-end;
}
.FreelancerProfileMain_mainProfileImageContainer__1dU92 {
  height: 200px;
  width: 100%;
}
.FreelancerProfileMain_mainProfileImage__17o7g {
  
  object-fit: cover;
}
.FreelancerProfileMain_profileAboutContainer__2BesU {
  display: flex;
  width: 100%;
}

.HireAssistent_pageLayoutContainer___fD-_ {
  background-color: #f5f6fb;
  display: flex;
}
.HireAssistent_pageLayout__3duue {
  width: 100%;
  margin-top: 10px;
  background: #fff;
}
.HireAssistent_BtnGateway__xu_D9 {
  border: 1px solid;
  border-radius: 6px;
  text-decoration: none;
  padding: 0px 12px;
  margin-top: 10px;
}

.HireAssistent_MobileMainBannerImgTextFirst__2jhS8 {
  position: absolute;
  padding: 48px 0px 0px 20px;
  font-size: 24px;
  font-weight: 700;
  margin: 0px;
}
.HireAssistent_MobileMainBannerImgTextSecond__QDId1 {
  position: absolute;
  padding: 80px 0px 0px 20px;
  font-size: 19px;
  font-weight: bold;
  margin: 0px;
}
.HireAssistent_MobileMainBannerHireButton__6OXUU {
  margin: 135px 0px 0px 20px;
  position: absolute;
}
.HireAssistent_BannercardContanier__2eTnh {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  margin-top: -80px;
}
.HireAssistent_Bannercard__Mgs6u {
  height: 120px;
  min-width: 100px;
  margin: 8px 12px;
  padding: 20px;
  box-shadow: 5px 0px #fff;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 5%) 0px 0px 0px 1px;
}
.HireAssistent_BannercardIcon__NtJzR {
  display: flex;
  margin: auto;
  
}
.HireAssistent_BannercardTextFirst__28q5B {
  text-align: center;
  font-size: 11px;
  padding-top: 10px;
  margin: 0px;
}
.HireAssistent_BannercardTextSecond__3UtlX {
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  padding: 2px 0px 8px 0px;
  margin: 0px;
}
.HireAssistent_SecondBannerImgContainer__1wV25 {
  margin-top: 40px;
}

.HireAssistent_SecondBannerTextContainer__vhGgV {
  position: absolute;
  padding: 16px 0px 0px 20px;
}
.HireAssistent_SecondBannerTextFirst__31it3 {
  font-size: 14px;
  font-weight: 900;
  margin: 0px;
}
.HireAssistent_SecondBannerTextSecond__txBmA {
  font-size: 16px;
  font-weight: 900;
  margin: 0px;
}
.HireAssistent_SecondBannerTextThird__OEiYt {
  font-size: 16px;
  font-weight: 900;
  margin: 0px;
}
.HireAssistent_SecondBannerHireButton__1UzxZ {
  margin: 10px 0px;
}

.HireAssistent_HireAssistantMainContainer__2HFsg {
  position: absolute;
  margin: 80px 0px 0px 50px;
}
.HireAssistent_WebMainBannerImgTextFirst__2rf2C {
  font-size: 45px;
  font-weight: 700;
  margin: 0px;
}
.HireAssistent_WebMainBannerImgTextSecond__2AmvG {
  font-size: 22px;
  font-weight: 700;
  margin: 0px;
}
.HireAssistent_WebMainBannerHireButton__3jaNV {
  margin: 135px 0px 0px 0px;
  margin-top: 22px;
  width: 212px;
}
.HireAssistent_WebBannercardContanier__3hpi5 {
  display: flex;
  width: 93%;
  margin-top: -80px;
  padding: 0px 32px;
  justify-content: space-around;
}
.HireAssistent_WebBannercard__1styv {
  height: 200px;
  width: 100%;
  margin: 8px 12px;
  padding: 20px 0px;
  box-shadow: 5px 0px #fff;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 5%) 0px 0px 0px 1px;
}
.HireAssistent_WebBannercardIcon__2oMFS {
  display: flex;
  margin: auto;
  
}
.HireAssistent_WebBannercardTextFirst__1nlch {
  text-align: center;
  font-size: 18px;
  padding: 15px 25px 0px 25px;
  margin: 0px;
}
.HireAssistent_WebBannercardTextSecond__2FwZY {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  padding: 0px 25px 0px 25px;
  margin: 0px;
}
.HireAssistent_Text_Container__3QnOl {
  width: 100%;
  margin-top: 40px;
}

.HireAssistent_SecondBannerImgSpace__1_Kif {
  width: 50%;
}
.HireAssistent_WebSecondBannerTextContainer__1Ropk {
  position: absolute;
  top: 60px;
  right: 50px;
}
.HireAssistent_WebSecondBannerTextFirst__3FZ26 {
  font-size: 22px;
  font-weight: 900;
  margin: 0px;
}
.HireAssistent_WebSecondBannerTextSecond__2XU9d {
  font-size: 35px;
  font-weight: 900;
  margin: 0px;
}
.HireAssistent_WebSecondBannerTextThird__BCs7e {
  font-size: 35px;
  font-weight: 600;
  margin: 0px;
}
.HireAssistent_WebSecondBannerImgContainer__VBq3W {
  position: relative;
  margin-top: 40px;
}

.HireAssistant_Text_Container__1UYlU{
    margin-top: 30px;
  }
  .HireAssistant_NumericTextContainer__GJNUx{
    display: flex;
    padding: 14px;
    
  }
  .HireAssistant_Icon__1M2wO{
    height: 40px;
    width: 130px;
    margin-top: 16px;
    color: #1D7B82;
  }
  .HireAssistant_NumericTextFirst__3CRIx{
    font-weight: 700;
    font-size: 20px;
    margin: 0px;
  }
  .HireAssistant_Paragraph__3XEzm{
    font-size: 17px;
    margin: 0px;
  }
  .HireAssistant_NumericTextParaContainer__16xNs{
    padding: 12px 0px 0px 10px;
  }
  .HireAssistant_MobileMainBannerHireButton__marXm{
    font-size: 16px;
    font-weight: 500;
    background-color: #1D7B82;
    padding: 10px;
    border-radius: 8px;
    color: #fff;
    
    width: 213px;
    text-align: center;
  }
  .HireAssistant_MainBannerHireButton__p-qVX{
    background-color: #1D7B82;
    padding: 10px 10px;
    border-radius: 8px;
    color: #fff;
    
    width: 211px;
    text-align: center;
  }
  .HireAssistant_MobileMainBannerHireLink__3AjJb{
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
  }
  @media (max-width: 768px) {
    .HireAssistant_NumericTextFirst__3CRIx{
      font-weight: 700;
      font-size: 17px;
      padding-top: 5px;
      margin: 0px;
    }
    .HireAssistant_Icon__1M2wO{
      height: 70px;
      width: 170px;
      margin-top: 0px;
      color: #1D7B82;
    }
    .HireAssistant_Paragraph__3XEzm{
      font-size: 15px;
      margin: 0px;
    }
  }
.PublicClientRequirementDetail_pageLayout__2Q0Xi {
  background-color: #f5f6fb;
}
.PublicClientRequirementDetail_pageColumn__jteja {
  display: flex;
  justify-content: center;
}
.PublicClientRequirementDetail_leftArea__tClPm {
  width: 70%;
}
.PublicClientRequirementDetail_rightArea__3B-ek {
  width: 30%;
}
.PublicClientRequirementDetail_pageLoader__2cv9g{
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.PublicClientRequirementDetail_btnModal__127MP {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.PublicClientRequirementDetail_overlay__2UhUx {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
}

.PublicClientRequirementDetail_modal__C4sY5 {
  background-color: white;
  margin: auto;
  position: relative;
  font-weight: 500;
  top: -25px;
  width: 84%;
  padding: 20px 0px;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
  z-index: 30;
}
.PublicClientRequirementDetail_closeModal__3B8hh {
  position: absolute;
  top: 4px;
  right: 10px;
  padding: 6px;
}
.PublicClientRequirementDetail_Message__2agNm{
  display: block;
  font-size: 15px;
  padding: 10px 12px 20px 12px;
  text-align: center;
  font-weight: 400;
}
.PublicClientRequirementDetail_LoaderCenter__2cx7C{
  text-align: center;
}
.PublicClientRequirementDetail_msgbtmcenter__1GO82{
  text-align: center;
}
.PublicClientRequirementDetail_title__ScP6a{
  display: block;
  font-size: 18px;
  text-align: center;
  font-weight:500;
  margin-top: 9px;
}

@media screen and (min-width: 1023px) {
  .PublicClientRequirementDetail_modal__C4sY5 {
    background-color: white;
      margin: auto;
      position: relative;
      font-weight: 500;
      top:-25px;
      width: 30%;
      padding: 23px 0px;
      border-radius: 14px;
      box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
      z-index: 30;
  }
  .PublicClientRequirementDetail_Message__2agNm{
    padding: 12px;
    font-size: 20px;
  }

  .PublicClientRequirementDetail_title__ScP6a{
      display: block;
      font-size: 20px;
      text-align: center;
      font-weight:500;
      margin-top: 18px;
  }
  
}
.PublicRecRequirementCard_mainContainer__29Y3X {
  background-color: white;
}

.PublicRecRequirementCard_mainCardContainer__1Zs1i {
  background-color: white;
  padding: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(219, 219, 219);

  margin-top: 20px;

  border-radius: 5px;
}
.PublicRecRequirementCard_imagecontainer__2Bd0c {
  width: 90px;
  background-color: rgb(219, 219, 219);
}
.PublicRecRequirementCard_workType__h7aNy {
  color: indigo;
  font-weight: bolder;

  margin-left: 10px;
}

.PublicRecRequirementCard_twoSectionDivider__WPuWl {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.PublicRecRequirementCard_skillContainer__O3LCi {
  font-size: 25px;
  font-weight: bold;
  margin-top: 20px;
}

.PublicRecRequirementCard_titleArea__11AmT {
  margin-top: 10px;
}

.PublicRecRequirementCard_postType__3qN9P {
  margin-left: 5px;
}
.PublicRecRequirementCard_skillTags__jj-qJ {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  color: #707070;
  background-color: #f5f5f5;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 10px;
  margin-top: 10px;
  font-size: 13px;
  font-weight: 600;
}
.PublicRecRequirementCard_skilltagsContainer__3zAcw {
  display: flex;
  flex-wrap: wrap;
}

.PublicRecRequirementCard_statusAndMenu__2G0Za {
  font-weight: bold;
  font-size: 20px;
}
.PublicRecRequirementCard_menuWithStatus__NUNyn {
  text-align: left;
}
.PublicRecRequirementCard_dropDownStatus__MNkiE {
  align-self: flex-end;
}
.PublicRecRequirementCard_rightSection__1MSXN {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 2%;
}
.PublicRecRequirementCard_icondots__2ym1B {
  margin-top: 6px;
}
.PublicRecRequirementCard_icondots__2ym1B:hover {
  cursor: pointer;
}
.PublicRecRequirementCard_workPolicy__3snmn {
  padding: 3px 7px;
  background-color: #3db2be;
  color: white;
  border-radius: 3px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
}
.PublicRecRequirementCard_iconContainer__khRuo {
  display: flex;
  margin-bottom: 5px;
}

.PublicRecRequirementCard_workingType__2b3iP {
  display: flex;
  align-items: center;
  margin-left: 2%;
}
.PublicRecRequirementCard_mobileMenuOption__2XJYd {
  display: flex;
}
.PublicRecRequirementCard_statusAnddate__UN4zT {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;

  color: grey;
}
.PublicRecRequirementCard_highlightText__1-k81 {
  font-weight: bold;
  margin-top: 10px;
}

.PublicRecRequirementCard_Commission__1up7r {
  color: #fea11b;
}

.PublicRecRequirementCard_Contract__1_T09 {
  color: #486a31;
}
.PublicRecRequirementCard_One-Time__3QZGI {
  color: #365aa9;
}
.PublicRecRequirementCard_Monthly__1En7Z {
  color: #125552;
}
.PublicRecRequirementCard_requirementCardButton__1SsTt {
  padding: 10px;
  border-width: 1px;
  border-color: #365aa9;
  border-style: solid;
  margin-top: 10px;
  color: #365aa9;
  font-size: 14px;
}
.PublicRecRequirementCard_requirementCardButton__1SsTt:hover {
  cursor: pointer;
}
.PublicRecRequirementCard_buttonWithIconContainer__a042n {
  display: flex;
  justify-content: end;
}
.PublicRecRequirementCard_colorBlue__1gmv8 {
  color: #365aa9;
  font-weight: normal;
}
.PublicRecRequirementCard_workStatus__31_fX {
  background-color: #d6ffe3;
  border-radius: 5px;
  padding: 5px;

  font-weight: bold;
  font-size: 14px;
  color: #43c962;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.PublicRecRequirementCard_postedDate__382HX {
  font-style: italic;
  color: grey;
  margin-left: 10px;
  font-size: 12px;
}

.PublicRecRequirementCard_budgetUnit__T7L5T {
  font-size: 13px;
  font-weight: 500;
}
.PublicRecRequirementCard_AddonName__2BseX{
  font-size: 15px;
  color: #7c7c7c;

}
.PublicRecRequirementCard_tagFeatured__2s5ao {
  margin-right: 10px;
  display: flex;
  align-items: center;
 
margin-bottom: 5px;
}
.PublicRecRequirementCard_featuredListing__233iQ{
  display: flex;
  margin: 4px 0 18px 0;
flex-wrap: wrap;
}


.PublicRecRequirementCard_addonNameIcon__36EsZ {
  margin-right: 5px;
 width: 16px;
}
.PublicRecRequirementCard_addonContainer__3FEuT {
  display: flex;
  align-items: center;
  border: 1px solid #7c7c7c;
  border-radius: 20px;
  padding: 3px 8px;
  
}
.PublicRecRequirementCard_noRequirement__3Mz75{
  padding-left: 12px;
  font-size: 20px;
  font-weight: 600;
}
.PublicRecRequirementCard_mainContainer__29Y3X{
  margin-bottom: 50px;
}

.PublicRecRequirementCard_titleSkill__311WJ{
  font-size: 16px;
  font-weight: 600;
}

.PublicRecRequirementCard_reqSection__2fiYq{
  padding: 20px;
  padding-left: 12px;
  padding-right: 12px;
}
.PublicRecRequirementCard_dateAndStatusContainer__3ODY4{
  margin-top: 10px;
  align-items: center;
  display: flex;

  
  
}
.PublicRecRequirementCard_dateArea__1LkjG{
  font-size: 12px;
  color:#878787 ;
  margin-left: 10px;
  font-weight: 600

}
.PublicRecRequirementCard_newTag__1JdDV{
  padding: 2px 10px;
  border-radius: 10px;
  background-color: #3E70B4;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 600;
  color: white;
  font-size: 12px;
}

.PublicRecRequirementCard_mainReqPoints__11CrX{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10%;

}
.PublicRecRequirementCard_reqPointsContainer__oYfAV{

}

.PublicRecRequirementCard_priceFont__wfDO0{
  font-size:14px;
  font-weight: 600;
}
.PublicRecRequirementCard_priceType__ENLwb{
  font-size: 12px;
  color:#878787 ;
  font-weight: 600;
}

.PublicRecRequirementCard_shortDesc__dO3XO{
  margin-top: 10%;
  font-size: 14px;
}

.PublicRecRequirementCard_skillTags__jj-qJ{
  padding: 3px 10px;
  background-color: #E3ECE4;
  font-size: 12px;
  width: -moz-fit-content;
  /* width: fit-content; */
  border-radius: 10px;
  margin: 5px;
  font-weight: 500;
}
.PublicRecRequirementCard_skillTagContainer__dQvNB{
  display: flex;
  margin-top: 5%;


}
.PublicRecRequirementCard_buttonContainer__jfofP{
  margin-top: 20px;
}
.PublicRecRequirementCard_greenButton__1ad6a{
  width: 70%;
  background-color: #4EA656;
  padding: 3px 5px;
  color: white; 
  text-align: center;
  border-radius: 25px;
 font-weight: 500;
  margin: auto;
}
.PublicRecRequirementCard_borderGreen__2BZrx{
  height: 1px;

  background-color: #4EA656;
  margin-top: 40px;
}


.PublicRecRequirementCard_moreJobsButton__1cR3w{
  padding: 5px 10px;
  border: 1px solid #4EA656;
  border-radius: 25px;
  text-align: center;
  width: 80%;
  margin: auto;
  font-size: 16px;
  font-weight: 600; 
  
}

.PublicRecRequirementCard_skilltagsContainer__3zAcw {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5%;
}
.PublicRecRequirementCard_moreJobsButton__1cR3w:hover{
  background-color:#4EA656 ;
  color: white;
}
.PublicRecRequirementCard_noRequirementFoundText__3CMtC{
  padding-left: 4px;
  font-size: 16px;
  font-weight: 600;
}
@media (min-width: 768px) {
  .PublicRecRequirementCard_mainContainer__29Y3X {
    padding: 15px;
    margin-left: 10px;
  }
  .PublicRecRequirementCard_twoSectionDivider__WPuWl {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .PublicRecRequirementCard_menuWithStatus__NUNyn {
    text-align: right;
  }
  .PublicRecRequirementCard_mainCardContainer__1Zs1i {
    width: 70%;
    margin-right: 10px;
  }
  .PublicRecRequirementCard_leftSectionWidth__3ROii {
    width: 50%;
    margin: 2%;
  }

  .PublicRecRequirementCard_highlightText__1-k81 {
    margin-top: 0;
    justify-content: end;

    display: flex;
  }
  .PublicRecRequirementCard_noRequirementFoundText__3CMtC{
    padding-left: 12px;
    font-size: 16px;
    font-weight: 600;
  }
}

.AutoSuggestionSkills_mainDiv__38bRW {
  max-width: 800px;
  flex-direction: column;
  margin-bottom: 12px;
}
.AutoSuggestionSkills_input__zYw0c {
  width: 100%;
  height: 42px;
  margin-top: 5px;
  border: 1px solid #ededed;
  flex-direction: column;
  display: flex;
  padding: 12px;
  outline: none;
  font-size: 18px;
}

.AutoSuggestionSkills_primary__2QIun {
  font-size: 12px;
  text-align: right;
  margin-right: 5px;
  font-weight: bold;
  font-style: italic;
}
.AutoSuggestionSkills_input__zYw0c {
  padding: 10px 10px;
  outline: none;
  border: 1px solid #ece9e9;
  margin: 10px 0px;
  font-size: 18px;
}
.AutoSuggestionSkills_input__zYw0c:focus {
  border-color: #1778f2;
}

.AutoSuggestionSkills_suggestion__2aJKQ {
  max-width: 493px;
  border-bottom-left-radius: 5px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  margin-left: 3px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.AutoSuggestionSkills_suggestion__2aJKQ p {
  padding: 6px;
  cursor: pointer;
  font-size: 15px;
  margin: 0;
}

.AutoSuggestionSkills_skillSuggestionList__1XwQn {
  padding: 6px;
}

.AutoSuggestionSkills_skillSuggestionList__1XwQn:hover {
  background-color: #ededed;
  cursor: pointer;
}
.AutoSuggestionSkills_dropdown_items_active__JY1mI {
  background-color: #1678f2;
  color: #ffff;
}
p:hover {
  background-color: #ededed;
}
.AutoSuggestionSkills_icon__20J5O {
  position: absolute;
  margin-left: 49vw;
  margin-top: 13px;
  align-items: flex-end;
}

#AutoSuggestionSkills_Search__3bY0C {
  padding-left: 48px;
  padding-top: 1px;
  font-size: 22px;
  color: black;
  background-repeat: no-repeat;
  background-position: center;
  outline: 0;
}

#AutoSuggestionSkills_Search__3bY0C::-webkit-search-cancel-button {
  position: relative;
  right: 20px;
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background: black;
}
.AutoSuggestionSkills_searchIcon__1uhTf {
  position: absolute;
  top: 12px;
  left: 15px;
}
.AutoSuggestionSkills_highlighted__2oj-3 {
  background: #ededed;
}
.AutoSuggestionSkills_tagMainDiv__uigZR {
  margin-bottom: 6px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
}
.AutoSuggestionSkills_suggestionTag__2Mv2C {
  background-color: #1678f2;
  margin: 4px 4px;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px 7px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.AutoSuggestionSkills_suggestionTagFirst__1tfb7 {
  background: linear-gradient(45deg, #4568dc, #b06ab3);
  margin: 4px 4px;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px 7px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}
.AutoSuggestionSkills_closeIcon__3v3_q {
  margin-top: 4px;
  align-items: center;
  margin-left: 6px;
  float: right;
}

@media only screen and (min-width: 1024px) {
  .AutoSuggestionSkills_mainDiv__38bRW {
    flex-direction: column;

    margin: 0 0px;
  }
  .AutoSuggestionSkills_input__zYw0c {
    padding: 10px 10px;
    outline: none;
    border: 1px solid #ece9e9;
    margin: 10px 0px;
    font-size: 18px;
    border-radius: 0px;
  }
}

.PublicClientRequirementList_pageLayout__1bPfB {
  padding: 15px;
  
}

.PublicClientRequirementList_Overflow__3dPkK::-webkit-scrollbar {
  display: none;
}
.PublicClientRequirementList_pageDivider__1RdBK {
  display: flex;
}

.PublicClientRequirementList_filterContainer__2On3B {
  height: auto;
  width: 25%;
  background-color: white;
  padding: 20px;
}

.PublicClientRequirementList_filterByHeading__3s_D6 {
  font-weight: bold;
  padding: 10px 10px 3px 10px;
  font-size: 20px;

  border-bottom-width: 2px;
  border-bottom-color: black;
  border-bottom-style: solid;
}

.PublicClientRequirementList_filterOptionHeadings__3cYYJ {
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 5px;

  
}
.PublicClientRequirementList_filterOptionHeadingsContainer__1pUW2{
  padding-left: 3px;
  padding-top: 3px;
}
.PublicClientRequirementList_checkBoxContainer__3ag90 {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.PublicClientRequirementList_WorkTypeContainer__3RIyg{
  padding-left: 8px;
}
.PublicClientRequirementList_budgetTextInput__1YudU {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.PublicClientRequirementList_textInput__1VFys {
  width: 50%;
  margin: 3px;
  padding: 5px 10px;
  font-size: 14px;
}

.PublicClientRequirementList_goButton__1TSty {
  padding: 3px;
  border: 1px solid gray;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  border-radius: 3px;
}
.PublicClientRequirementList_goButton__1TSty:hover {
  cursor: pointer;
  background-color: #1678f2;
  color: white;
}

.PublicClientRequirementList_sortAndRequirmentContainer___WbPG {

  background-color: white;
  margin-left: 10px;
}

.PublicClientRequirementList_sortAndPageContainer__3olWF {
 padding-left: 4px;
}
.PublicClientRequirementList_sortContainer__2_urT {
  display: flex;
  overflow: hidden;
  width: 85%;
  justify-content: space-between;
  margin-top: 6px;
}

.PublicClientRequirementList_sortHeading__Nsj-m {
  font-weight: bold;
  border-bottom: 2px solid #1678f2;
  color: #1678f2;
}

.PublicClientRequirementList_sortHeading__Nsj-m:hover {
  cursor: pointer;
}


.PublicClientRequirementList_sortOptions__2KP40:hover {
  cursor: pointer;
}
.PublicClientRequirementList_sortOptionSelected__dEnUM {
  border-bottom-color: #c07fff;
  border-bottom-style: solid;
  border-bottom-width: 2px;
 width: -webkit-fit-content;
 width: -moz-fit-content;
 width: fit-content;
  color: #c07fff;
  font-weight: bold;
}

.PublicClientRequirementList_sortOptionSelected__dEnUM:hover {
  cursor: pointer;
}

.PublicClientRequirementList_pageNumbersContainer__7QnyQ {
  display: flex;
  margin-right: 10%;
  font-weight: bold;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}

.PublicClientRequirementList_pageNumbersContainerBottom__3z8Am {
  display: flex;
  margin-right: 10%;
  font-weight: bold;
  font-size: 20px;
  align-items: center;
  justify-content: end;
  margin-top: 3%;
  margin-bottom: 3%;
}

.PublicClientRequirementList_pageCount__2Q-jr {
  background-color: #1778f2;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  width: 28px;
  margin: 2px 6px;
  color: #fff;
  font-size: 14px;
  height: 28px;
}
.PublicClientRequirementList_searchBarContainer__3r4Fg {

  display: flex;
 

  width: 100%;
}
.PublicClientRequirementList_searchIcon__1n5_q {
  padding: 10px 12px;
  height: 22px;
  background-color: #1678f2;
  color: white;
  margin-top: 13px;
  display: flex;
  align-items: center;
}

.PublicClientRequirementList_searchIcon__1n5_q:hover {
  cursor: pointer;
  background-color: #0c58b4;
}

.PublicClientRequirementList_searchInput__siDp7 {
  padding: 3px 0px;
  width: 85%;
}
.PublicClientRequirementList_searchInput__siDp7:focus {
  outline: none;

  border: 1px solid #1678f2;
  border-right-width: 0px;
}
.PublicClientRequirementList_searchInput__siDp7::-webkit-input-placeholder {
  font-size: 16px;
  color: rgb(197, 197, 197);
}
.PublicClientRequirementList_searchInput__siDp7:-ms-input-placeholder {
  font-size: 16px;
  color: rgb(197, 197, 197);
}
.PublicClientRequirementList_searchInput__siDp7::placeholder {
  font-size: 16px;
  color: rgb(197, 197, 197);
}

.PublicClientRequirementList_borderLine__QeQwk {
  border-bottom: 1px solid #dedede;
  width: 40%;
}

.PublicClientRequirementList_buttons1__1_mEx {
  display: flex;
}
.PublicClientRequirementList_pageCountIcon__nyAWm {
  color: #1778f2;
  margin: 2px 6px;
}
.PublicClientRequirementList_pageCountIcon__nyAWm:hover {
  cursor: pointer;
}
.PublicClientRequirementList_PaginationContainer__JGvdG {
  padding: 22px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.PublicClientRequirementList_pageCountData__3ZZdH{
  padding: 2px 0px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content:center ;
}
.PublicClientRequirementList_buttonone__2pn-h {
  color: #fff;
  background-color: #1778f2;
  padding: 5px 12px 3px 12px;
  border: none;
  margin-left: 4px;
  margin-right: 3px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}
.PublicClientRequirementList_buttononeDisable__1BiMM {
  color: #fff;
  background-color: #1778f2;
  padding: 5px 12px 3px 12px;
  box-shadow: 0 8px 6px -6px black;
  border: none;
  margin-left: 4px;
  margin-right: 4px;
  font-size: 16px;
  border-radius: 5px;
  opacity: 0.5;
  pointer-events: none;
}
.PublicClientRequirementList_CurrentPageText__3eGoL{
  background-color:  #1778f2;
  border-radius: 50%;
  padding: 4px 10px;
  margin-right: 4px;
  color: #fff;
  margin-left: 8px;
  font-size: 12px;
  box-shadow: 0 8px 6px -6px black;
}
.PublicClientRequirementList_CurrentPageNext__3wiTh{
 background-color:  #1778f2;
  border-radius: 50%;
  padding: 5px 10px;
  margin-right: 5px;
  color: #fff;
  margin-left: 8px;
  font-size: 12px;
  box-shadow: 0 8px 6px -6px black;
}
.PublicClientRequirementList_CurrentPageTextNext__2AEgA{
  background-color:  #1778f2;
  border-radius: 50%;
  padding: 5px 10px;
  margin-right: 5px;
  color: #fff;
  margin-left: 8px;
  opacity: .5;
  font-size: 12px;
  box-shadow: 0 8px 6px -6px black;
}

.PublicClientRequirementList_buttononePre__Kv6cv{
  color: #fff;
  background-color: #1778f2;
  padding: 3px 12px 0px 12px;
  border: none;
  margin-left: 6px;
  margin-right: 2px;
  border-radius: 5px;
  opacity: 0.5;
  font-size: 16px;
}
.PublicClientRequirementList_pageHeading__3oNRt{
 padding-left: 4px;
 
}
.PublicClientRequirementList_pageHeadingTwo__2PkEP{
  padding-left: 4px;
  margin-top: -2%;
}
.PublicClientRequirementList_joinFreelancer__3N8zm{
  color: #1778f2;
  cursor: pointer;
}

.PublicClientRequirementList_skillOption__2jkmU {
  padding: 8px;
  border: 1.5px solid #59acbf;
  border-radius: 8px;
  text-align: center;
  margin: 5px;
  font-size: 12px;
  color: #59acbf;
  cursor: pointer;
}
.PublicClientRequirementList_skillOption__2jkmU:hover {
  background-color: #59acbf;
  color: #f3f9ff;
}
.PublicClientRequirementList_tagDisplay__1ucWe {
  display: flex;
  flex-wrap: wrap;
}
.PublicClientRequirementList_setoverFlow__1ifIT {
  overflow: scroll;
}

.PublicClientRequirementList_filter__19L3P{
  display: flex;
  margin: 13px 12px;
 
  
}
.PublicClientRequirementList_filtertag__1YDHj{
  padding: 4px;
}
.PublicClientRequirementList_filterbtn__3GxXK{
  padding: 10px 16px;
  cursor: pointer;
  border: transparent;
  color: white;
  background-color: #2b7de9;
}
.PublicClientRequirementList_sortHeadingFilterContainer__1r2PA{
  display: flex;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .PublicClientRequirementList_pageLayout__1bPfB {
    padding: 15px;
    background-color: #f5f6fb;
  }
  .PublicClientRequirementList_PaginationContainer__JGvdG {
    padding: 10px 22px 10px 22px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .PublicClientRequirementList_pageCountData__3ZZdH{
    padding: 10px 35px;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .PublicClientRequirementList_searchInput__siDp7 {
    padding: 3px 0px;
    width: 100%;
  }
  .PublicClientRequirementList_sortAndPageContainer__3olWF {
    display: flex;
    padding: 10px;
    padding-bottom: 0px;
    justify-content: space-between;
  }
  .PublicClientRequirementList_pageNumbersContainer__7QnyQ {
    justify-content: inherit;
    margin-top: 0;
  }
  .PublicClientRequirementList_sortContainer__2_urT {
    display: grid;
    justify-content: inherit;
  
    
  }
  .PublicClientRequirementList_searchIcon__1n5_q {
    padding: 10px 12px;
    height: 22px;
    background-color: #1678f2;
    color: white;
    margin-top: 13px;
    display: flex;
    align-items: center;
  }
  .PublicClientRequirementList_pageHeading__3oNRt{
    padding-left: 34px;
    
   }
   .PublicClientRequirementList_pageHeadingTwo__2PkEP{
     padding-left: 34px;
     margin-top: -2%;
   }

 
}
.FutureWork_FutureWork_bannerovertext__-Wm61 {
  position: absolute;
  color: #fff;
  text-align: center;
  top: 60%;
}

.FutureWork_FutureWork_bannerimageweb__3mxsl {
  display: none;
}
.FutureWork_FutureWork_maintext__1SheI {
  font-size: 21px;
  font-weight: bold;
  margin: 30px;
}
.FutureWork_FutureWork_imagefirst__1pHG_ {
  
  margin: auto;
  display: block;
  border-radius: 6px;
  display: block;
}
.FutureWork_FutureWork_imagefirstweb__46lkG {
  display: none;
}
.FutureWork_FutureWork_text__dxge3 {
  font-size: 23px;
  margin: 0px 30px;
}
.FutureWork_FutureWork_borderbottomfirst__2rurM {
  width: 109px;
  border-bottom: 3px solid #fb6e00;
  margin-left: 30px;
  border-radius: 5px;
  margin-bottom: 50px;
}
.FutureWork_FutureWork_borderbottomsecond__2JrpA {
  width: 66px;
  border-bottom: 6px solid #fb6e00;
  border-radius: 5px;
  margin: 50px 0px;
}

.FutureWork_FutureWork_borderbottomthird__2m6i9 {
  width: 77px;
  border-bottom: 6px solid #1c2f43;
  margin-left: 30px;
  border-radius: 5px;
  margin: 50px 0px;
}
.FutureWork_FutureWork_bgcolor__uxk4q {
  background: #1c2f43;
  color: #fff;
}
.FutureWork_FutureWork_lastheadingfirst__3NWK7 {
  font-size: 33px;
  font-weight: 200;
  padding: 20px 20px 0px 20px;
}
.FutureWork_FutureWork_lastheadingsecond__3ZBLa {
  font-size: 33px;
  font-weight: bold;
  margin: 0px 20px;
}
.FutureWork_ActionButton__1rcJa {
  margin: 0px 20px;
  display: block;
}
.FutureWork_FutureWork_bgcolorwebone__5fPZ6 {
  display: block;
}
.FutureWork_FutureWork_bgcolorwebtwo__1x5c- {
  display: none;
}
.FutureWork_PARBtn_Link__3lUzL {
  text-decoration: none;
  color: #fff;
}

@media screen and (min-width: 1023px) {
  .FutureWork_FutureWork_bannerimageweb__3mxsl {
  
    display: block;

  }
  .FutureWork_FutureWork_bannerovertext__-Wm61 {
    right: 30px;
    width: 35%;
    top: 250px;
  }

  .FutureWork_FutureWork_bannerimage__SVpp8 {
    display: none;
  }

  .FutureWork_FutureWork_dflex__11OAE {
    display: flex;
    justify-content: space-around;
    width: 80%;
    margin: auto;
    padding-top: 75px;
  }
  .FutureWork_FutureWork_maintext__1SheI {
    text-align: center;
  }
  .FutureWork_FutureWork_borderbottomfirst__2rurM {
    margin: auto;
  }
  .FutureWork_FutureWork_borderbottomthird__2m6i9 {
    align-items: right;
  }

  .FutureWork_FutureWork_imagefirstweb__46lkG {
   
    display: block;
    border-radius: 6px;
  }

  .FutureWork_FutureWork_imagefirst__1pHG_ {
    display: none;
  }
  .FutureWork_FutureWork_bgcolorwebone__5fPZ6 {
    background: #f2f2f2;
    display: block;
    width: 50%;
    border-radius: 10px 0px 0px 10px;
    color: #000;
  }
  .FutureWork_FutureWork_bgcolorwebtwo__1x5c- {
    background: #1c2f43;

    width: 50%;
    text-align: center;
    padding-top: 30px;
    border-radius: 0px 10px 10px 0px;
    font-size: 30px;
    display: block;
  }
  .FutureWork_FutureWork_bgcolor__uxk4q {
    background: none;
    display: flex;
    width: 80%;
    height: 170px;
    margin: auto;
    padding-top: 80px;
  }
  .FutureWork_ActionButton__1rcJa {
    display: none;
  }
  .FutureWork_FutureWork_border__1qIT0 {
    border-bottom: 5px solid #fb6e00;
    width: 20%;
    padding-top: 10px;
    margin: auto;
  }
}

.ArticlesRozgaar_shadowDiv__1Uf1s {
  border-radius: 12px;
}
.ArticlesRozgaar_titleHeading__cGkbK {
  margin: 0px;
  font-size: 24px;
  font-weight: 700;
}
.ArticlesRozgaar_shadowMainDiv__MhS2K {
  position: relative;
}
.ArticlesRozgaar_cardImageDiv__3rH3o {
  width: 210px;
}
.ArticlesRozgaar_banner__Y6SSx {
  width: 100%;
  object-fit: cover;
  transition: 0.5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  opacity: 1;
}
.ArticlesRozgaar_bannerDiv__2nkuB {
  width: 100%;
  position: absolute;
}
.ArticlesRozgaar_bannerDiv__2nkuB .ArticlesRozgaar_banner__Y6SSx {
  opacity: 0.5;
}
.ArticlesRozgaar_articleBanner__2rXqA {
  margin: auto;
  display: block;
}
.ArticlesRozgaar_postedDateDiv__3qfzH {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.ArticlesRozgaar_iconDiv__2T8pJ {
  font-size: 15px;
}
.ArticlesRozgaar_postedText__2hBDl {
  font-size: 12px;
  font-weight: 700;
  padding-right: 2px;
}
.ArticlesRozgaar_shadowDivHeading__2MH6B {
  padding: 15px;
  background-color: #fff;
}
.ArticlesRozgaar_cardMainDiv__15Ooo {
  padding: 20px;
}
.ArticlesRozgaar_contentMainDiv__1uY05 {
  padding: 15px;
}
.ArticlesRozgaar_imageCard__3R-65 {
 

  object-fit: cover;
}
.ArticlesRozgaar_shadowDivContentHeading__1NtPl {
  font-weight: 800;
  font-size: 17px;
  margin: 20px 0 10px 0;
}
.ArticlesRozgaar_cardHeading__3qZZk {
  font-weight: 700;
  font-size: 19px;

  margin: 0px;
  line-height: 1.2;
  color: black;
padding-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ArticlesRozgaar_cardHeadingSub__1UqY0 {
  font-weight: 600;
  font-size: 16px;

  margin: 0px;
  line-height: 1.2;
  color: black;
  padding-top: 5px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ArticlesRozgaar_articleDiv__1uoLl {
  display: flex;
  overflow: scroll;
}
.ArticlesRozgaar_articleCard__3QvwB {
  margin-right: 20px;
  margin-top: 15px;
  margin-bottom: 20px;
}
.ArticlesRozgaar_cardContent__1ywpM {
  font-size: 13px;
  color: black;
  font-weight: 500;
  margin: 0px;
  width: 90%;
padding-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.ArticlesRozgaar_articleCardInfoDiv__2gBD4 {
  display: flex;
  align-items: center;
}
.ArticlesRozgaar_info__3TSH8 {
  margin-left: 7px;
  font-size: 13px;
}
.ArticlesRozgaar_articleDiv__1uoLl::-webkit-scrollbar {
  display: none;
}
.ArticlesRozgaar_infoMainDiv__PCO0- {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  color: black;
  width: 100%;
}
.ArticlesRozgaar_icon__Yf8xO,
.ArticlesRozgaar_watchIcon__3OXDw {
  color: 848484px !important;
}
.ArticlesRozgaar_iconLink__zoouc {
  text-decoration: none;
  color: black;
  margin-left: 10px;
}

.ArticlesRozgaarWeb_banner__3TC5_{
    width: 100%;
    height: 499px;
    object-fit: cover;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    opacity: 1;

}
.ArticlesRozgaarWeb_bannerDiv__2fqnp{
    position: absolute;
    width: 100%;
}
.ArticlesRozgaarWeb_bannerDiv__2fqnp .ArticlesRozgaarWeb_banner__3TC5_{
    opacity: 0.5;
  }
  
.ArticlesRozgaarWeb_articleTitleMargin__3A95v{
    font-size: 34px;
    text-align: center;
    color: black;
    font-weight: 800;
    margin: 0px;
}
.ArticlesRozgaarWeb_icon__74l6Q{
    color: #848484;
    font-size: 13px;
    margin-right: 4px;
}
.ArticlesRozgaarWeb_watchIcon__38JsT{
    color: #848484;
    font-size: 16px;
    margin-right: 4px;
}
.ArticlesRozgaarWeb_recommendedArticles__3Ksgo{
    font-size: 34px;
    text-align: center;
    color: black;
    font-weight: 800;
    margin: 0px;
    padding-top: 40px;
}
.ArticlesRozgaarWeb_recommendedArticlesSubHeading__3wV-G{
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    padding-top: 15px;
    margin: 0px;
}
.ArticlesRozgaarWeb_cardMainDiv__2quEP{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 20px;
    cursor: pointer;
}
.ArticlesRozgaarWeb_cardHeading__yfVUf{
    font-size: 16px;
    font-weight: 800;
    color: black;
margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
padding-top: 4px;

}
.ArticlesRozgaarWeb_articleCardInfoDiv__nNzhV{
    display: flex;
    align-items: center;
    margin-top: 10px;
    color: black;
    font-size: 12px;
}
.ArticlesRozgaarWeb_infoMainDiv__1JnuQ{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2rem;
}
.ArticlesRozgaarWeb_cardContent__3t-eG{
    font-size: 13px;
    font-weight: 600;
    color: black;
    margin: 0px;
padding-top: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.ArticlesRozgaarWeb_image__1l26L{
   
    object-fit: cover;
}
.ArticlesRozgaarWeb_cardInnerDiv__1dXqQ{
    margin-right: 20px;
    width: 23%;
}
.ArticlesRozgaarWeb_shadowDiv__1eskP{

    background-color: #fff;
    border-radius: 10px;
    margin-left: 5rem;
    margin-right: 5rem;
}
.ArticlesRozgaarWeb_shadowMainDiv__-MRlA{
    padding: 60px;
}


.ArticlesRozgaarWeb_postedDateDiv__1yILG{
    display: flex;
    justify-content: space-between;
    margin-top: 20px
}
.ArticlesRozgaarWeb_iconDiv__2D_3K{
    margin-bottom: 10px;
    font-size: 25px;
    color: #5d5b5b;
    float: right;
    text-align: center;
}
.ArticlesRozgaarWeb_iconLink__1rpdM{
    text-decoration: none;
    color: black;
}
.ArticlesRozgaarWeb_postedText__1kYue{
    font-size: 16px;
    font-weight: 800;
    float: right;
}
.ArticlesRozgaarWeb_shadowDivHeading__2h5tp{
    font-weight: 700;
    font-size: 36px;
    letter-spacing: 1px;   
    text-align: center;
    margin-top: 55px;
}
.ArticlesRozgaarWeb_contentMainDiv__2AJ9A{
    padding-top: 15px;
}
.ArticlesRozgaarWeb_shadowDivContentHeading__3RNVi{
    font-weight: 700;
    font-size: 26px;
    margin: 10px 0 10px 0;
    padding-top: 10px;
}
.ExploreSkills_ExploreSkills_maincontainer__15LKi {
  padding: 30px 0px;
}
.ExploreSkills_ExploreSkills_img__1oGJK {
  
  display: block;
  position: absolute;
}
.ExploreSkills_ExploreSkills_heading__2ebiq {
  font-size: 24px;
  font-weight: bold;
}
.ExploreSkills_ExploreSkills_maintext__1MnJo {
  position: absolute;
  padding-top: 13px;
  position: relative;
  padding-left: 10px;
}
.ExploreSkills_ExploreSkills_imgweb__CvAbZ {
  display: none;
}
.ExploreSkills_partnerCompanies_about__2D9OJ {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .ExploreSkills_ExploreSkills_imgweb__CvAbZ {
    position: absolute;
    display: block;
    
  }
  .ExploreSkills_ExploreSkills_img__1oGJK {
    display: none;
  }
  .ExploreSkills_ExploreSkills_maintext__1MnJo {
    position: relative;
    padding-top: 26px;
    font-size: 20px;
    text-align: center;
  }
  .ExploreSkills_ExploreSkills_heading__2ebiq {
    font-size: 40px;
    font-weight: bold;
  }
  .ExploreSkills_partnerCompanies_border__3lzgd {
    border-bottom: 6px solid #3d2086;
    width: 15%;
    margin: 30px auto;
    border-radius: 6px;
  }
}

.ExploreSkillsNavbar_header_container__11ggf {
  width: 100%;
}
.ExploreSkillsNavbar_scroll_container__1fggc {
  overflow-x: scroll;
}
.ExploreSkillsNavbar_navbar__223vo {
  display: flex;
  font-size: 24px;
  border-bottom: 2px solid #f0f0f0;
  width: 455px;
  overflow-y: hidden;
}
.ExploreSkillsNavbar_menuOptionSelected__36tlI {
  font-size: 24px;
  margin: 5px 15px 0px;
  font-weight: bold;
  color: #060606;
  text-decoration: none;
  border-bottom-width: 4px;
  border-bottom-color: #422883;
  border-bottom-style: solid;
  padding-top: 20px;
  padding-bottom: 10px;
}
.ExploreSkillsNavbar_menuOptionContent__152xm:hover {
  cursor: pointer;
}
.ExploreSkillsNavbar_menuOptionContent__152xm {
  font-size: 24px;
  margin: 5px 15px 0px;
  font-weight: 500;
  color: #060606;
  text-decoration: none;
  padding-top: 20px;
  padding-bottom: 10px;
}
.ExploreSkillsNavbar_TextLineFirst__12xj4 {
  cursor: pointer;
  margin-top: 20px;
}
.ExploreSkillsNavbar_TextLineFirstbold__1YTYt {
  font-weight: bold;
  margin-top: 20px;
}
.ExploreSkillsNavbar_skills_Link__3x4im {
  text-decoration: none;
  color: #000;
}

.ExploreSkillsNavbar_jshondata_map__2JmlY {
  padding-top: 20px;
}
.ExploreSkillsNavbar_dflex__AVcz8 {
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-left: 10px;
}
.ExploreSkillsNavbar_jshondata__1zH0N {
  margin-left: 36px;
}
.ExploreSkillsNavbar_relatedSkillBox__2GTxB {
  border-width: 1px;
  border-radius: 5px;
  border-color: rgb(223, 223, 223);
  padding: 5px 10px;
  border-style: solid;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.ExploreSkillsNavbar_relatedSkillBox__2GTxB:hover {
  cursor: pointer;
  background-color: rgb(0, 0, 0);
  color: white;
}

.ExploreSkillsNavbar_relatedSkillContainer__FlMrU {
  display: flex;
  flex-wrap: wrap;
  max-height: 200px;
  overflow: scroll;
}
.ExploreSkillsNavbar_mainDiv__bw1wG {
  width: 100%;
  margin-top: 5px;
}
@media screen and (min-width: 768px) {
  .ExploreSkillsNavbar_header_container__11ggf {
    width: 85%;
    margin: auto;
    display: flex;
  }
  .ExploreSkillsNavbar_navbar__223vo {
    display: block;
    border-bottom: none;
    width: 100%;
    overflow: hidden;
  }
  .ExploreSkillsNavbar_MenuOnHover_active__3mU3A {
    border-bottom: none;
    font-size: 28px;
  }

  .ExploreSkillsNavbar_menuOptionSelected__36tlI {
    border-bottom: none;
    cursor: pointer;
    font-size: 28x;
  }
  .ExploreSkillsNavbar_dflex__AVcz8 {
    display: flex;
    flex-direction: row;
  }
  .ExploreSkillsNavbar_jshondata__1zH0N {
    flex-wrap: wrap;
  }
  .ExploreSkillsNavbar_jshondata_map__2JmlY {
    padding-top: 20px;
    cursor: pointer;
  }

  .ExploreSkillsNavbar_TextLineFirst__12xj4 {
    width: 100%;
    cursor: pointer;
  }
  .ExploreSkillsNavbar_TextLineFirstbold__1YTYt {
    font-weight: bold;
    cursor: pointer;
  }
  .ExploreSkillsNavbar_TextLine__11hVR {
    width: 100%;
  }
  .ExploreSkillsNavbar_scroll_container__1fggc {
    overflow-x: hidden;
  }
  .ExploreSkillsNavbar_ItemName__1UicW {
    width: 100%;
    margin-left: 30px;
    cursor: pointer;
  
  }
  .ExploreSkillsNavbar_mainDiv__bw1wG {
    width: 600px;
    margin-top: 5px;
  }
}

.BulkHiringPage_BulkHiringDesc__1PbOg {
  margin: 2rem 1rem 2rem 1rem;
}
.BulkHiringPage_bulkhiringHeading__-y0fj {
  font-weight: bold;
  font-size: 25px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  padding: 20px 12px;
  text-align: center;
}

.BulkHiringPage_bulkHiringlookingFor__36LBM {
  margin-left: 1rem;
  margin-bottom: 2rem;
}
.BulkHiringPage_bulkHiringBold__2cLjE {
  font-weight: bold;
  color: rgb(0 50 86);
  font-size: 30px;
  margin: 0px;
}
.BulkHiringPage_BulkHiringbutton__CBrzA {
  padding: 10px;
  margin-top: 30px;
  background: rgb(0 50 86);
  border: none;
  font-weight: bold;
  font-size: 16px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.BulkHiringPage_bannerImage__1SI0Y {
  display: block;
}
.BulkHiringPage_bannerImageWeb__1xL0f {
  display: none;
}
.BulkHiringPage_BulkHiringbannerImage__1lNui {
  display: block;
}
.BulkHiringPage_BulkHiringbannerImageWeb__2lrQ8 {
  display: none;
}

.BulkHiringPage_bannerTextAndImage__2SUfp {
  position: relative;
  color: white;
}

.BulkHiringPage_bannerText__Bo-BL {
  position: absolute;
  top: 40px;
  left: 16px;
}
.BulkHiringPage_textHeadingBold__1v_pp {
  font-weight: bold;
  font-size: 26px;
  margin-bottom: 10px;
  color: #fff;
 
}
.BulkHiringPage_skillsFlex__1mGU5 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 4rem 1rem 0rem 1rem;
}
.BulkHiringPage_skills__1aOL0 {
  width: 40%;
  text-align: center;
  margin-bottom: 7rem;
  cursor: pointer;
}
.BulkHiringPage_list__ylJZk {
  font-weight: bold;
}

.BulkHiringPage_requirementButton__3K9OT {
  cursor: pointer;
  color: rgb(0 50 86);
  background: WHITE;
  border: none;
  outline: none;
  border-radius: 3px;
  padding: 7px 15px;
  font-size: 16px;
  margin-top: 30px;
  font-weight: bold;
}
.BulkHiringPage_solutionIcons__25pju {
  font-size: 80px;
}
.BulkHiringPage_textHeading__2Izo4 {
  width: 70%;
  color: #fff;
}
@media screen and (min-width: 1023px) {
  .BulkHiringPage_skillsFlex__1mGU5 {
    margin: 5rem 8rem 5rem 8rem;
  }
  .BulkHiringPage_skills__1aOL0 {
    width: 33%;
  }
  .BulkHiringPage_bulkhiringHeading__-y0fj {
    font-size: 40px;
    margin-bottom: 2rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
  }
  .BulkHiringPage_BulkHiringDesc__1PbOg {
    margin: 2rem 3rem 2rem 4rem;
    font-size: 17px;
  }
  .BulkHiringPage_flex__q6WN2 {
    display: flex;
    flex-direction: row-reverse;
  }
  .BulkHiringPage_bulkHiringlookingFor__36LBM {
    width: 50%;
    padding-top: 3rem;
    padding-left: 40px;
  }
  .BulkHiringPage_bulkHiringBold__2cLjE {
    font-size: 55px;
  }
  .BulkHiringPage_BulkHiringbutton__CBrzA {
    padding: 20px;
    font-size: 20px;
  }

  .BulkHiringPage_bannerText__Bo-BL {
    top: 110px;
    left: 60px;
  }
  .BulkHiringPage_textHeadingBold__1v_pp {
    font-size: 50px;
    color: #fff;
  }
  .BulkHiringPage_textHeading__2Izo4 {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .BulkHiringPage_requirementButton__3K9OT {
    font-size: 25px;
  }
  .BulkHiringPage_BulkHiringbannerImageWeb__2lrQ8 {
    display: block;
  }
  .BulkHiringPage_BulkHiringbannerImage__1lNui {
    display: none;
  }
  .BulkHiringPage_bannerImage__1SI0Y {
    display: none;
  }
  .BulkHiringPage_bannerImageWeb__1xL0f {
    display: block;
  }
}

.CheckUserEligibility_PageLayoutContainer__13Dxf {
  padding: 30px;
  width: 80%;
  margin: auto;
  display: flex;
  background-color: #fff;
  margin-top: 10px;
  border-radius: 12px;
  flex-direction: column;
}
.CheckUserEligibility_PageLayoutHeading__aasvd {
  color: grey;
}
.CheckUserEligibility_circleIcon__3jK9L{
  margin-top: 4px;
}
.CheckUserEligibility_PageLayoutHeadingBorder__1Ezka {
  border-bottom: 0.5px solid lightgrey;
  padding-top: 15px;
}
.CheckUserEligibility_PageLayoutSecondHeadingContainer__TJ-qo {
  display: flex;
  padding-top: 20px;
}
.CheckUserEligibility_SecondHeadingText__1cGxL {
  font-size: 18px;
  padding-left: 12px;
  font-weight: 700;
}
.CheckUserEligibility_PageLayoutThirdContainer__2QyCi {
  display: flex;
}
.CheckUserEligibility_aerrowBack__cVVGb {
  cursor: pointer;
}
.CheckUserEligibility_FreeSectionPageLayout__fibbf {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 29px;
}
.CheckUserEligibility_FreeSectionContainer__3VCyZ {
  padding-top: 45px;
}
.CheckUserEligibility_FreeSectionText__27PMp {
  font-size: 18px;
  font-weight: 600;
  color: grey;
}
.CheckUserEligibility_AmountIconText__27_ne {
  display: flex;
  align-items: center;
  padding-top: 12px;
  font-size: 18px;
  font-weight: 600;
  color: grey;
}
.CheckUserEligibility_FreeSectionTextSecond__2-DR5 {
  padding-top: 15px;
  color: grey;
  font-size: 16px;
}
.CheckUserEligibility_CheckIconText__1E4na {
  display: flex;
  align-items: center;
  padding-top: 15px;
}
.CheckUserEligibility_FreeSectionTextThird__Rbn94 {
  color: #898989;
  padding-left: 15px;
  font-size: 14px;
}
.CheckUserEligibility_thirdSectionContainer__2gGUi {
  width: 65%;
  border: 1px solid #2066c2;
  border-radius: 8px;
  margin-top: 22px;
}
.CheckUserEligibility_PaidHeadingFirst__2rlPO {
  display: flex;
  justify-content: right;
  color: #778792;
  padding: 2px 16px 0px 16px;
}
.CheckUserEligibility_PaidHeading___PKEW {
  font-size: 18px;
  font-weight: 800;
  padding: 0px 20px;
}
.CheckUserEligibility_PaidAmountIconText__35tTb {
  display: flex;
  align-items: center;
  padding-top: 12px;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 16px 0px 16px;
}
.CheckUserEligibility_EditIcon__2Utck {
  display: flex;
  align-items: center;
  padding-left: 10px;
  text-decoration: none;
  color: #3480b8;
}
.CheckUserEligibility_PaidSectionPoint__3wD51 {
  padding-left: 20px;
}
.CheckUserEligibility_PaidSectionTextSecond__1iIu6 {
  padding-top: 15px;
  font-size: 16px;
}
.CheckUserEligibility_PaidSectionTextThird__1sX0e {
  padding-left: 15px;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.CheckUserEligibility_PaidButton__3_dGd {
  padding: 8px 10px;
  background-color: #1778f2;
  border-radius: 20px;

  text-align: center;
  color: #fff;
  cursor: pointer;
}
.CheckUserEligibility_PaidButton__3_dGd:hover {
  padding: 8px 10px;
  background-color: #fff;
  border-radius: 20px;
  
  text-align: center;
  color: #1778f2;
  cursor: pointer;
  border: 1px solid #1778f2;
}
.CheckUserEligibility_FreeButton__1KHkw {
  padding: 8px 10px;
  border: 1px solid #bfbfbf;
  border-radius: 20px;
  width: 100px;
  text-align: center;
  color: #bfbfbf;
  cursor: pointer;
}
.CheckUserEligibility_addOnAmount__3E23x {
  display: flex;
  align-items: center;
  padding: 30px 20px;
  justify-content: space-between;
}
.CheckUserEligibility_AddOnsHeading__3RLXg {
  font-size: 18px;
  font-weight: 800;
  padding: 20px 0px 0px 0px;
}
@media screen and (max-width: 1023px) {
  .CheckUserEligibility_PageLayoutThirdContainer__2QyCi {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .CheckUserEligibility_FreeSectionPageLayout__fibbf {
    width: 100%;
  }
  .CheckUserEligibility_FreeSectionContainer__3VCyZ {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .CheckUserEligibility_thirdSectionContainer__2gGUi {
    width: 100%;
    border: 1px solid #2066c2;
    border-radius: 8px;
    margin-top: 22px;
  }
  .CheckUserEligibility_PaidSectionPoint__3wD51 {
    padding-left: 20px;
  }
  .CheckUserEligibility_PaidButton__3_dGd {
    padding: 8px 10px;
    background-color: #3480b8;
    border-radius: 20px;
    width: 80px;
    font-size: 12px;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }
  .CheckUserEligibility_PageLayoutContainer__13Dxf {
   
    width: 94%;
    display: flex;
    background-color: #fff;
    margin-top: 10px;
    border-radius: 12px;
    flex-direction: column;
    margin: 15px;
    padding: 0px;
  }
  .CheckUserEligibility_PageLayoutSecondHeadingContainer__TJ-qo, .CheckUserEligibility_FreeSectionPageLayout__fibbf{
    width: 0px;
    height: 0px;
    padding: 0px;
    visibility: hidden;
  }
}

.Notification_Notification_maindiv__3aH5f {
  width: 100%;
  background: #fff;
}
.Notification_Notification_flex__13ycj {
  display: flex;
  font-size: 20px;
  padding: 30px 0px 5px 0px;
  border-bottom: 1px solid lightgrey;
}

.Notification_icon__1YuIF {
  margin-right: 20px;
  margin-top: 5px;
}
.Notification_Notification_heading__rsL7a {
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  background-color: white;
  margin: 10px 0px;


}
.Notification_Notification_container__1riCM {
  width: 85%;
  margin: auto;
}
.Notification_Notification_time__3RvrW {
  font-size: 12px;
  margin-bottom: 10px;
}
.Notification_blank_Notification_div__2v-w4 {
  text-align: center;
  padding-top: 35vh;
  height: 350px;
}
.Notification_blank_Notification_heading__-v6FI {
  font-size: 20px;
  font-weight: bold;
}
.Notification_blank_Notification_text__15E_F {
  font-size: 12px;
  margin: auto;
}

/* .blank_Notification_img {
  width: 20%;
} */
.Notification_notification_text__2ZSwj {
  font-size: 15px;
}
.Notification_notification_textbold__aB6Qc {
  font-size: 15px ;
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  /* .blank_Notification_img {
    width: 10%;
  } */
  .Notification_notification_text__2ZSwj {
    font-size: 18px;
  }
  .Notification_notification_textbold__aB6Qc {
    font-size: 18px ;
    font-weight: bold;
    
  }
  
}

.GatewayPaymentStatus_modal__2DLf4 {
  background-color: white;
  padding: 20px;
  text-align: center;
  width: 90%;
  margin: auto;
  border: 1px solid #00a484;
  border-radius: 5px;
}

.GatewayPaymentStatus_mainContainer__1s7a5 {
  margin-top: 30px;
}
.GatewayPaymentStatus_successfulHeading__2hnUA {
  font-weight: 600;
  font-size: 30px;
  color: #00a484;
}

.GatewayPaymentStatus_failHeading__2Tzqj {
  font-weight: 600;
  font-size: 30px;
  color: #cd2626;
}
.GatewayPaymentStatus_detailContainer__3Fb3N {
  margin-top: 20px;
}
.GatewayPaymentStatus_detailDiv__3qW0W {
  display: flex;

  justify-content: space-between;
  font-size: 20px;
}
.GatewayPaymentStatus_detailvalue__2IBv8 {
  font-weight: 600;
}

.GatewayPaymentStatus_mainHeading__2oMzD {
  font-weight: bold;
  color: gray;
}

.GatewayPaymentStatus_backMessage__22NyI {
  margin-top: 30px;
}

@media screen and (min-width: 768px) {
  .GatewayPaymentStatus_modal__2DLf4 {
    width: 30%;
  }
}

.RIFooter_footer_container__2PdKS {
  background-color: #171717;
  margin-top: 25px;
  color: #ffffff;
}
.RIFooter_footer_aboutUs__31hiF {
  display: flex;
  width: 57%;
  justify-content: space-between;
}
.RIFooter_footer_top__1UyfV {
  display: flex;
  padding: 20px;

  justify-content: flex-end;
  border-bottom: 1px groove grey;
}
.RIFooter_footer_contact__25QGY {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.RIFooter_aboutUs_list__279wc {
  text-align: center;
  
  width: 95%;
}
.RIFooter_BottomBorder__hJwBx{
  border-bottom: 1px groove grey;
}
.RIFooter_plus_icon__tc92G {
  background-color: #171717;
  color: #ffffff;
  border: hidden;
  cursor: pointer;
  font-size: 20px;
  width: 5%;
}
.RIFooter_plus_iconText__1-25h{
  background-color: #171717;
  color: #ffffff;
  border: hidden;
  cursor: pointer;
  font-size: 16px;
  width: 95%;
}
.RIFooter_riLogo__3GOPu {
  
  cursor: pointer;
}

.RIFooter_social_icons__3w90H {
  display: flex;
  justify-content: space-around;
  margin: auto;
  width: 260px;
  margin-top: 12px;
  margin-bottom: 12px;
}
.RIFooter_icons__2St-3 {
  padding: 0px 10px;
  height: 16px;
  cursor: pointer;
  border-right: 1px groove grey;
}
.RIFooter_icons__2St-3:last-child {
  border-right: hidden;
}
.RIFooter_payment_icon__33z1T {
  margin-top: 20px;
}
.RIFooter_all_rights__2OlpJ {
  text-align: center;
  padding: 10px 0px;
}
.RIFooter_list_open__3j2DJ {
  margin: 10px 0px;
  cursor: pointer;
  color: white;
  text-decoration: none;
}
.RIFooter_web_view_container__2KBpw {
  display: none;
}
.RIFooter_Footer_Link__3t0-S {
  text-decoration: none;
  color: #ffffff;
}
.RIFooter_Footer_Link__3t0-S:hover {
  text-decoration: none;
  color: #1778f2;
}
.RIFooter_icons_web__xsu-9:hover {
  color: #1778f2;
  border: 1px solid #1778f2;
}
.RIFooter_icons_web__xsu-9 {
  margin-right: 8px;
  border: 1px solid white;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  padding: 5px;
}
.RIFooter_MailBtn__2gJS6 {
  background: none;
  border: none;
  padding: 0px;
  color: #fff;
}
.RIFooter_followUsText__26KI8 {
  font-size: 22px;
}
.RIFooter_haedingName__HFL00{
  font-weight: bold;
}

.RIFooter_sitemapText__3rAtc{
  text-decoration: none;
  color: white;
}
.RIFooter_sitemapText__3rAtc:hover{
  text-decoration: none;
  color: #1778f2;
}

@media screen and (min-width: 1023px) {
  .RIFooter_web_view__2Wtl- {
    display: flex;
    margin: 0px 130px;
  }

  .RIFooter_web_view_container__2KBpw {
    display: block;
  }
  .RIFooter_mob_view__U91Ca {
    display: none;
  }
  .RIFooter_aboutUs_list_web__AmO9O {
    text-align: left;
    margin: 30px 0px;
    width: 33%;
  }
  .RIFooter_social_icons_web__3w8ku {
    display: flex;
    width: 336px;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
  }

  .RIFooter_banking_partners__3iZMB {
    border-top: 1px groove gray;
    display: flex;
    justify-content: center;
  }
 
  .RIFooter_riLogo_web__6aLKb {

    cursor: pointer;
  }
  .RIFooter_footer_bottom__3u4FA {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 130px;
  }
}
.RIFooter_icons_web_Link__u5iXJ {
  text-decoration: none;
  color: white;
}

/* @media screen and (min-width: 500px) {
  .footer_aboutUs {
    width: 52%;
  }
  .footer_contact {
    width: 55%;
  }
 

  .LoaderCenter{
    text-align: center;
  }
  .msgbtmcenter{
    text-align: center;
  }
 
} */

.ErrorPage_MainContainer__2NMNN {
  display: flex;
  flex-direction: column;
  padding: 12px;
}
.ErrorPage_BgBox_Image__145NM {
  width: 100%;
  margin: 20px 0px;
}
.ErrorPage_Message__OBKtF {
  font-size: 18px;
  font-weight: 500;
  padding-left: 20px;
}
.ErrorPage_Front_Box__7mmJP {
  margin: auto;
}
.ErrorPage_Btn_Box__2Epc_ {
  display: flex;
  justify-content: center;
  grid-gap: 12px;
  gap: 12px;
  margin-top: 15px;
}
.ErrorPage_Btn_SearchJobs__wqzqm {
  width: 175px;
  border-radius: 5px;
  padding: 8px;
  color: #ffff;
  font-weight: 500;
  text-align: center;
  background-color: #193101;
  cursor: pointer;
}
.ErrorPage_Btn_Home__23kEp {
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 8px;
  color: #ffff;
  font-weight: 500;
  text-align: center;
  background-color: #2c1262;
  cursor: pointer;
}
.ErrorPage_LinkUrl_Btn__duM2c {
  text-decoration: none;
}
.ErrorPage_ErrorHeadingMain__32WNp {
  font-size: 50px;
  font-weight: 600;
  margin: 0px;
  padding-left: 20px;
}
.ErrorPage_ErrorHeading__2KNba {
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
  padding-left: 20px;
}
@media screen and (min-width: 1024px) {
  .ErrorPage_MainContainer__2NMNN {
    display: flex;
    flex-direction: row;
  }
  .ErrorPage_Btn_Box__2Epc_ {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 50px;
    justify-content: left;
  }
  .ErrorPage_BgBox_Image__145NM {
    width: 50%;
    margin: 0px;
    padding: 30px;
  }
  .ErrorPage_Message__OBKtF {
    font-size: 22px;
    font-weight: 600;
    text-align: left;
    padding-left: 0px;
  }
  .ErrorPage_ErrorHeadingMain__32WNp {
    font-size: 70px;
    font-weight: 600;
    margin: 0px;
    padding-left: 0px;
  }
  .ErrorPage_ErrorHeading__2KNba {
    font-size: 30px;
    font-weight: 500;
    margin: 0px;
    padding-left: 0px;
  }
  .ErrorPage_Front_Box__7mmJP {
    margin: auto;
    margin-left: -60px;
  }

  .ErrorPage_Btn_Home__23kEp {
    width: 150px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: center;
    padding: 8px;
    color: #ffff;
    font-weight: 500;
    text-align: center;
    background-color: #2c1262;
    cursor: pointer;
  }
  .ErrorPage_Btn_SearchJobs__wqzqm {
    width: 190px;
    text-align: center;
  }
}

.FreeJobAlert_BgdivTop__3Q6jO {
  padding: 0px 10px;
  background: linear-gradient(180deg, #ffffff, #f3c9ae);
}

.FreeJobAlert_HeadingSecond__2Qh8r {
  margin-top: 29px;
  text-align: center;
  margin-bottom: 9px;
  font-size: 18px;
  font-weight: 700;
}

.FreeJobAlert_headThird__1Uu7A {
  font-size: 20px;
  padding: 20px;
}

.FreeJobAlert_checkB__2SZbT {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.FreeJobAlert_Text__3thRl {
  margin-top: 25px;
  padding-bottom: 20px;
  text-align: center;
}

.FreeJobAlert_newsletter__2CLbF {
  display: flex;
  width: 100%;
  justify-content: center;
}

.FreeJobAlert_headThird__1Uu7A {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.FreeJobAlert_Hrline__PCU27 {
  width: 112px;
  border-bottom: 2px solid black;
}

.FreeJobAlert_chipsCont__31lxC {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: space-around;
}
.FreeJobAlert_chips__2iB6u {
  padding: 5px 10px;
  border-radius: 50px;
  display: inline-flex;
  margin: 5px;
  background: #dddddd;
  color: #616161;
  font-size: 14px;
  cursor: pointer;
}
.FreeJobAlert_chips__2iB6u:hover{
  background-color: #1778f2;
  color: #fff;
}
.FreeJobAlert_emailInput__24VIf {
  padding-left: 10px;
}

.FreeJobAlert_Btn_Button__YzfDT {
  width: 100px;
  padding: 6px 8px;
  text-align: center;
  font-weight: 500;
  background-color: #ffbb01;

  color: #000000;
}

.FreeJobAlert_rjBox__3Rz8t {
  margin-top: 30px;
}

.FreeJobAlert_remoteContMain__1c175 {
  margin: 10px 0px 35px 12px;
}

.FreeJobAlert_remCircleFirst__3tIIy,
.FreeJobAlert_remCircleSec__DPjJz,
.FreeJobAlert_remCircleThird__wrUOO {
  border-radius: 50%;
  background-color: #ececec;
  height: 50pt;
  width: 48pt;
}

.FreeJobAlert_HrlineRemFirst__3cycl,
.FreeJobAlert_HrlineRemSec__1fm9g,
.FreeJobAlert_HrlineRemThird__3PtXj {
  width: 112px;
  margin-left: 30px;
}

.FreeJobAlert_HrlineRemFirst__3cycl {
  border-bottom: 3px solid #ffbfbf;
}

.FreeJobAlert_HrlineRemSec__1fm9g {
  border-bottom: 3px solid #b0fef8;
}

.FreeJobAlert_HrlineRemThird__3PtXj {
  border-bottom: 3px solid #dcc6f5;
}

.FreeJobAlert_remTitle__3DdCf {
  padding-left: 30px;
  padding-top: 10px;
  font-size: 15px;
  font-weight: 500;
  width: 110px;
}

.FreeJobAlert_HrTitle__1W5YS {
  margin-top: 5px;
  margin-left: 62px;
  width: 250px;
}

/*...................right-job................*/
.FreeJobAlert_workTypeColor__2uJAi {
  display: flex;
  margin: 7px;
}
.FreeJobAlert_workType__1FcDi {
  padding-top: 8px;
}
.FreeJobAlert_ImgCont__1hOES {
  border-radius: 10px;
  background-color: #ffffff;
  margin-right: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
}
.FreeJobAlert_title__MlqDi {
  font-size: 15pt;
  font-weight: 500;
}
.FreeJobAlert_detailHead__2T7Ml {
  font-weight: 500;
  font-size: 14px;
}
.FreeJobAlert_detail__35Uyu {
  font-size: 14px;
  font-weight: 400;
}

.FreeJobAlert_One-Time__7tqE1,
.FreeJobAlert_Monthly-Basis__2cXn8,
.FreeJobAlert_Contract-Work__3BrtW,
.FreeJobAlert_On-Commission__ukznn {
  font-weight: 500;
}
.FreeJobAlert_One-Time__7tqE1 {
  color: #0c48c1;
}
.FreeJobAlert_Monthly-Basis__2cXn8 {
  color: #005131;
}
.FreeJobAlert_Contract-Work__3BrtW {
  color: #076009;
}
.FreeJobAlert_On-Commission__ukznn {
  color: #e8b200;
}

.FreeJobAlert_One__1xGkE {
  background-color: #fff3f3;
}
.FreeJobAlert_Monthly__RcU5Z {
  background-color: #edffdc;
}
.FreeJobAlert_Contract__1LENM {
  background-color: #dffcff;
}
.FreeJobAlert_OnCommission__3M7fu {
  background-color: #e2ecff;
}

.FreeJobAlert_One__1xGkE,
.FreeJobAlert_Monthly__RcU5Z,
.FreeJobAlert_Contract__1LENM,
.FreeJobAlert_OnCommission__3M7fu {
  padding: 5px;
  border-radius: 8px;
}

.FreeJobAlert_TopTitle__35Lxv {
  text-align: center;
  margin-top: 16px;
}

.FreeJobAlert_ActionBtnContainer__1nS2w {
  display: flex;
  align-items: center;
}

.FreeJobAlert_InputBox__2PgRN {
  width: 70%;
  padding-top: 2px;
  margin-bottom: 0px;
}
.FreeJobAlert_ErrorMessage__3GOrr {
  color: #f22;
  margin-bottom: 20px;
}

.FreeJobAlert_SubmitBtn_JobAlert__3nXMZ {
  margin-top: 12px;
}
.FreeJobAlert_LoadingBtn__1jIj- {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  width: 55%;
  border: none;
}

.FreeJobAlert_ActionBtnContainerTop__1Nglm {
  display: flex;
  flex-direction: column;
}
.FreeJobAlert_skillsLink__1zevW{
  text-decoration: none !important;
}
@media screen and (min-width: 1024px) {
  .FreeJobAlert_SubmitBtn_JobAlert__3nXMZ {
    margin-top: 15px;
  }
  .FreeJobAlert_ActionBtnContainer__1nS2w {
    width: 115%;
  }
  
  .FreeJobAlert_ActionBtnContainerTop__1Nglm {
    width: 50%;
    margin: 0 auto;
  }

  .FreeJobAlert_InputBox__2PgRN {
    margin-top: 4px;
    width: 75%;
  }
  .FreeJobAlert_rjBox__3Rz8t {
    display: flex;
    justify-content: space-between;
    padding: 9px;
    width: 80%;
    grid-gap: 10px;
    gap: 10px;
    margin-left: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
    margin: 40px auto;
  }
  .FreeJobAlert_remoteContMain__1c175 {
    margin: auto;
    width: 100%;
  }

  .FreeJobAlert_checkB__2SZbT {
    display: flex;
    justify-content: space-between;
    width: 33%;
    margin: auto;
  }

  .FreeJobAlert_Rightjob_flex__15BEZ {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80%;
    margin: auto;
  }
  .FreeJobAlert_One__1xGkE,
  .FreeJobAlert_Monthly__RcU5Z,
  .FreeJobAlert_Contract__1LENM,
  .FreeJobAlert_OnCommission__3M7fu {
    padding: 5px;
    width: 45%;
    border-radius: 8px;
  }

  .FreeJobAlert_newsletter__2CLbF {
    width: 50%;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .FreeJobAlert_TopTitle__35Lxv {
    text-align: center;
    margin-top: 60px;
  }

  .FreeJobAlert_Text__3thRl {
    margin-top: 42px;

    width: 55%;
    margin: auto;
    padding-bottom: 40px;
  }
  .FreeJobAlert_HrTitle__1W5YS {
    width: 300px;
    margin-left: 62px;
  }

  .FreeJobAlert_chipsCont__31lxC {
    justify-content: center;
    margin-top: 40px;
    width: 90%;
  }
  .FreeJobAlert_HeadingSecond__2Qh8r {
    margin-top: 35px;
    margin-bottom: 30px;
    font-size: 22px;
  }
  .FreeJobAlert_chips__2iB6u {
    font-size: 16px;
  }
}

