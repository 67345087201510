.para_text_container {
  margin: 0px 15px;
}

.para_text {
  margin: 10px 0px 30px 0px;
  font-size: 16px;
}

.theFutureOfWork {
  color: gray;
  font-size: 20px;
}
.yellowbar {
  display: flex;
  flex-direction: column;
  padding: 22px 22px 0px;
  align-items: center;
  background-color: #f2c910ef;
}
.yellowtitle {
  font-size: 22px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  text-align: center;
}

.para_text_mid {
  font-size: 16px;
  margin: 10px;
  margin-left: 0px;
  color: #3f3f3f;
  font-weight: 500;
}

.midques {
  font-weight: 600;
  color: #0c3568;
}
.futurecharter {
  color: rgb(211, 5, 5);
  font-size: 20px;
  font-weight: 600;
}

.applybtn {
  height: 10px;
}

.bluebar {
  display: flex;
  flex-direction: column;
  padding: 22px 22px 0px;
  align-items: center;
  background-color: #2c3333;
}

.bluetitle {
  font-size: 22px;
  font-weight: 600;
  display: flex;
  color: #fff;
  justify-content: center;
  text-align: center;
}
.imgFirstBloc {
  margin: 14px auto;
}
.bluehead {
  background-color: #00233e;
  height: 300px;
}
.greyhead {
  background-color: #e6e6e6;
  height: 570px;
}
.headBg {
  position: relative;
}
.headImg {
  width: 90%;
  height: 65%;
  left: 3%;
  padding: 10px;
  top: 3%;
  position: absolute;
  display: block;
  text-align: center;
}

.swpname {
  color: #a70606;
  font-weight: 600;
  font-size: 26px;
  text-align: center;
  padding-top: 10px;
}
.swpdetailhead {
  color: #201f1f;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
.logo {
  margin: 20px auto 0px;
  padding: 4px;
}
.headImgphotoWeb {
  display: none;
}
.headImgphotoMob {
  display: block;
}
.bgYllow {
  background-color: yellow;
}
.green {
  width: 30%;
  background-color: green;
}
.logoweb {
  display: none;
}
.bnnrDetailsHead {
  display: none;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
}

.modal {
  background-color: white;
  margin: auto;
  position: relative;
  font-weight: 500;
  top: -25px;
  width: 84%;
  padding: 20px 0px;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
  z-index: 30;
}
.closeModal {
  position: absolute;
  top: 4px;
  right: 10px;
  padding: 6px;
}
.Message {
  display: block;
  font-size: 15px;
  padding: 10px 12px 20px 12px;
  text-align: center;
  font-weight: 400;
  color: #000;
}
.LoaderCenter {
  text-align: center;
}
.msgbtmcenter {
  text-align: center;
}
.title {
  display: block;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  margin-top: 9px;
  color: #000;
}

.paraContainer {
  padding: 10px 0px 10px 20px;
}
.imgContainer {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
.imgParaContainer {
  width: 100%;
}
.lastParaContainer {
  padding-top: 20px;
}
@media screen and (min-width: 1023px) {
  .para_text_container {
    margin: 0px 30px;
    padding: 30px;
  }

  .yellowbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 22px;
  }
  .bluebar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 22px;
  }
  .imgFirstBloc {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .colorBoxBg {
    display: flex;
    flex-direction: row;
    background-color: #e6e6e6;
  }
  .bluehead {
    height: auto;
    width: 260px;
  }
  .headImg {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .logoweb {
    display: block;

    padding-top: 10px;
  }

  .logo {
    display: none;
  }

  .headImgphotoWeb {
    display: block;
    margin-right: 20px;
  }
  .headImgphotoMob {
    display: none;
  }
  .swpname,
  .swpdetailhead {
    text-align: start;
  }

  .redStripe {
    background-color: #a70606;
    height: 100px;
    width: 30px;
    margin-right: 5px;
  }
  .redstripeBox {
    display: flex;
  }

  .redstripeBoxOuter {
    display: flex;
    margin-top: 15px;
  }
  .redstripeText {
    font-size: 17px;

    text-align: start;
  }
  .bnnrparaDetails {
    font-size: 18px;
    font-weight: 500;
    color: #201f1f;
    text-align: start;
  }
  .swpname {
    font-size: 40px;
  }
  .swpdetailhead {
    font-size: 26px;
    color: #201f1f;
    font-weight: 700;
  }
  .bnnrDetailsHead {
    display: block;
  }
  .modal {
    background-color: white;
    margin: auto;
    position: relative;
    font-weight: 500;
    top: -25px;
    width: 30%;
    padding: 23px 0px;
    border-radius: 14px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
    z-index: 30;
  }
  .Message {
    padding: 12px;
    font-size: 20px;
    color: #000;
  }

  .title {
    display: block;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    margin-top: 18px;
    color: #000;
  }
  .imgContainer {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    align-items: center;
    justify-content: space-between;
  }
  .imgParaContainer {
    width: 72%;
  }
}
