.mainContainer{
    margin-bottom: 10%;
}

.titleSkill{
    font-size: 16px;
    font-weight: 600;
}
.threeDots{
    cursor: pointer;
}
.reqSection{
    padding: 20px;
    padding-left: 30px;
    padding-right: 30px;
}
.dateAndStatusContainer{
    margin-top: 10px;
    align-items: center;
    display: flex;

    
    
}
.dateArea{
    font-size: 12px;
    color:#878787 ;
    margin-left: 10px;
    font-weight: 600

}
.newTag{
    padding: 2px 10px;
    border-radius: 10px;
    background-color: #3E70B4;
    width: fit-content;
    font-weight: 600;
    color: white;
    font-size: 12px;
}

.mainReqPoints{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10%;

}
.reqPointsContainer{

}

.priceFont{
    font-size:14px;
    font-weight: 600;
}
.priceType{
    font-size: 12px;
    color:#878787 ;
    font-weight: 600;
}

.shortDesc{
    margin-top: 10%;
    font-size: 14px;
}

.skillTags{
    padding: 3px 10px;
    background-color: #E3ECE4;
    font-size: 12px;
    width: -moz-fit-content;
    /* width: fit-content; */
    border-radius: 10px;
    margin: 5px;
    font-weight: 500;
}
.skillTagContainer{
    display: flex;
    margin-top: 5%;

 
}
.buttonContainer{
    margin-top: 20px;
}
.greenButton{
    width: 70%;
    background-color: #4EA656;
    padding: 3px 5px;
    color: white; 
    text-align: center;
    border-radius: 25px;
   font-weight: 500;
    margin: auto;
}
.borderGreen{
    height: 1px;

    background-color: #4EA656;
    margin-top: 40px;
}


.moreJobsButton{
    padding: 5px 10px;
    border: 1px solid #4EA656;
    border-radius: 25px;
    text-align: center;
    width: 80%;
    margin: auto;
    font-size: 16px;
    font-weight: 600; 
    
}

.skilltagsContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5%;
  }
.moreJobsButton:hover{
    background-color:#4EA656 ;
    color: white;
}
