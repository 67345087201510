.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
}

.modal {
  background-color: white;
  margin: auto;
  position: relative;
  font-weight: 500;
  width: 450px;
  padding: 40px 20px;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
  z-index: 30;
}
.button {
  width: 70px;
  color: #f02f39;
  padding: 2px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.ActionBtn {
  display: flex;
  justify-content: space-around;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.ModalCheckIcon {
  text-align: center;
}
.ModalCheckTextFirst {
  text-align: center;
  padding-top: 16px;
  font-size: 14px;
  font-weight: bold;
}
.ModalCheckTextSecond {
  text-align: center;
  padding-top: 16px;
  font-size: 16px;
  font-weight: bold;
}
.modalcheck {
  height: 60px;
  width: 60px;
  margin-top: -90px;
  border: 1px solid;
  padding: 20px;
  border-radius: 50%;
  background-color: #0c2551;
  color: #fff;
  border-color: #0c2551;
}
.ModalCheckIconText {
  text-align: center;
  font-weight: bold;
}
@media screen and (max-width:768px) {
  .ActionBtn {
   
   display: flex;
   
   width: 100%;
   margin: auto;
  }
  .ActionBtnContainer{
     width: 100%;
     margin: 10px;
  }
  
}