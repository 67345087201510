.Notification_maindiv {
  width: 100%;
  background: #fff;
}
.Notification_flex {
  display: flex;
  font-size: 20px;
  padding: 30px 0px 5px 0px;
  border-bottom: 1px solid lightgrey;
}

.icon {
  margin-right: 20px;
  margin-top: 5px;
}
.Notification_heading {
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  background-color: white;
  margin: 10px 0px;


}
.Notification_container {
  width: 85%;
  margin: auto;
}
.Notification_time {
  font-size: 12px;
  margin-bottom: 10px;
}
.blank_Notification_div {
  text-align: center;
  padding-top: 35vh;
  height: 350px;
}
.blank_Notification_heading {
  font-size: 20px;
  font-weight: bold;
}
.blank_Notification_text {
  font-size: 12px;
  margin: auto;
}

/* .blank_Notification_img {
  width: 20%;
} */
.notification_text {
  font-size: 15px;
}
.notification_textbold {
  font-size: 15px ;
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  /* .blank_Notification_img {
    width: 10%;
  } */
  .notification_text {
    font-size: 18px;
  }
  .notification_textbold {
    font-size: 18px ;
    font-weight: bold;
    
  }
  
}
