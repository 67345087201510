.Joinpage_header {
  width: 90%;
  margin: 40px auto;
  border-width: 0px;
  border-color: rgb(223, 223, 223);
  border-style: solid;
  background-color: #fff;
}

.heading {
  font-size: 25px;
  font-weight: bold;
  margin: 40px 15px 20px 15px;
  text-align: center;
}

.client_join {
  border: 1px solid lightgrey;
  background-color: #f6f6f6;
  padding: 10px;
  border-radius: 5px;
  margin: 15px;
  cursor: pointer;
}
.client_joincolor {
  border: 1px solid lightgrey;
  background-color: #fbfdff;
  padding: 10px;
  border-radius: 5px;
  margin: 15px;
  cursor: pointer;
}

.freelancer_join {
  border: 1px solid grey;
}

.Joinpage_input {
  float: right;
  cursor: pointer;
}

.Joinpage_flex {
  display: flex;
  justify-content: left;
  padding: 23px 5px;
  align-items: center;
}

.Joinpage_text {
  padding: 0px;
  font-size: 15px;
}
.Joinpage_imgfreelancer {
  width: 75%;
  padding-top: 7px;
}

.Joinpage_img {
  width: 75%;
  padding-top: 7px;
}

.ActionButton {
  text-align: center;
  padding-bottom: 10px;
}
.ActionButton_disable {
  text-align: center;
  padding-bottom: 10px;
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
.login {
  color: #1778f2;
  cursor: pointer;
}
.last_text {
  text-align: center;
  font-size: 15px;
  padding-bottom: 30px;
}


@media screen and (min-width: 1024px) {
  .displey_flex {
    display: flex;
    justify-content: center;
  }
  .mainContainer {
    background-color: rgb(223, 223, 223);
    padding-top: 30px;
    padding-bottom: 50px;
  }
  .client_join {
    width: 32%;
    height: 20%;
    cursor: pointer;
  }
  .client_joincolor {
    width: 32%;
    height: 20%;
    cursor: pointer;
  }

  .Joinpage_flex {
    display: block;
    text-align: center;
  }

  .Joinpage_img {
    width: 24%;
    padding-top: 5px;
    padding-bottom: 2px;
  }
  .Joinpage_imgfreelancer {
    width: 25%;
    padding-top: 7px;
    padding-bottom: 10px;
  }
  .Joinpage_header {
    width: 60%;
    margin: 40px auto;
    border-width: 1px;
    border-color: rgb(223, 223, 223);
    border-style: solid;
  }
 
}
