.MainContainer {
  display: flex;
  flex-direction: column;
  padding: 12px;
}
.BgBox_Image {
  width: 100%;
  margin: 20px 0px;
}
.Message {
  font-size: 18px;
  font-weight: 500;
  padding-left: 20px;
}
.Front_Box {
  margin: auto;
}
.Btn_Box {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 15px;
}
.Btn_SearchJobs {
  width: 175px;
  border-radius: 5px;
  padding: 8px;
  color: #ffff;
  font-weight: 500;
  text-align: center;
  background-color: #193101;
  cursor: pointer;
}
.Btn_Home {
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 8px;
  color: #ffff;
  font-weight: 500;
  text-align: center;
  background-color: #2c1262;
  cursor: pointer;
}
.LinkUrl_Btn {
  text-decoration: none;
}
.ErrorHeadingMain {
  font-size: 50px;
  font-weight: 600;
  margin: 0px;
  padding-left: 20px;
}
.ErrorHeading {
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
  padding-left: 20px;
}
@media screen and (min-width: 1024px) {
  .MainContainer {
    display: flex;
    flex-direction: row;
  }
  .Btn_Box {
    display: flex;
    gap: 20px;
    margin-top: 50px;
    justify-content: left;
  }
  .BgBox_Image {
    width: 50%;
    margin: 0px;
    padding: 30px;
  }
  .Message {
    font-size: 22px;
    font-weight: 600;
    text-align: left;
    padding-left: 0px;
  }
  .ErrorHeadingMain {
    font-size: 70px;
    font-weight: 600;
    margin: 0px;
    padding-left: 0px;
  }
  .ErrorHeading {
    font-size: 30px;
    font-weight: 500;
    margin: 0px;
    padding-left: 0px;
  }
  .Front_Box {
    margin: auto;
    margin-left: -60px;
  }

  .Btn_Home {
    width: 150px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: center;
    padding: 8px;
    color: #ffff;
    font-weight: 500;
    text-align: center;
    background-color: #2c1262;
    cursor: pointer;
  }
  .Btn_SearchJobs {
    width: 190px;
    text-align: center;
  }
}
