.mainContainer {
  padding: 20px;
}
.formContainer{
  margin-top: 18px;
}
.pageLoader {
  text-align: center;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.centerMain {
  padding: 20px;
  padding-top: 0px;
}
.Heading {
  color: #0c2551;
}
.input_container {
  margin-top: 25px;
}
.input_fields {
  display: flex;
  flex-direction: column;
}
.label {
  margin-top: 15px;
  font-weight: 800;
  color: #292e42;
}
.input {
  padding: 12px;
  border: none;
  outline: none;
  margin: 7px 0px;
}
.lableDesign {
  margin: 15px 0px 0px 0px;
  font-weight: 500;
  align-items: center;
  color: #000;
  display: flex;
}
.lablecompany {
  margin: 13px 0px;
  font-weight: 500;
  color: #000;
  display: flex;
}
.error_message {
  color: rgb(250, 0, 0);
  display: flex;
  align-items: center;
}
.NumberStyle {
  text-align: right;
  padding: 0px 3px;
  font-size: 14px;
}
.Icon {
  padding: 8px 0px 2px 0px;
}

.form_upload_label {
  cursor: pointer;
}
.PopUpText {
  font-size: 12px;
  color: rgb(71, 70, 70);
}
.uploadIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.LoadingBtn {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 10px 10px;
  width: 55%;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
}
.uploadContainer {
  display: flex;
}
.editIcon {
  padding: 8px 0px 0px 20px;
  height: 25px;
  cursor: pointer;
  width: 25px;
  color: rgb(16, 40, 71);
}
.inputArea_container {
  width: 50%;
}
.Logo_image {
  height: 30px;
  width: 30px;
}
.selectoption {
  padding-left: 3px;
}
.RadioOne {
  border: 1px solid #ece9e9;
  padding: 8px;
  margin-top: 10px;
}
.RadioOne:focus {
  box-shadow: 0.5px 0.5px 0.5px 0.5px #d1d0d0;
}
.RadioTwo {
  border: 1px solid #ece9e9;
  padding: 8px;
  margin-top: 8px;
}
.RadioTwo:focus {
  box-shadow: 0.5px 0.5px 0.5px 0.5px #d1d0d0;
}
.TextInputRadioSelected {
  margin-top: 24px;
}
.PARBtn_Link {
  text-decoration: none;
}
.ActionBtn {
  display: flex;
  justify-content: center;
}
.Heading_text {
  font-size: 30px;
  font-weight: bold;
}

.para_text {
  margin: 4px 1px 30px 1px;
}

.progressBarMargin {
  margin-top: 30px;
  margin-bottom: 30px;
}

.HeadingText {
  margin: 10px 0px;
}
.back_button {
  background: #fff;
  border: none;
  padding-left: 15px;
  margin-bottom: 20px;
  cursor: pointer;
}
.closeIcon {
  cursor: pointer;
}
.inputArea {
  padding: 10px 10px;
  outline: none;
  border: 1px solid #ece9e9;
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
  font-size: 18px;
}
.inputfilds1 {
  cursor: pointer;
}
.ImageInput {
  cursor: pointer;
}
.relatedSkillBox {
  border-width: 1px;
  border-radius: 5px;
  border-color: rgb(223, 223, 223);
  padding: 5px 10px;
  border-style: solid;
  width: fit-content;
  margin: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.relatedSkillBox:hover {
  cursor: pointer;
  background-color: rgb(0, 0, 0);
  color: white;
}
.relatedSkillContainer {
  display: flex;
  flex-wrap: wrap;
  max-height: 200px;
  overflow: scroll;
}
.relatedSkillHeading {
  font-size: 18px;
}
.addIcon {
  margin-left: 5px;
}
.htmlEditor{
  border: 1px solid #ece9e9 ;
  margin: 10px 0;
}
.htmlEditorFocus{
  border: 1px solid #1778f2 ;
  margin: 10px 0;
}
@media screen and (min-width: 1023px) {
  .Maincontainer_border {
    padding: 0px 0px;
    width: 40%;
    background-color: white;
    margin-top: 20px;
  }

  .webImageContainer {
    display: flex;
    justify-content: center;

    background-color: #f5f5f5;
    padding-top: 20px;
    padding-bottom: 50px;
  }

  .webImageArea {
    display: flex;
    flex-direction: column;
    background-color: #1678f2;
    height: auto;
    width: 40%;
    margin-top: 20px;
  }

  .Heading_text_Web {
    margin: 30px;
    font-size: 50px;
    font-weight: bold;
    color: white;
  }

  .para_text_Web {
    margin-left: 30px;
    font-size: 50px;
    font-size: 30px;
    color: white;
  }

  .Heading_text {
    display: none;
  }
  .para_text {
    display: none;
  }
}
