.mainContainer {
  margin: 10px;
  padding: 20px;
  height: fit-content;

  padding-left: 30px;
  background-color: white;
}
.mainHeading {
  font-weight: bold;
  font-size: 18px;
}
.profilesContainer {
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.profileImageDisplay {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: whitesmoke;

  align-items: center;
  display: inline-grid;
}
.eachProfileSet {
  margin: 10px 50px;
  margin-top: 20px;

  text-align: center;
}
.name {
  font-weight: bold;
  margin-top: 10px;
}
.skills {
  font-size: 16px;
}

.connectButton {
  color: #1678f2;
  font-weight: bold;
  font-size: 12px;
  margin-top: 5px;
  cursor: pointer;
}
.hirebutton {
  padding: 5px 10px;

  background-color: green;
  color: white;

  margin-top: 10px;
}

.noApplication {

  text-align: center;

}
.contentAndIcon {
  margin: auto;
  margin-top: 30px;
  text-align: center;
}
.iconArea {
  height: 50px;
  width: 50px;
  
}
.Image_Profile {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  cursor: pointer;
}
@media (min-width: 768px) {
  .profilesContainer {
    display: flex;
  }
}
