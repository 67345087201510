.BulkHiringDesc {
  margin: 2rem 1rem 2rem 1rem;
}
.bulkhiringHeading {
  font-weight: bold;
  font-size: 25px;
  width: fit-content;
  margin: auto;
  padding: 20px 12px;
  text-align: center;
}

.bulkHiringlookingFor {
  margin-left: 1rem;
  margin-bottom: 2rem;
}
.bulkHiringBold {
  font-weight: bold;
  color: rgb(0 50 86);
  font-size: 30px;
  margin: 0px;
}
.BulkHiringbutton {
  padding: 10px;
  margin-top: 30px;
  background: rgb(0 50 86);
  border: none;
  font-weight: bold;
  font-size: 16px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.bannerImage {
  display: block;
}
.bannerImageWeb {
  display: none;
}
.BulkHiringbannerImage {
  display: block;
}
.BulkHiringbannerImageWeb {
  display: none;
}

.bannerTextAndImage {
  position: relative;
  color: white;
}

.bannerText {
  position: absolute;
  top: 40px;
  left: 16px;
}
.textHeadingBold {
  font-weight: bold;
  font-size: 26px;
  margin-bottom: 10px;
  color: #fff;
 
}
.skillsFlex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 4rem 1rem 0rem 1rem;
}
.skills {
  width: 40%;
  text-align: center;
  margin-bottom: 7rem;
  cursor: pointer;
}
.list {
  font-weight: bold;
}

.requirementButton {
  cursor: pointer;
  color: rgb(0 50 86);
  background: WHITE;
  border: none;
  outline: none;
  border-radius: 3px;
  padding: 7px 15px;
  font-size: 16px;
  margin-top: 30px;
  font-weight: bold;
}
.solutionIcons {
  font-size: 80px;
}
.textHeading {
  width: 70%;
  color: #fff;
}
@media screen and (min-width: 1023px) {
  .skillsFlex {
    margin: 5rem 8rem 5rem 8rem;
  }
  .skills {
    width: 33%;
  }
  .bulkhiringHeading {
    font-size: 40px;
    margin-bottom: 2rem;
    width: fit-content;
    text-align: center;
  }
  .BulkHiringDesc {
    margin: 2rem 3rem 2rem 4rem;
    font-size: 17px;
  }
  .flex {
    display: flex;
    flex-direction: row-reverse;
  }
  .bulkHiringlookingFor {
    width: 50%;
    padding-top: 3rem;
    padding-left: 40px;
  }
  .bulkHiringBold {
    font-size: 55px;
  }
  .BulkHiringbutton {
    padding: 20px;
    font-size: 20px;
  }

  .bannerText {
    top: 110px;
    left: 60px;
  }
  .textHeadingBold {
    font-size: 50px;
    color: #fff;
  }
  .textHeading {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .requirementButton {
    font-size: 25px;
  }
  .BulkHiringbannerImageWeb {
    display: block;
  }
  .BulkHiringbannerImage {
    display: none;
  }
  .bannerImage {
    display: none;
  }
  .bannerImageWeb {
    display: block;
  }
}
