.BgdivTop {
  padding: 0px 10px;
  background: linear-gradient(180deg, #ffffff, #f3c9ae);
}

.HeadingSecond {
  margin-top: 29px;
  text-align: center;
  margin-bottom: 9px;
  font-size: 18px;
  font-weight: 700;
}

.headThird {
  font-size: 20px;
  padding: 20px;
}

.checkB {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Text {
  margin-top: 25px;
  padding-bottom: 20px;
  text-align: center;
}

.newsletter {
  display: flex;
  width: 100%;
  justify-content: center;
}

.headThird {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Hrline {
  width: 112px;
  border-bottom: 2px solid black;
}

.chipsCont {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: space-around;
}
.chips {
  padding: 5px 10px;
  border-radius: 50px;
  display: inline-flex;
  margin: 5px;
  background: #dddddd;
  color: #616161;
  font-size: 14px;
  cursor: pointer;
}
.chips:hover{
  background-color: #1778f2;
  color: #fff;
}
.emailInput {
  padding-left: 10px;
}

.Btn_Button {
  width: 100px;
  padding: 6px 8px;
  text-align: center;
  font-weight: 500;
  background-color: #ffbb01;

  color: #000000;
}

.rjBox {
  margin-top: 30px;
}

.remoteContMain {
  margin: 10px 0px 35px 12px;
}

.remCircleFirst,
.remCircleSec,
.remCircleThird {
  border-radius: 50%;
  background-color: #ececec;
  height: 50pt;
  width: 48pt;
}

.HrlineRemFirst,
.HrlineRemSec,
.HrlineRemThird {
  width: 112px;
  margin-left: 30px;
}

.HrlineRemFirst {
  border-bottom: 3px solid #ffbfbf;
}

.HrlineRemSec {
  border-bottom: 3px solid #b0fef8;
}

.HrlineRemThird {
  border-bottom: 3px solid #dcc6f5;
}

.remTitle {
  padding-left: 30px;
  padding-top: 10px;
  font-size: 15px;
  font-weight: 500;
  width: 110px;
}

.HrTitle {
  margin-top: 5px;
  margin-left: 62px;
  width: 250px;
}

/*...................right-job................*/
.workTypeColor {
  display: flex;
  margin: 7px;
}
.workType {
  padding-top: 8px;
}
.ImgCont {
  border-radius: 10px;
  background-color: #ffffff;
  margin-right: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
}
.title {
  font-size: 15pt;
  font-weight: 500;
}
.detailHead {
  font-weight: 500;
  font-size: 14px;
}
.detail {
  font-size: 14px;
  font-weight: 400;
}

.One-Time,
.Monthly-Basis,
.Contract-Work,
.On-Commission {
  font-weight: 500;
}
.One-Time {
  color: #0c48c1;
}
.Monthly-Basis {
  color: #005131;
}
.Contract-Work {
  color: #076009;
}
.On-Commission {
  color: #e8b200;
}

.One {
  background-color: #fff3f3;
}
.Monthly {
  background-color: #edffdc;
}
.Contract {
  background-color: #dffcff;
}
.OnCommission {
  background-color: #e2ecff;
}

.One,
.Monthly,
.Contract,
.OnCommission {
  padding: 5px;
  border-radius: 8px;
}

.TopTitle {
  text-align: center;
  margin-top: 16px;
}

.ActionBtnContainer {
  display: flex;
  align-items: center;
}

.InputBox {
  width: 70%;
  padding-top: 2px;
  margin-bottom: 0px;
}
.ErrorMessage {
  color: #f22;
  margin-bottom: 20px;
}

.SubmitBtn_JobAlert {
  margin-top: 12px;
}
.LoadingBtn {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  width: 55%;
  border: none;
}

.ActionBtnContainerTop {
  display: flex;
  flex-direction: column;
}
.skillsLink{
  text-decoration: none !important;
}
@media screen and (min-width: 1024px) {
  .SubmitBtn_JobAlert {
    margin-top: 15px;
  }
  .ActionBtnContainer {
    width: 115%;
  }
  
  .ActionBtnContainerTop {
    width: 50%;
    margin: 0 auto;
  }

  .InputBox {
    margin-top: 4px;
    width: 75%;
  }
  .rjBox {
    display: flex;
    justify-content: space-between;
    padding: 9px;
    width: 80%;
    gap: 10px;
    margin-left: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
    margin: 40px auto;
  }
  .remoteContMain {
    margin: auto;
    width: 100%;
  }

  .checkB {
    display: flex;
    justify-content: space-between;
    width: 33%;
    margin: auto;
  }

  .Rightjob_flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80%;
    margin: auto;
  }
  .One,
  .Monthly,
  .Contract,
  .OnCommission {
    padding: 5px;
    width: 45%;
    border-radius: 8px;
  }

  .newsletter {
    width: 50%;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .TopTitle {
    text-align: center;
    margin-top: 60px;
  }

  .Text {
    margin-top: 42px;

    width: 55%;
    margin: auto;
    padding-bottom: 40px;
  }
  .HrTitle {
    width: 300px;
    margin-left: 62px;
  }

  .chipsCont {
    justify-content: center;
    margin-top: 40px;
    width: 90%;
  }
  .HeadingSecond {
    margin-top: 35px;
    margin-bottom: 30px;
    font-size: 22px;
  }
  .chips {
    font-size: 16px;
  }
}
