.pageLayout {
  background-color: #f5f6fb;
}
.pageColumn {
  display: flex;
  justify-content: center;
}
.leftArea {
  width: 70%;
}
.rightArea {
  width: 30%;
}
.pageLoader {
  text-align: center;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.closePostHeading {
  font-size: 24px;
  margin-top: -10px;
}
.closePostButtons {
  width: 220px;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}
.btnContainer {
  padding: 10px 0px 3px 0px;
}
.closePostHeading {
  font-size: 20px;
}
.closePostButtonsConatiner {
  display: flex;
  justify-content: center;
}
@media (min-width: 768px) {
  .closePostButtons {
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
  }
  .btnContainer {
    padding: 20px 0px 10px 0px;
  }
  .closePostButtonsConatiner {
    display: flex;
    justify-content: center;
  }
}
