.mainContainer{
    padding: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10%;
   
}

.heading{
    font-size: 30px;
    font-weight: 600;
  
}

.pointAndIcons{
display: flex;
margin-top: 10%;
}
.icons{
    width: 30px;
    height: 30px;
    margin-right: 10px;
    margin-top: 3px;

}

.pointHeading{
    font-weight: 800;
    font-size: 18px;
}

.pointDesc{
    color:#878787 ;
}