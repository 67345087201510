.HowRiCanHelpContainer {
  padding: 15px;
  text-align: center;
  background-color: #f5f5f5;
  padding-bottom: 25px;
}

.iconContainer {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}
.headingofEachSection {
  font-size: 20px;
  font-weight: bold;
  margin-left: 20px;
  margin-bottom: 10px;
}

.sectionContainer {
  margin-top: 20px;
  padding: 10px;
}

.PARBtn_Link {
  text-decoration: none;
  color: #1e1e1e;
}

.proTip {
  font-weight: 400;
  font-size: 14px;
  font-style: italic;
}

.tipIcon {
  margin-top: 20px;
}

@media screen and (min-width: 768px) {
  .container {
    padding-top: 40px;
    width: 95%;
    align-content: center;
    align-self: center;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
  .HowRiCanHelpContainer {
    padding: 40px;
    margin-bottom: 40px;
    margin-top: 40px;
  
  }
  .sectionContainer {
    width: 25%;
  }

  .headingofEachSection {
    margin-left: 0px;
    font-size: 18px;
    margin-bottom: 20px;
  }
  .iconContainer {
    display: grid;
    align-content: flex-start;
    align-items: center;
  }
  .icon {
    
    display: flex;
    justify-content: center;
    align-self: center;
    text-align: center;
    margin-bottom: 20px;
  }
  .subHeadingWeb{
    height: 100px;
  }
  .tipIcon {
    margin-top: 40px;
  }
}
