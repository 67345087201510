.pageMainContainer {
  padding: 20px 40px;
  background-color: white;
  margin-right: 10px;
}
.formContainerCompany {
  width: 80%;
  margin-top: 40px;
}

.InputContainer {
  width: 80%;
  margin-top: 20px;
}
.formContainer {
  margin-top: 30px;
}
.disabledInput {
  pointer-events: none;
  opacity: 0.4;
}
.inputArea {
  padding: 10px 10px;
  outline: none;
  border: 1px solid #ece9e9;
  margin: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
}
.inputFieldsWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.inputAdressWrapper {
  margin-bottom: 5px;
  margin-top: 5px;
}
.alternateContainer {
  margin-top: -10px;
}
.CheckBoxInputDiv {
  margin-bottom: 10px;
}
.submitbutton {
  width: 100%;
  text-align: center;
}
.inputFields {
  width: 47%;
}
.loaderLabel {
  margin-top: 10px;
}

.AddAlternative {
  display: flex;
  align-items: center;
}
.AddAlternativeOpen {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.addIcon {
  margin-right: 10px;
  height: 18px;
  width: 18px;
  cursor: pointer;
}
.addIcon:hover {
  color: #1678f2;
}
.CheckBoxInput {
  cursor: pointer;
}
.error_message {
  color: rgb(250, 0, 0);
}
.editButton {
  cursor: pointer;
}
.editButton:hover {
  color: #1678f2;
}
.alterNateText {
  cursor: pointer;
}

.personalInfoInput {
  margin-bottom: 20px;
}
.labelWithIcon {
  display: flex;
  align-items: center;
}
::-webkit-calendar-picker-indicator {
  cursor: pointer;
}
.personalInfoInputEdit {
  margin: 25px 0px;
}
.personalInfoInputState {
  margin: 35px 0px;
}



.form_upload_label {
  display: flex;
  height: 120px;
  width: 120px;
  border-radius: 60px;
  cursor: pointer;
  border: 1px solid #ece9e9;
  align-items: center;
  flex-direction: column;
}
.uploadLogoText {
  margin-left: 30px;
}
.updateImage {
  display: flex;
  height: 120px;
  width: 120px;
  border-radius: 60px;
  border: 1px solid #ece9e9;
  align-items: center;
}
.imageEditIcon {
  display: flex;
  height: 30px;
  width: 30px;
  border-radius: 50px;
  cursor: pointer;
  background: white;
  margin-left: -39px;
  border: 1px solid #848484;
  align-items: center;
}
.uploadImagebutton {
  display: flex;
  height: 30px;
  width: 30px;
  border-radius: 50px;
  cursor: pointer;
  background: white;
  margin-left: 84px;
  position: absolute;
  margin-top: -34px;
  border: 1px solid #848484;
}

.uploadContainer {
  display: flex;
  align-items: center;
}
.updatetImageContainer {
  display: flex;
  align-items: end;
}
.uploadIcon {
  cursor: pointer;
  color: gray;
  margin: auto;
}
.uploadImgText {
  font-size: 12px;
  margin-top: -30px;
  text-align: center;
}



.scrollArea {
  background-color: white;
  display: flex;
  padding: 10px 10px 0px 10px;
  margin: 10px 0px;
}

.innerNavigation {
  display: flex;
}
.menuOptionContent {
  font-size: 20px;
  font-weight: bold;
  margin: 0px 15px 0px;
  font-weight: bold;
  color: black;
  text-decoration: none;
  padding-bottom: 8px;
}

.menuOptionContent:hover {
  cursor: pointer;
}
.companyInfoInputEdit {
  margin-top: 20px;
}
.companyInfoInputState {
  margin-top: 20px;
}
.menuOptionSelected {
  font-size: 20px;
  margin: 0px 15px 0px;
  font-weight: bold;
  color: #1678f2;
  text-decoration: none;
  border-bottom-width: 4px;
  border-bottom-color: #1678f2;
  border-bottom-style: solid;
  padding-bottom: 8px;
}
.menuOptionContent:hover {
  cursor: pointer;
}
.pageLoader {
  text-align: center;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.closePostHeading {
  font-size: 24px;
  margin-top: -10px;
}
.closePostButtons {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-top: 16px;
}
.nameHeading {
  font-weight: bold;
  color: #1678f2;
  margin-bottom: 10px;
  font-size: 24px;
  text-transform: capitalize;
}
.socialIcons {
  margin-right: 5px;
  cursor: pointer;
}

@media (max-width: 1023px) {
  .scrollAreaContainer {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .form_upload_label {
    height: 100px;
    width: 100px;
  }
  .personalInfoInput {
    margin-bottom: 10px;
  }
  .uploadImagebutton {
    margin-left: 73px;
    margin-top: -35px;
  }
  .nameHeading {
    font-size: 22px;
  }
  .companyInfoInputEdit {
    margin: 20px 0px;
  }
  .companyInfoInputState {
    margin: 34px 0px;
  }
  .scrollArea {
    display: flex;
    width: 550px !important;
  }
  /* .pageMainContainer {
    width: 80%;
  } */
  .personalInfoInputEdit {
    margin: 20px 0px;
  }
  .personalInfoInputState {
    margin: 32px 0px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .AddAlternative {
    margin-bottom: 15px;
  }
}