.mainContainer {
  padding: 15px;
  background-color: white;
  margin: 10px;
}

.headingFromProps {
  padding: 20px;
  font-size: 20px;
  font-weight: bold;
}

.pageLoader {
  text-align: center;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainCardContainer {
  width: 90%;

  padding: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(219, 219, 219);
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}
.imagecontainer {
  width: 90px;
  background-color: rgb(219, 219, 219);
}
.workType {
  color: indigo;
  font-weight: bolder;
  margin-left: 10px;
}



.skillImageContainer {
  display: flex;
  height: 40%;
}
.skillContainer {
  font-size: 18px;
  font-weight: bold;
  color: #000;
}
.compnayLocationDate{
  color: #000;
}
.titleArea {
  margin-top: 10px;
  color: grey;
}
.workStatus {
  background-color: #d6ffe3;
  border-radius: 5px;
  padding: 5px;

  font-weight: bold;
  font-size: 14px;
  color: #43c962;
  width: fit-content;
}

.actionContainer {
  height: 100px;
}
.ActionButton {
  text-align: right;
}
.PARBtn_Link {
  text-decoration: none;
  
}
.postedDate {
  font-style: italic;
  color: grey;
  margin-left: 10px;
  font-size: 12px;
}
.budget {
  font-weight: bold;
  font-size: 22px;
  color: #000;
}

.iconAndButtonContainer {
  display: flex;
  justify-content: right;
  align-content: center;
  align-items: center;
}

.workPolicy {
  padding: 3px;
  background-color: #3db2be;
  color: white;
  border-radius: 3px;
  width: fit-content;
  font-size: 14px;
}
.workPolicyAndWorkType {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.statusAnddate {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.Commission {
  color: #fea11b;
}

.Contract {
  color: #486a31;
}
.One-Time {
  color: #365aa9;
}
.Monthly {
  color: #125552;
}

.knowMore {
  text-align: right;
  color: #9398a1;
  font-weight: 500;
  font-size: 20px;
  padding: 10px;

  align-items: center;
  align-content: center;
  justify-content: flex-end;
  display: flex;
}

.knowMore:hover {
  cursor: pointer;
  color: #1778f2;
}

.iconArrow {
  margin-top: 3px;
}

.heartIcon {
  cursor: pointer;
}
.mainCardContainer:hover {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}
.cardHeightSetting {
  cursor: pointer;
}
.budgetUnit {
  font-size: 14px;
  font-weight: 500;
}
.AnchorTag{
  text-decoration: none;
}
@media screen and (min-width: 1023px) {
  .webLayout {
    display: flex;

    padding-left: 30px;
    padding-right: 30px;
  }
  .mainContainer {
    padding: 0px;
  }
  .mainCardContainer {
    margin-left: 10px;
    margin-right: 10px;
    padding: 15px;
  }
  .cardHeightSetting {
    height: 250px;
  }

  .knowMore {
    margin-right: 3%;
    margin-bottom: 20px;
  }
}
