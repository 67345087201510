.mainContainer {
  margin-bottom: 30px;
}

.progressbar {
  width: 100%;
  background-color: #ededed;
  height: 10px;
}

.progressbarStatus25 {
  background-color: #1678f2;
  width: 25%;
  height: 10px;

  background-image: linear-gradient(to right, #00c9ff, #92fe9d);
}
.progressbarStatus50 {
  background-color: #1678f2;
  width: 50%;
  height: 10px;

  background-image: linear-gradient(to right, #00c9ff, #92fe9d);
}
.progressbarStatus75 {
  background-color: #1678f2;
  width: 75%;
  height: 10px;

  background-image: linear-gradient(to right, #00c9ff, #92fe9d);
}

.progressbarStatus100 {
  background-color: #1678f2;
  width: 100%;
  height: 10px;

  background-image: linear-gradient(to right, #00c9ff, #92fe9d);
}
