.centerMain {
  padding: 20px;
  padding-top: 0px;
}

.back_button {
  background: #fff;
  border: none;
  padding-left: 15px;
  margin-bottom: 20px;
  cursor: pointer;
}

.Heading_text {
  font-size: 30px;
  font-weight: bold;
}

.para_text {
  margin: 4px 1px 30px 1px;
}

.webImageArea {
  display: none;
}

@media screen and (min-width: 1023px) {
  .Maincontainer_border {
    padding: 0px 0px;
    width: 40%;
    background-color: white;
    margin-top: 20px;
  }
  .back_button {
    padding-left: 10px;
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .webPadding {
    padding-left: 20px;
    padding-right: 20px;
  }

  .webImageContainer {
    display: flex;
    justify-content: center;

    background-color: #f5f5f5;
    padding-top: 20px;
    padding-bottom: 50px;
  }

  .webImageArea {
    display: flex;
    flex-direction: column;
    background-color: #1678f2;
    height: auto;
    width: 40%;
    margin-top: 20px;
  }
  .Heading_text_Web {
    margin: 30px;
    font-size: 50px;
    font-weight: bold;
    color: white;
  }
  .Heading_text {
    display: none;
  }
  .para_text {
    display: none;
  }
  .para_text_Web {
    margin-left: 30px;
    font-size: 50px;
    font-size: 30px;
    color: white;
  }
}
