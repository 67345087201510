.centerMain {
  padding: 20px;
  padding-top: 0px;
}
.contentContainer {
  padding: 10px;
  margin-top: 20px;
  border-width: 1px;
  display: flex;
  border-color: grey;

  border-radius: 8px;
  border-style: solid;
}
.lableDesign {
  margin: 15px 0px 0px 0px;
  font-weight: 500;
  color: #000;
  display: flex;
}
.input_container {
  margin-top: 25px;
}
.contentArea {
  margin-left: 10px;
}
.LoadingBtn {
  background-color: #1778f2;
  color: white;
  border-radius: 6px;
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 10px 10px;
  width: 55%;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
}
.headingRequirementType {
  font-weight: bold;
}
.radioButton {
  color: yellow;
}
.PARBtn_Link {
  text-decoration: none;
}
.ActionBtn {
  display: flex;
  justify-content: center;
}
.Heading_text {
  font-size: 30px;
  font-weight: bold;
}
.error_message {
  color: rgb(250, 0, 0);
  display: flex;
  align-items: center;
}
.para_text {
  margin: 4px 1px 30px 1px;
}

.HeadingText {
  margin: 10px 0px;
}
.back_button {
  background: #fff;
  border: none;
  padding-left: 15px;
  margin-bottom: 20px;
  cursor: pointer;
}
.webImageArea {
  display: none;
}

.pageLoader {
  text-align: center;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 1023px) {
  .back_button {
    padding-left: 10px;
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .webPadding {
    padding-left: 20px;
    padding-right: 20px;
  }

  .webImageContainer {
    display: flex;
    justify-content: center;

    background-color: #f5f5f5;
    padding-top: 20px;
    padding-bottom: 50px;
  }

  .webImageArea {
    display: flex;
    flex-direction: column;
    background-color: #1678f2;
    height: auto;
    width: 40%;
    margin-top: 20px;
  }
  .Heading_text_Web {
    margin: 30px;
    font-size: 50px;
    font-weight: bold;
    color: white;
  }
  .Heading_text {
    display: none;
  }
  .para_text {
    display: none;
  }
  .para_text_Web {
    margin-left: 30px;
    font-size: 50px;
    font-size: 30px;
    color: white;
  }
}
