.iconContainer {
  border-radius: 50px;
  color: white;
  background-color: #1778f2;
}
.iconContainer:hover {
  background-color: #f5f5f5;
  color: rgb(0, 0, 0);
}
.iconContainerLeftMenu {
  border-radius: 50px;
  background-color: white;
  color: #1778f2;
}
.innerInitial {
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
}
.innerInitialLeftMenu {
  padding: 28px;
  font-size: 18px;
  font-weight: bold;
}

.smalliconContainer {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  background-color: #f5f5f5;
  margin-left: 4px;
  margin-right: 4px;
  text-align: center;
}
.smallinnerInitial {
  padding: 6px;
  font-size: 12px;
  font-weight: bold;
}
.smalliconContainer:hover {
  background-color: #1778f2;
  color: white;
  cursor: pointer;
}

.profilePicture {
  object-fit: cover;
  
  border-radius: 50px;
  border: 1px solid #bbb;
 
}
.topProfilePicture{
  width: 60px;
  height: 60px;
  border: 1px solid #bbb;
  cursor: pointer;
  object-fit: cover;
  border-radius: 50%;
}
.profileImage {
  width: 40px;
  height: 40px;
}
.profileImageLeftMenu {
  width: 60px;
  height: 60px;
}
.logooverlaped {
  display: flex;
  justify-content: end;
  margin: 1rem;
}
.freelancerProfileContainer {
  margin-left: -5%;
}
.logo_images {
  width: 100%;
  height: 100%;
  border: 1px solid #fff;
  cursor: pointer;
  object-fit: cover;
  border-radius: 50%;
}

.profileImageDisplay {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: whitesmoke;

  align-items: center;
  display: inline-grid;
}

.profileImageDisplay:hover {
  cursor: pointer;
}
.nameAlign {
  text-align: center;
}
.requirementcard_icons {
  width: 50px;
  height: 50px;
  border: 1px solid #bbb;
  cursor: pointer;
  object-fit: cover;
  border-radius: 50%;
}
