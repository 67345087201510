.footer_container {
  background-color: #171717;
  margin-top: 25px;
  color: #ffffff;
}
.footer_aboutUs {
  display: flex;
  width: 57%;
  justify-content: space-between;
}
.footer_top {
  display: flex;
  padding: 20px;

  justify-content: flex-end;
  border-bottom: 1px groove grey;
}
.footer_contact {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.aboutUs_list {
  text-align: center;
  
  width: 95%;
}
.BottomBorder{
  border-bottom: 1px groove grey;
}
.plus_icon {
  background-color: #171717;
  color: #ffffff;
  border: hidden;
  cursor: pointer;
  font-size: 20px;
  width: 5%;
}
.plus_iconText{
  background-color: #171717;
  color: #ffffff;
  border: hidden;
  cursor: pointer;
  font-size: 16px;
  width: 95%;
}
.riLogo {
  
  cursor: pointer;
}

.social_icons {
  display: flex;
  justify-content: space-around;
  margin: auto;
  width: 260px;
  margin-top: 12px;
  margin-bottom: 12px;
}
.icons {
  padding: 0px 10px;
  height: 16px;
  cursor: pointer;
  border-right: 1px groove grey;
}
.icons:last-child {
  border-right: hidden;
}
.payment_icon {
  margin-top: 20px;
}
.all_rights {
  text-align: center;
  padding: 10px 0px;
}
.list_open {
  margin: 10px 0px;
  cursor: pointer;
  color: white;
  text-decoration: none;
}
.web_view_container {
  display: none;
}
.Footer_Link {
  text-decoration: none;
  color: #ffffff;
}
.Footer_Link:hover {
  text-decoration: none;
  color: #1778f2;
}
.icons_web:hover {
  color: #1778f2;
  border: 1px solid #1778f2;
}
.icons_web {
  margin-right: 8px;
  border: 1px solid white;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  padding: 5px;
}
.MailBtn {
  background: none;
  border: none;
  padding: 0px;
  color: #fff;
}
.followUsText {
  font-size: 22px;
}
.haedingName{
  font-weight: bold;
}

.sitemapText{
  text-decoration: none;
  color: white;
}
.sitemapText:hover{
  text-decoration: none;
  color: #1778f2;
}

@media screen and (min-width: 1023px) {
  .web_view {
    display: flex;
    margin: 0px 130px;
  }

  .web_view_container {
    display: block;
  }
  .mob_view {
    display: none;
  }
  .aboutUs_list_web {
    text-align: left;
    margin: 30px 0px;
    width: 33%;
  }
  .social_icons_web {
    display: flex;
    width: 336px;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
  }

  .banking_partners {
    border-top: 1px groove gray;
    display: flex;
    justify-content: center;
  }
 
  .riLogo_web {

    cursor: pointer;
  }
  .footer_bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 130px;
  }
}
.icons_web_Link {
  text-decoration: none;
  color: white;
}

/* @media screen and (min-width: 500px) {
  .footer_aboutUs {
    width: 52%;
  }
  .footer_contact {
    width: 55%;
  }
 

  .LoaderCenter{
    text-align: center;
  }
  .msgbtmcenter{
    text-align: center;
  }
 
} */
