.pageLayout {
  background-color: #f5f6fb;
}
.pageColumn {
  display: flex;
  justify-content: center;
}
.leftArea {
  width: 70%;
}
.rightArea {
  width: 30%;
}
.pageLoader{
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btnModal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
}

.modal {
  background-color: white;
  margin: auto;
  position: relative;
  font-weight: 500;
  top: -25px;
  width: 84%;
  padding: 20px 0px;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
  z-index: 30;
}
.closeModal {
  position: absolute;
  top: 4px;
  right: 10px;
  padding: 6px;
}
.Message{
  display: block;
  font-size: 15px;
  padding: 10px 12px 20px 12px;
  text-align: center;
  font-weight: 400;
}
.LoaderCenter{
  text-align: center;
}
.msgbtmcenter{
  text-align: center;
}
.title{
  display: block;
  font-size: 18px;
  text-align: center;
  font-weight:500;
  margin-top: 9px;
}

@media screen and (min-width: 1023px) {
  .modal {
    background-color: white;
      margin: auto;
      position: relative;
      font-weight: 500;
      top:-25px;
      width: 30%;
      padding: 23px 0px;
      border-radius: 14px;
      box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
      z-index: 30;
  }
  .Message{
    padding: 12px;
    font-size: 20px;
  }

  .title{
      display: block;
      font-size: 20px;
      text-align: center;
      font-weight:500;
      margin-top: 18px;
  }
  
}